import get from 'lodash/get';
import axios from 'axios';
import api from '../api.service';
// import authService from '../auth/auth.service';

const queryString = require('query-string');

const TRELLO_API_HOST = 'https://api.trello.com/1';
const TRELLO_KEY = process.env.REACT_APP_TRELLO_KEY;
let APP_HOST = process.env.REACT_APP_HOST;

if (get(window, 'location.hostname') === 'localhost') {
    // Local development host
    APP_HOST = 'http://localhost:4000';
}

/**
 * TRELLO REST API v1
 * https://developers.trello.com/docs/api-introduction
 */
class TrelloApi {

    /**
     * @param path {String} The GET request path. E.g. /projects/search
     * @param token {String} Trello secret key
     * @param [options] {Object} Query string options
     * @return {Promise<any>}
     */
    trelloGet(path, token, options = {}) {

        const qs = queryString.stringify({
            ...options,
            token,
            key: TRELLO_KEY
        });

        return axios({
            method: 'GET',
            url: `${TRELLO_API_HOST}${path}?${qs}`
        }).then(response => get(response, 'data'));
    }

    getBoardList(token, options = {}) {
        return this.trelloGet(`/members/me/boards`, token, {
            filter: 'open',
            organization: true,
            organization_fields: [
                'id',
                'displayName',
                'desc',
                'descData',
                'url'
            ].join(','),
            ...options
        });
    }

    getBoardListList(token, boardId, options = {}) {
        return this.trelloGet(`/boards/${boardId}/lists/open`, token, options);
    }

    getBoardMemberList(token, boardId, options = {}) {
        return this.trelloGet(`/boards/${boardId}/members`, token, options);
    }

    /**
     * @param missionId
     * @param boardId
     * @param payload {Object}
     * @param payload.useTrelloColumns {Boolean} Use trello first and last columns as TODO and DONE
     * @param payload.useTrelloMembers {Boolean} Import Trello members and send invites
     * @param payload.useColumnsAsCategories {Boolean} Don't use the columns as a process pipeline, rather as categories/buckets of work.
     */
    import(missionId, boardId, payload = {}) {
        return api.post(`/mission/${missionId}/trello/import/${boardId}`, payload)
            .then(res => res.data)
            .catch(err => {
                throw get(err, 'response.data') || err
            });
    }

    trelloAuthorizeRedirect(missionId, options = {}) {

        const expiration = options.expiration || '1hour';

        const returnUrlOptions = {
            mission: missionId,
            trello: 'authCallback',
            expiration
        };

        options = {
            expiration,
            name: 'Mission-X',
            scope: 'read',
            response_type: 'token',
            callback_method: 'fragment',
            return_url: `${APP_HOST}/board?${queryString.stringify(returnUrlOptions)}`,
            key: TRELLO_KEY
        };

        window.location.replace(`https://trello.com/1/authorize?${queryString.stringify(options)}`);
    }

    handleAuth(missionId, token, expiration) {
        return api
            .post(`/mission/${missionId}/trello/create`, {token, expiration})
            .then(res => res.data)
            .catch(err => {
                throw get(err, 'response.data') || err
            })
    }
}

export default new TrelloApi();
