import api from '../../api.service';

/**
 * @param orgId {string}
 * @returns {Promise<* | void>}
 */
export function getAuthRedirect(orgId) {
    return api.post(`/quickbook/online/auth/${orgId}/redirect`)
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err);
        })
}

/**
 * @param orgId {string}
 * @param payload {object}
 * @param payload.code {string}
 * @param payload.realmId {string}
 * @returns {Promise<AxiosResponse<any> | void>}
 */
export function authToken(orgId, payload) {
    return api.post(`/quickbook/online/auth/${orgId}/token`, payload).catch(err => {
        throw api.getResponseError(err);
    })
}

/**
 * Example:
 * await apiOrg.qbDemoRequest(ORG_ID, "/company/2F4620816365241356110/companyinfo/2F4620816365241356110")
 *
 * @param orgId {string}
 * @param quickBookUrl {string} The Quickbook API url, either full URL or the portion right after the "v3/" part
 * @param [quickBookMethod] {'GET'|'POST'|'PUT'|'PATCH'|'DELETE'} The Quickbook API method. Defaults to "GET"
 * @param [quickBookBody] {any} The Quickbook API payload
 * @returns {Promise<*>}
 */
export function makeRequestBase(orgId, quickBookUrl, quickBookMethod = 'GET', quickBookBody) {
    return api.post(`/quickbook/online/org/${orgId}/request`, {
        url: quickBookUrl,
        method: quickBookMethod,
        body: quickBookBody,
    }).then(res => res.data).catch(err => {
        throw api.getResponseError(err);
    })
}

/**
 * @param orgId {string}
 */
export function makeRequest(orgId) {
    return {
        get: (quickBookUrl) => makeRequestBase(orgId, quickBookUrl),
        post: (quickBookUrl, body = {}) => makeRequestBase(orgId, quickBookUrl, 'POST', body),
        put: (quickBookUrl, body = {}) => makeRequestBase(orgId, quickBookUrl, 'PUT', body),
        patch: (quickBookUrl, body = {}) => makeRequestBase(orgId, quickBookUrl, 'PATCH', body),
        delete: (quickBookUrl) => makeRequestBase(orgId, quickBookUrl, 'DELETE'),
    };
}
