const scriptJs = require('scriptjs');

/**
 * reCAPTCHA v3
 * https://developers.google.com/recaptcha/docs/v3
 */
class Recaptcha {

    get window() {
        return window;
    }

    get grecaptcha() {
        return this.window ? this.window.grecaptcha : null;
    }

    constructor() {
        this.key = process.env.REACT_APP_FIREBASE_RECAPTCHA_KEY;
        this.request = null;
        this.isLoaded = false;
    }

    load() {
        return new Promise((resolve, reject) => {
            if (!this.window) {
                return reject(new Error('Recaptcha: Window is not defined.'));
            }

            if (this.isLoaded) {
                return resolve();
            }

            this._request()
                .then(() => {
                    this.isLoaded = true;
                    resolve();
                })
                .catch(err => reject(err));
        });
    }

    execute(action = 'login') {
        return this
            .load()
            .then(() => {
                return new Promise((resolve, reject) => {
                    try {
                        this.grecaptcha.execute(this.key, {action}).then((token) => {
                            resolve(token);
                        });
                    } catch (err) {
                        reject(err);
                    }
                });
            });
    }

    _request() {

        if (this.request) {
            return this.request;
        }

        this.request = new Promise((resolve, reject) => {
            /**
             * TODO: Use LoadJs instead. It has error handling and retries.
             * https://www.npmjs.com/package/loadjs
             */
            scriptJs(`https://www.google.com/recaptcha/api.js?render=${this.key}`, () => {

                if (!this.grecaptcha) {
                    // Error handling not supported
                    // https://github.com/ded/script.js/pull/113
                    return reject(new Error('Recaptcha: Failed to load, window.grecaptcha is not defined.'));
                }

                this.grecaptcha.ready(() => {
                    this.request = null;
                    resolve();
                });
            });
        });

        return this.request;
    }
}

export default new Recaptcha();