export function updateStateExpenses({ expeneses = [], how, data }) {
    let newExpenses = expeneses.slice()
    switch (how) {
        case "set":
            newExpenses = data
            break
        case "add":
            newExpenses.push(data)
            break
        case "push":
            newExpenses.push(data)
            break
        case "merge":
            newExpenses = [...expeneses, ...data]
            break
        case "delete":
            const existinIndex = newExpenses.findIndex((ts) => ts._id === data._id)
            if (existinIndex !== -1) {
                newExpenses.splice(existinIndex, 1)
            }
            break
        case "update":
            const existinIndex2 = newExpenses.findIndex((ts) => ts._id === data._id)

            if (existinIndex2 !== -1) {
                newExpenses[existinIndex2] = { ...newExpenses[existinIndex2], ...data }
            }
            break

        default:
    }

    return newExpenses
}
