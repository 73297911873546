import {get} from 'lodash';
import firebase from '../firebase.service';
import authService from './auth.service';
import api from '../api.service';
import {recaptcha} from '../recaptcha';
import {getValidEmail} from '../../comp/Utils';
import axios from 'axios';
import {revokeCsrf, revokeSessionCookie, setCsrf, setSessionCookie} from '../session/session.api';


export function getCurrentUser() {
    return authService.getCurrentUser();
}

export function signUp(email, password) {
    return recaptcha.execute()
        .then(token => api.public.post(`/auth/signup`, {email, password, token}))
        .then(obj => obj.data)
        .catch(err => {
            throw api.getResponseError(err);
        });
}

export async function signInWithEmailAndPasswordAndGetProfile(email, password, rememberMe) {
    await this.signInWithEmailAndPassword(email, password, rememberMe);
    return this.getProfile();
}

export async function signInWithEmailAndPassword(email, password, rememberMe) {

    const persistence = (rememberMe === true ?
        firebase.auth.Auth.Persistence.LOCAL :
        firebase.auth.Auth.Persistence.SESSION);

    await firebase
        .auth()
        .setPersistence(persistence);

    const authRes = await firebase.auth().signInWithEmailAndPassword(email, password);
    await setCsrf();
    await setSessionCookie();
    return authRes;
}

export async function signOut() {
    try {
        await revokeSessionCookie();
        await revokeCsrf();
    } catch(err) {
        // Nah...
    }
    await firebase.auth().signOut();
}

export async function sendPasswordResetEmail(email) {
    return firebase.auth().sendPasswordResetEmail(email);
}

export async function verifyPasswordResetCode(code) {
    return firebase.auth().verifyPasswordResetCode(code);
}

export async function confirmPasswordReset(code, newPassword) {
    return firebase.auth().confirmPasswordReset(code, newPassword)
}

export function getProfile() {
    return api.get('/people/profile')
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

export function updateProfile(data) {
    return api.put('/people/profile', data)
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

/**
 * Get the avatar blob
 * @return {Promise<*>}
 */
export function getAvatarContents() {
    return api
        .get('/people/profile/avatar', {
            responseType: 'blob'
        })
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

export function uploadAvatar(file) {
    return api
        .uploadFileSingle(`/people/profile/avatar`, file, {
            name: 'avatar'
        })
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

export function existsPersonWithEmail(email) {
    return api.get(`/auth/exists/${email}`)
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

export function updatePassword(passOld, passNew) {
    const user = firebase.auth().currentUser;
    return this.reauthenticateUser(passOld)
        .then(() => user.updatePassword(passNew));
}

export function reauthenticateUser(password) {
    const user = firebase.auth().currentUser;
    return user.reauthenticateAndRetrieveDataWithCredential(firebase.auth.EmailAuthProvider.credential(
        user.email,
        password
    ));
}

export function sendEmailVerification(email) {
    const user = firebase.auth().currentUser;
    return !email || user.email === email
        ? user.sendEmailVerification()
        : this.emailListSendVerification(email);
}

/**
 * @makis
 *
 * If user needs to sign in again before changing email, you will get the following error code:
 * { code: 'auth/requires-recent-login' }
 *
 * In this case, you need to reauthenticate the user:
 * apiAuth
 *      .reauthenticateUser('password')
 *      .then(() => apiAuth.updateEmail('newEmail'))
 *      .then(() => console.log('success!'))
 *      .catch(err => console.log(err))
 *
 * @param email
 * @returns {*}
 */
export async function updateEmail(email) {
    const user = await authService.getCurrentUser();
    const mfaFactorList = get(user, "multiFactor.enrolledFactors") || [];
    const currentEmail = user.email;
    email = getValidEmail(email);

    if (!email) {
        throw new Error(`Invalid email provided.`);
    }

    if (email === currentEmail) {
        throw new Error(`That's already your current email.`);
    }

    if (mfaFactorList.length) {
        // TODO: Log to API so we know we have messed up
        throw new Error(`Failed to update email. We're on it.`);
    }

    return api
        .get(`/people/exists-other-person-with-email/${email}`)
        .then(res => {
            const existsOtherPersonWithEmail = get(res, 'data.exists');
            if (existsOtherPersonWithEmail) {
                const responseError = {
                    message: 'Another person exists with this email.',
                    code: 'auth/email-already-in-use',
                };
                throw responseError;
            }
        })
        .then(() => user.updateEmail(email))
        .then(() => api
            .put(`/people/profile/email/update`)
            .catch(err => new Promise((resolve, reject) => {
                // Revert firebase email update
                user.updateEmail(currentEmail).finally(() => {
                    reject(err);
                });
            }))
        )
        .then(() => user.getIdToken(true))
        .catch(err => {
            throw api.getResponseError(err)
        })
}

/**
 * Multi-factor users must always have a verified email address.
 * So to update an email of an MFA user, this method has to be called, instead of "updateEmail"
 * https://cloud.google.com/identity-platform/docs/work-with-mfa-users#updating_a_users_email
 *
 * @makis
 *
 * If user needs to sign in again before changing email, you will get the following error code:
 * { code: 'auth/requires-recent-login' }
 *
 * In this case, you need to reauthenticate the user:
 * apiAuth
 *      .reauthenticateUser('password')
 *      .then(() => apiAuth.verifyBeforeUpdateEmail('newEmail'))
 *      .then(() => console.log('success!'))
 *      .catch(err => console.log(err))
 *
 * @param email
 * @returns {*}
 */
export async function verifyBeforeUpdateEmail(email) {
    const user = await authService.getCurrentUser();
    const mfaFactorList = get(user, "multiFactor.enrolledFactors") || [];
    const currentEmail = user.email;
    email = getValidEmail(email);

    if (!email) {
        throw new Error(`Invalid email provided.`);
    }

    if (email === currentEmail) {
        throw new Error(`That's already your current email.`);
    }

    if (!mfaFactorList.length) {
        // TODO: Log to API so we know we have messed up
        throw new Error(`Failed to update email. We're on it.`);
    }

    return api
        .get(`/people/exists-other-person-with-email/${email}`)
        .then(res => {
            const existsOtherPersonWithEmail = get(res, 'data.exists');
            if (existsOtherPersonWithEmail) {
                const responseError = {
                    message: 'Another person exists with this email.',
                    code: 'auth/email-already-in-use',
                };
                throw responseError;
            }
        })
        .then(() => user.verifyBeforeUpdateEmail(email))
        .catch(err => {
            throw api.getResponseError(err)
        })
}

/**
 * Multi-factor users must always have a verified email address.
 * This is the callback handler of verifyBeforeUpdateEmail() method.
 * https://cloud.google.com/identity-platform/docs/work-with-mfa-users#updating_a_users_email
 * @param oobCode
 * @return {Promise<*>}
 */
export async function verifyAndUpdateEmail(oobCode) {
    const user = await authService.getCurrentUser();
    let data;

    return api
        .put(`/people/profile/email/verify-update`, {oobCode})
        .then(res => data = res.data)
        .then(() => user.getIdToken(true))
        .then(() => data)
        .catch(err => {
            throw api.getResponseError(err)
        });
}

export async function verifyEmail(oobCode) {

    const user = await authService.getCurrentUser();

    if (user.emailVerified === true) {
        // Make sure email is verified in API
        return user.getIdToken(true)
            .then(() => api.put(`/people/profile/email/verify`, {email: user.email}))
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err)
            });
    }

    return firebase.auth()
        .applyActionCode(oobCode)
        .then(() => user.getIdToken(true))
        .then(() => api.put(`/people/profile/email/verify`, {
            email: user.email
        }))
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        });
}

export async function recoverEmail(oobCode) {
    return api
        .put(`/auth/email/recover`, {oobCode}, true)
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        });
}

export function emailListAdd(email) {
    return api.put(`/people/profile/email/list/add`, {email})
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

export function emailListRemove(email) {
    return api.put(`/people/profile/email/list/remove`, {email})
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

export async function emailListVerify(oobCode) {
    await authService.getCurrentUser();
    return api.put(`/people/profile/email/list/verify`, {oobCode})
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

export function emailListSendVerification(email) {
    return api.post(`/people/profile/email/list/verification/send`, {email})
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err)
        })
}

/**
 * Microsoft
 */

/**
 *
 * @param scopeList
 * @param options
 * @returns {Promise<void>}
 */
export async function microsoftSignInWithPopup(scopeList, options = {}) {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    const {prompt = 'select_account', login_hint, tenant = 'common', client_id, response_type, state} = options;

    provider.setCustomParameters({
        prompt, login_hint, tenant, client_id, response_type, state
    });

    scopeList.forEach(scope => provider.addScope(scope));
    const authResponse = await firebase.auth().signInWithPopup(provider);
    await setCsrf();
    await setSessionCookie();
    const profile = authResponse?.additionalUserInfo?.profile;
    const credential = authResponse?.credential;
    const token = credential?.accessToken;
    return {
        profile,
        token
    };
}

export async function getMicrosoftProfileDetails(accessToken) {
    const user = await authService.getCurrentUser();
    const token = await user.getIdToken();
    const [microsoftProfile, microsoftPhoto] = await Promise.all([
        axios({
            url: 'https://graph.microsoft.com/v1.0/me',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }),
        axios({
            url: 'https://graph.microsoft.com/v1.0/me/photo/$value',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    ]);
    return {microsoftProfile, microsoftPhoto};
}

/**
 * Link the microsoft account with the currently logged in account.
 * CAREFUL: You CANNOT link a personal account to two different microsoft accounts.
 * So if you link this user to company account X, then you cannot link this user again to company account Y.
 * You would have to unlink first and then link the new account.
 *
 * @returns {Promise<void>}
 */
export async function microsoftLinkWithPopup() {
    const user = await authService.getCurrentUser();
    const provider = new firebase.auth.OAuthProvider('microsoft.com');

    provider.setCustomParameters({
        prompt: 'select_account'
    });

    try {
        await user.linkWithPopup(provider);
    } catch (err) {
        /**
         * @makis: Handle below errors on page level.
         */

        if (err.code === 'auth/credential-already-in-use') {
            // User has signed up with this email outside of Active Directory
            // How do we handle this?
            // Maybe, prompt the user to set his personal email as the primary email and try again?
            // Or force him to set a personal email to distinguish the two accounts (no linking)?
            // In either case we need to give the option to the user, what they would like to do.
        }

        if (err.code === 'auth/multi-factor-auth-required') {
            // Proof of ownership of a second factor is required to complete sign-in.
            // Happens when user haven't gone through the Microsoft MFA steps (email + Phone)
        }

        if (err.code === 'auth/provider-already-linked') {
            // User can only be linked to one identity for the given provider.
        }

        // Handle other errors

        throw err;
    }
}

export default {
    getCurrentUser,
    signUp,
    signInWithEmailAndPasswordAndGetProfile,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
    confirmPasswordReset,
    signInWithEmailAndPassword,
    getProfile,
    updateProfile,
    getAvatarContents,
    uploadAvatar,
    existsPersonWithEmail,
    updatePassword,
    reauthenticateUser,
    sendEmailVerification,
    updateEmail,
    verifyBeforeUpdateEmail,
    verifyAndUpdateEmail,
    verifyEmail,
    recoverEmail,
    emailListAdd,
    emailListRemove,
    emailListVerify,
    emailListSendVerification,
    microsoftSignInWithPopup,
    microsoftLinkWithPopup,
    signOut
};