export const ENVIRONMENT = {
    DEVELOP: "develop",
    ALPHA: "alpha",
    PRODUCTION: "production",
}

export const OPERATIONS = {
    GET: "get",
    CREATE: "create",
    UPDATE: "update",
    DELETE: "delete",
}

export const NOTIFICATION_TYPE = {
    ORG_INVITE: "orgInvite",
    INVITE: "invite",
    MAKE_IT_HAPPEN_MEETING: "mkh_meeting",
    MENTION_COMMENT: "mentionInComment",
}

export const QUICKBOOK_SCOPE = {
    ACCOUNTING: "com.intuit.quickbooks.accounting",
    PAYMENT: "com.intuit.quickbooks.payment",
    PAYROLL: "com.intuit.quickbooks.payroll",
    TIME_TRACKING: "com.intuit.quickbooks.payroll.timetracking",
}

/**
 * Needs to be in sync with API, OpenAPI and Pobuca constants.
 * Don't change only here.
 */
export const GT_CUSTOM_ROLES = {
    PROJECT_MANAGER: "projectManager",
    ENGAGEMENT_EXECUTIVE_A: "engagementExecutiveA",
    ENGAGEMENT_EXECUTIVE_B: "engagementExecutiveB",
    OFFER_PROCEDURE_EMPLOYEE: "offerProcedureEmployee",
}

export const GT_CUSTOM_ROLE_LABELS = {
    PROJECT_MANAGER: "Project Manager",
    ENGAGEMENT_EXECUTIVE_A: "Engagement Executive A",
    ENGAGEMENT_EXECUTIVE_B: "Engagement Executive B",
    OFFER_PROCEDURE_EMPLOYEE: "Employee responsible for the offer procedure",
}

export const ERROR_SEVERITY = {
    INFO: "info",
    WARNING: "warning",
    ERROR: "error",
    CRITICAL: "critical",
    DEBUG: "debug",
    ALERT: "alert",
}
