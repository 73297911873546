//TO BE DEPRECATED! utisl/dates.js

import { fromZonedTime } from "date-fns-tz"
import { toZonedTime } from "date-fns-tz"

import endOfWeek from "date-fns/endOfWeek"
import flatMap from "lodash/flatMap"
import groupBy from "lodash/groupBy"

import {endOfDay} from "date-fns/endOfDay"
import differenceInMonths from "date-fns/differenceInMonths"
import differenceInDays from "date-fns/differenceInDays"
import {isAfter} from "date-fns/isAfter"
import {isBefore} from "date-fns/isBefore"
import {startOfISOWeek} from "date-fns/startOfISOWeek"

import differenceInBusinessDays from "date-fns/differenceInBusinessDays"

import differenceInWeeks from "date-fns/differenceInWeeks"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"

import addDays from "date-fns/addDays"
import startOfDay from "date-fns/startOfDay"
import addWeeks from "date-fns/addWeeks"
import addMonths from "date-fns/addMonths"
import {endOfISOWeek} from "date-fns/endOfISOWeek"
import getISODay from "date-fns/getISODay"

import startOfWeek from "date-fns/startOfWeek"
import addSeconds from "date-fns/addSeconds"
import isSameWeek from "date-fns/isSameWeek"
import addHours from "date-fns/addHours"

import {isWithinInterval} from "date-fns/isWithinInterval"
import isMonday from "date-fns/isMonday"
import isSunday from "date-fns/isSunday"
import {isSaturday} from "date-fns/isSaturday"
import {eachDayOfInterval} from "date-fns/eachDayOfInterval"
import isFriday from "date-fns/isFriday"
import isLastDayOfMonth from "date-fns/isLastDayOfMonth"
import isWeekend from "date-fns/isWeekend"
import nextMonday from "date-fns/nextMonday"
import nextTuesday from "date-fns/nextTuesday"

export function getStartHour(date) {
    return getISODay(date) * 8 - 8
}

export function getDayIndex(date) {
    return date.getDay() === 0 ? 6 : date.getDay() - 1
}

export function ensureNumber(val) {
    let newVal = val
    if (newVal === null) return 0
    if (newVal === undefined) return 0
    if (typeof newVal === "string") {
        newVal = newVal.replaceAll(",", "")
    }
    if (Number.isNaN(+newVal)) return 0
    return +newVal
}
function getEntryFromToArray(daysArray, from, to) {
    let arr = []

    for (let i = 0; i < 7; i++) {
        arr.push(i >= from && i <= to ? +daysArray[i] : 0)
    }

    return arr
}

function cleanOldTsEntryFormats(entry, from = 0, to = 6) {
    if (!entry?.days) {
        return "0,0,0,0,0,0,0"
    }
    if (!Array.isArray(entry.days) && typeof entry.days === "string") {
        return getEntryFromToArray(entry.days.split(","), from, to).join(",")
    }

    let cleanDays = entry.days || []

    //Return days string "0,0,0,0,0,0,0"

    if (!cleanDays?.length) {
        let spreadHrs = 0
        if (entry.hours <= 16) {
            spreadHrs = roundToDivisor(entry.hours / 2, 0.25)

            cleanDays = [0, 0, 0, spreadHrs, spreadHrs, 0, 0]
        } else if (entry.hours > 16 && entry.hours <= 24) {
            spreadHrs = roundToDivisor(entry.hours / 3, 0.25)

            cleanDays = [0, 0, spreadHrs, spreadHrs, spreadHrs, 0, 0] //.join(",")
        } else if (entry.hours > 24 && entry.hours <= 48) {
            spreadHrs = roundToDivisor(entry.hours / 4, 0.25)
            cleanDays = [0, spreadHrs, spreadHrs, spreadHrs, spreadHrs, 0, 0] //.join(",")
        } else {
            spreadHrs = roundToDivisor(entry.hours / 5, 0.25)

            cleanDays = [spreadHrs, spreadHrs, spreadHrs, spreadHrs, spreadHrs, 0, 0] //.join(",")
        }
    }

    return getEntryFromToArray(cleanDays, from, to).join(",")
}

export function getTsEntryHoursArray(entry, asString, from = 0, to = 6) {
    let cleanDays = cleanOldTsEntryFormats(entry, from, to)

    const str = cleanDays || "0,0,0,0," + (entry.hours || 0) + ",0,0"
    if (asString) {
        return str
    } else {
        return str.split(",").map((i) => {
            return +i
        })
    }
}

export function isTimesheetMissing(ts, date) {
    if (!ts && isBefore(date, startOfISOWeek(new Date()))) {
        return true
    }
    return ts?.status === "draft" && isBefore(toZonedTime(ts.weekEnd), startOfISOWeek(new Date()))
}

export function getTimesheetHoursAndOT({ timesheets, from = 0, to = 6, app, mission, orgData }) {
    let data = {
        totalHours: getTimesheetHours(timesheets, from, to),
        totalOT: 0,
    }

    const mst = groupBy(timesheets, "mission._id")

    Object.keys(mst).forEach((key, i) => {
        let myMission = mission || app?.state.missions.find((m) => m._id === key)

        if (!myMission) {
            myMission = mst[key][0]?.snapshot?.mission
        }

        const myOrgData = orgData
            ? orgData
            : myMission?.org
            ? app.state.orgs.find((o) => o._id === (mission?.org?._id || mission?.org))
            : null
        if (!myOrgData) {
            return
        }

        const otMethod = myMission?.overTimeAfterDayOrWeek || myOrgData?.overTimeAfterDayOrWeek || "daily"
        const otCutOff = myMission?.overTimeAfter || myOrgData?.overTimeAfter || (otMethod === "daily" ? 8 : 40)

        if (otMethod === "weekly") {
            const totalForThisPack = getTimesheetHours(mst[key], from, to)

            data.totalOT += totalForThisPack <= otCutOff ? 0 : totalForThisPack - otCutOff
        } else {
            const xWeeks = groupBy(mst[key], "weekStart")

            Object.keys(xWeeks).forEach((w, i) => {
                const isAgg = getTimesheetDailyAggregates(xWeeks[w])

                isAgg.forEach((agg, aggIndex) => {
                    const l = xWeeks[w].length

                    data.totalOT += agg / l > otCutOff ? agg - otCutOff : 0
                })
            })
        }
    })

    return data
}

export function getTimesheetHours(givenTimesheets, from = 0, to = 6) {
    let timesheets = givenTimesheets

    if (!Array.isArray(givenTimesheets)) {
        timesheets = [givenTimesheets]
    }

    let hours = 0
    let allActionItems = timesheets.flatMap((ts) => ts.actionItems || []).filter((a) => !!a)

    allActionItems.forEach((item, i) => {
        const thisAisTime = getTimeEntryHours(item, from, to)
        hours += thisAisTime
    })

    return ensureNumber(hours)
}
export function getTimesheetDailyAggregates(timesheets, from = 0, to = 6) {
    let dailyTotals = [0, 0, 0, 0, 0, 0, 0]
    flatMap(timesheets, "actionItems").forEach((tsa, i) => {
        const myHoursArray = getEntryFromToArray(getTsEntryHoursArray(tsa), from, to)

        for (var d = from; d <= to; d++) {
            dailyTotals[d] += myHoursArray[d]
        }
    })

    return dailyTotals
}

export function getTimesheetHoursByDay(timesheets, dayIndex) {
    let hours = 0

    timesheets.forEach((ts, i) => {
        ts.actionItems.forEach((entry, i) => {
            let cleanDates = cleanOldTsEntryFormats(entry)
            let dayHours = cleanDates.split(",")[dayIndex] || []
            hours += dayHours || 0
        })
    })

    return hours
}

//Totals
export function getTimeEntryHours(entry, from = 0, to = 6) {
    let hours = 0
    let days = getEntryFromToArray(getTsEntryHoursArray(entry), from, to)

    hours = days.length ? days.reduce((a, b) => ensureNumber(a) + ensureNumber(b), 0) : 0

    return hours
}

export function getTimesheetHoursFromTo(timesheets, from, to) {
    let thisPeriodsTimesheets = timesheets.filter((ts) =>
        areIntervalsOverlapping(
            {
                start: from,
                end: to,
            },
            {
                start: toZonedTime(ts.weekStart),
                end: endOfISOWeek(toZonedTime(ts.weekStart)),
            }
        )
    )
    let hours = 0
    thisPeriodsTimesheets.forEach((ts) => {
        let startIndex = 0
        let endIndex = 6

        if (isSameWeek(toZonedTime(ts.weekStart), from, { weekStartsOn: 1 })) {
            startIndex = getISODay(from) - 1
        }
        if (isSameWeek(endOfISOWeek(toZonedTime(ts.weekStart)), to, { weekStartsOn: 1 })) {
            endIndex = getISODay(to) - 1
        }
        hours += getTimesheetHours(ts, startIndex, endIndex)
    })

    return hours
}

export function getGreatestEndDate(list, labelKey = "endDate") {
    const sortedItems = list.sort((a, b) => a[labelKey] - b[labelKey]).reverse()

    if (sortedItems && sortedItems[0] && sortedItems[0][labelKey]) {
        return endOfDay(toZonedTime(sortedItems[0][labelKey]))
    } else {
        return null
    }
}
export function getSmallestStartDate(list, labelKey = "startDate") {
    const sortedItems = list.sort((a, b) => a[labelKey] - b[labelKey])

    if (sortedItems && sortedItems[0] && sortedItems[0][labelKey]) {
        return startOfDay(toZonedTime(sortedItems[0][labelKey]))
    } else {
        return null
    }
}

export function checkWeekendsForTask(startDate, endDate, task, isContracting) {
    if (!task.includeWeekends) {
        const myOriginalStartDate = toZonedTime(task.startDate)
        const myOriginalEndDate = toZonedTime(task.startDate)

        const initialDurationInDays = task.estimatedDuration / 8

        if (!isContracting) {
            if (isWeekend(startDate)) {
                startDate = startOfWeek(addWeeks(startDate, 1, { weekStartsOn: 1 }), { weekStartsOn: 1 })

                endDate = endOfDay(addDays(startDate, initialDurationInDays - 1))
            }
            if (isSaturday(endDate)) {
                endDate = endOfDay(nextMonday(endDate))
            } else if (isSunday(endDate)) {
                endDate = endOfDay(nextTuesday(endDate))
            }
        } else {
            if (isWeekend(startDate)) {
                startDate = startOfWeek(addWeeks(startDate, 1, { weekStartsOn: 1 }), { weekStartsOn: 1 })

                endDate = endOfDay(addDays(startDate, initialDurationInDays - 1))
            }
        }
    }

    return {
        endDate: endDate,
        startDate: startDate,
    }
}

export function howFarBackCanTaskGoVG(task, allActionItems) {
    let taskBeforeMe = allActionItems
        .filter((ai) => ai.y === task.y && ai.endDate < task.startDate && ai.actionId === task.actionId)
        .sort((a, b) => b.endDate - a.endDate)[0]

    let minDate = taskBeforeMe ? startOfDay(addDays(toZonedTime(taskBeforeMe.endDate), 1)) : null

    if (!task.dependencies?.length) return minDate

    const myDep = (task.dependencies || [])
        .filter((d) => d.linkType === "dependsOn")
        .sort((a, b) => {
            return a.linkTo.endDate - b.linkTo.endDate
        })[0]

    const myMinDepDate = myDep ? startOfDay(addDays(toZonedTime(myDep.linkTo.endDate), 1)) : null

    if (myMinDepDate && minDate) {
        if (isAfter(myMinDepDate, minDate)) {
            return myMinDepDate
        } else {
            return minDate
        }
    } else {
        return myMinDepDate || minDate || null
    }
}

export function howFarForwardCanTaskGo(task) {
    const cl = task.checklist?.filter((c) => c.startDate && c.done)
    if (!cl?.length) return null

    const fc = task.checklist.sort((a, b) => {
        return a.startDate - b.startDate
    })

    return fc[0]?.startDate ? toZonedTime(fc[0]?.startDate) : null
}

export function isAiWithinRange(ai, miss, range1, range2) {
    let ok = false

    const theStart = toZonedTime(ai.startDate)
    let theEnd = null

    try {
        theEnd = getAiEndDate(ai, miss)
    } catch (err) {}

    if (
        isWithinInterval(theStart, { start: range1, end: range2 }) ||
        isWithinInterval(theEnd, { start: range1, end: range2 })
    ) {
        ok = true
    }

    return ok
}

export function deriveEndDateAndTime(ai) {
    const start = typeof ai.startDate === "number" ? toZonedTime(ai.startDate) : ai.startDate

    if ((ai.startHour === null || ai.startHour === undefined) && ai.endDate) {
        return toZonedTime(ai.endDate)
    }

    if ((ai.startHour !== 0 && ai.startHour === null) || ai.startHour === undefined) {
        console.error("no startHour provided")
        return
    }

    const dur = ai.estimatedDuration || ai.duration

    if (!dur) {
        return null
    }

    let eod = dur / 8

    if (eod < 1) {
        eod = 1
    }

    let endDate

    if (ai.startHour % 8 === 0 && dur % 8 === 0) {
        endDate = endOfDay(addDays(start, eod - 1))
    } else {
        const myStartDate = startOfDay(deriveStartDateAndTime(ai))

        //Basic end date 00:00
        const howFarInAnEightHourDayAmIStarting = ai.startHour % 8

        const bleedToNextDay = (dur + howFarInAnEightHourDayAmIStarting) / 8

        const daysToAddToStartDate =
            (dur + howFarInAnEightHourDayAmIStarting) % 8 === 0 ? dur / 8 : Math.floor(bleedToNextDay)
        //End of day check

        const whatIsMyEndDateJustByEstimatedDuration = addDays(myStartDate, daysToAddToStartDate)

        let hoursToCover = 24

        try {
            hoursToCover = eachDayOfInterval({ start: start, end: whatIsMyEndDateJustByEstimatedDuration }).length * 8
        } catch (err) {}

        //What hours in the last day do I end?

        const leftOverHoursToDistribute = hoursToCover - dur
        const howManyGoToStart = howFarInAnEightHourDayAmIStarting
        const howManyGoToEnd = 8 - (leftOverHoursToDistribute - howManyGoToStart)

        const finalHoursToAdd = howManyGoToEnd + 9

        const myEndDateWithWorkDayTime =
            finalHoursToAdd === 17
                ? endOfDay(whatIsMyEndDateJustByEstimatedDuration)
                : addHours(whatIsMyEndDateJustByEstimatedDuration, finalHoursToAdd)

        endDate = myEndDateWithWorkDayTime
    }

    return endDate
}

export function findAiEndDate(ai) {
    if (ai.endDate) {
        return toZonedTime(ai.endDate)
    }

    if (ai.dueDate && (!ai.startDate || ai.startDate < ai.dueDate)) {
        return endOfDay(toZonedTime(ai.dueDate))
    }
    if (!ai.startDate && ai.completedOn) {
        return toZonedTime(ai.completedOn)
    }

    if (ai.startDate && ai.startHour >= 0 && ai.startHour !== null && ai.estimatedDuration > 0) {
        const days = ai.estimatedDuration / 8 - 1

        return endOfDay(addDays(toZonedTime(ai.startDate), days))
    }

    return null
}

export function deriveStartDateAndTime(ai) {
    const start = typeof ai.startDate === "number" ? toZonedTime(ai.startDate) : ai.startDate

    const addr = ai.startHour ? ai.startHour % 8 : 0

    //

    return addHours(start, 9 + addr)
}

export function getAiDueDate(ai) {
    if (ai.endDate || ai.dueDate) {
        return toZonedTime(ai.dueDate || ai.endDate)
    }

    return null
}

export function getAiEndDate(ai) {
    if (ai.endDate || ai.dueDate) {
        return toZonedTime(ai.dueDate || ai.endDate)
    }
    if (ai.startDate && ai.estimatedDuration && ai.startDate >= 0 && ai.startDate !== null) {
        const daysToAdd = ai.startHour + (ai.estimatedDuration % 8)
        return endOfDay(addDays(toZonedTime(ai.startDate), daysToAdd - 1))
    }

    return null
}

export function formatDurationUnits(startDate, endDate, includeWeekends) {
    if (typeof startDate === "number") {
        startDate = toZonedTime(startDate)
    }
    if (typeof endDate === "number") {
        endDate = toZonedTime(endDate)
    }

    const adjustedEndDate = addSeconds(endDate, 1)

    const months = differenceInMonths(adjustedEndDate, startDate)
    const weeks = differenceInWeeks(adjustedEndDate, startDate)
    let days = differenceInBusinessDays(adjustedEndDate, startDate)
    const weekDays = differenceInDays(adjustedEndDate, startDate)

    let remainderDays = 0

    if (includeWeekends) days = weekDays

    let labelTxt = includeWeekends || (isWeekend(startDate) && isWeekend(adjustedEndDate)) ? " day" : " business day"

    if (startDate.getDate() === 1 && isLastDayOfMonth(endDate)) {
        return months + " month" + (months === 1 ? "" : "s")
    }

    if (months === 0 && weeks === 0) {
        return days === 5 && isMonday(startDate) && isFriday(endDate) && !includeWeekends
            ? "1 week"
            : days + labelTxt + (days === 1 ? "" : "s")
    }

    if (months === 0 && weeks <= 2) {
        return days === 5 && isMonday(startDate) && isFriday(endDate)
            ? "1 " + (includeWeekends ? "" : "business") + " week"
            : days === 14
            ? "2 full weeks"
            : days === 12 && isMonday(startDate) && isFriday(endDate)
            ? "2 " + (includeWeekends ? "" : "business") + " weeks "
            : days + labelTxt + (days === "1" ? "" : "s")
    }
    if (months < 1) {
        const ws = addWeeks(startDate, weeks)
        remainderDays = differenceInDays(adjustedEndDate, ws)
        return (
            weeks +
            " week" +
            (weeks === 1 ? "" : "s") +
            (remainderDays ? ", " + remainderDays + " day" + (remainderDays === 1 ? "" : "s") : "")
        )
    }
    if (months >= 1) {
        const mns = addMonths(startDate, months)

        const remainderWeeks = differenceInWeeks(adjustedEndDate, mns)
        remainderDays = 0

        let returnText = months + " month" + (months === 1 ? "" : "s")

        if (remainderWeeks > 0) {
            returnText += ", " + remainderWeeks + " week" + (remainderWeeks === 1 ? "" : "s")

            const a = addMonths(startDate, months)
            const b = addWeeks(a, remainderWeeks)

            remainderDays = differenceInDays(b, adjustedEndDate)

            if (remainderDays > 0) {
                returnText += ", " + remainderDays + " day" + (remainderDays === 1 ? "" : "s")
            }
        } else {
            remainderDays = differenceInCalendarDays(adjustedEndDate, mns)

            if (remainderDays > 0) returnText += ", " + remainderDays + " day" + (remainderDays === 1 ? "" : "s")
        }

        return returnText
    }
}

/**
 * Converts timestamp to date, preserving the UTC timezone.
 *
 * @param date {Number} Timestamp
 * @return {Date} Date zoned in UTC
 */
export function timestampToUtcDate(date) {
    try {
        return toZonedTime(date, { timezone: "+00:00" })
    } catch (err) {
        return undefined
    }
}

/**
 * Date-fns startOfISOWeek, but with UTC timezone.
 */
export function startOfISOWeekUtc(date) {
    try {
        return fromZonedTime(startOfWeek(date, { weekStartsOn: 1 }), { timezone: "+00:00" })
    } catch (err) {
        return undefined
    }
}

/**
 * Date-fns endOfISOWeek, but with UTC timezone.
 */
export function endOfISOWeekUtc(date) {
    try {
        return fromZonedTime(endOfWeek(date, { weekStartsOn: 1 }), { timezone: "+00:00" })
    } catch (err) {
        return undefined
    }
}

const roundToDivisor = (num, divisor) => Math.round(num / divisor) * divisor
