import React from 'react';
import classnames from 'classnames';

import './ViewVideo.scss';
import apiFile from '../../services/file/file.api';
import ViewUnsupported from './ViewUnsupported';
import {getFileUrl} from '../../services/file/file.utils';

export default class ViewAudio extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            url: null,
            isLoaded: false
        };

        this.cancelTokenSource = null;
    }

    componentDidMount() {
        const {file} = this.props;
        this.setState({url: getFileUrl(file)});
        this.setIsLoaded();
    }

    setIsLoaded() {
        const {onLoad} = this.props;
        onLoad && onLoad();
        this.setState({
            isLoaded: true
        });
    }

    componentWillUnmount() {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel();
        }
    }

    onUnsupportedLoad() {
        const {onLoad} = this.props;
        onLoad && onLoad();
    }

    getMimeType(file) {
        switch (file.mimeType) {
            case 'video/quicktime':
                return 'video/mp4';
            default:
                return file.mimeType;
        }
    }

    onViewClick(e) {
        e.preventDefault();
        e.stopPropagation();
    }


    render() {
        const {file} = this.props;
        const {url, isLoaded} = this.state;

        const cx = classnames(
            'dna-drive-view--video',
            {'dna-drive-view--video-is-hidden': !isLoaded}
        );

        return (<>
            {!!url && <div className={cx} >
                <video
                    autoPlay
                    controls
                    controlsList='nodownload'
                    onClick={(e) => this.onViewClick(e)}
                >
                    <source src={url}/>
                    <ViewUnsupported onLoad={() => this.onUnsupportedLoad()} file={file}/>
                </video>
            </div>}
        </>);
    }
}