import React from "react"

export default ({width = 9, height = 29}) => (
    <svg width={width} height={height} viewBox="0 0 9 29" className="dna-svg-icon dna-icon-dots">

          <circle cx="4.5" cy="4.5" r="3.5"></circle>
          <circle cx="4.5" cy="14.5" r="3.5"></circle>
          <circle cx="4.5" cy="24.5" r="3.5"></circle>
      </svg>
)
