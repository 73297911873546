import { setDayOfYear } from "date-fns"
import { isWithinInterval } from "date-fns/isWithinInterval"
import { isSameDay } from "date-fns/isSameDay"
import { isSameMonth } from "date-fns/isSameMonth"
import { isSameYear } from "date-fns/isSameYear"
import { isAfter } from "date-fns/isAfter"
import uniqBy from "lodash/uniqBy"
import { MISSION_PERMISSION } from "../services/mission/mission.const"
import { ORG_PERMISSION } from "../services/org/org.const"
import { getObjectId } from "./Utils"
import { isArchived, getRefId } from "../utils/utils"

/**
 * Org people that can action (and view) leave requests.
 *
 * @param orgUser {Object}
 * @param orgUser.canApproveLeaves {Boolean}
 * @param orgUser.permission {Number}
 * @return {boolean}
 */
export function canViewLeaveOrg(orgUser) {
    return orgUser.canApproveLeaves === true || [ORG_PERMISSION.ADMIN].includes(orgUser.permission)
}

/**
 * Mission people that can action (and view) leave requests.
 *
 * @param leave {Object}
 * @param leave.dates {Object[]}
 * @param mission {Object}
 * @param mission.planStartDate {Number}
 * @param mission.planEndDate {Number}
 * @param missionUser {Object}
 * @param missionUser.isProjectManager {Boolean}
 * @param missionUser.permission {Number}
 * @return {boolean}
 */
export function canViewLeaveMission(leave, mission, missionUser) {
    return (
        isLeaveWithinRange(leave.dates, mission.planStartDate, mission.planEndDate) &&
        (missionUser.isProjectManager === true || [MISSION_PERMISSION.ADMIN].includes(missionUser.permission))
    )
}

/**
 * Get the Date from a leave date object.
 *
 * @param leaveDate {Object}
 * @param leaveDate.year {Number}
 * @param leaveDate.dayOfYear {Number}
 * @return {Date}
 */
export function getLeaveDate(leaveDate) {
    if (!leaveDate) return null
    return setDayOfYear(new Date(leaveDate.year, 1), leaveDate.dayOfYear)
}

export function sortLeavesByDate(leaves) {
    if (!leaves || leaves.length === 0) return []

    return leaves.sort((a, b) => {
        const l = leaves

        const startDateA = a.dates ? getLeaveDate(a.dates[0].from).getTime() : 0
        const startDateB = b.dates ? getLeaveDate(b.dates[0].from).getTime() : 0

        return startDateA - startDateB
    })
}

export function getLeavesDropDownList(lvs, activeOnly) {
    let leaves = lvs || []
    if (leaves.length === 0) {
        return []
    }

    let active = lvs.filter((l) => !isArchived(l))

    if (activeOnly) {
        return uniqBy(active || [], "id")
    }
    let archived = lvs.filter((l) => isArchived(l))
    let cleanLeaves = [...active]

    archived.forEach((arc, i) => {
        if (!active.find((a) => a.id === arc.id)) {
            cleanLeaves.push(arc)
        }
    })

    cleanLeaves = uniqBy(cleanLeaves || [], "id")

    return cleanLeaves
}

export function leavesSortedByTo(leaves) {
    return leaves.sort((a, b) => {
        const dateA = getLeaveDate(a.dates[0].from).getTime()
        const dateB = b.dates[0].to ? getLeaveDate(b.dates[0].to).getTime() : dateA

        return dateB - dateA
    })
}

/**
 * Sorts an array of leave dates by "from".
 *
 * @param leaveDates {Object[]}
 * @param leaveDates[].year {Number}
 * @param leaveDates[].dayOfYear {Number}
 * @return {Object[]}
 */
export function sortLeaveDatesByFrom(leaveDates = []) {
    try {
        return leaveDates.sort((dateA, dateB) => {
            if (dateA.from.year < dateB.from.year) {
                return -1
            }

            if (dateA.from.year > dateB.from.year) {
                return 1
            }

            if (dateA.from.dayOfYear < dateB.from.dayOfYear) {
                return -1
            }

            if (dateA.from.dayOfYear > dateB.from.dayOfYear) {
                return 1
            }

            return 0
        })
    } catch (err) {
        return leaveDates
    }
}

/**
 * Tests if the leave request dates are within a given date range.
 *
 * @param leaveDates {Object[]}
 * @param leaveDates[].from {Object}
 * @param leaveDates[].to {Object}
 * @param dateFrom {Number|Date}
 * @param [dateTo] {Number|Date} If not provided, infinite
 * @return {boolean}
 */
export function isLeaveWithinRange(leaveDates, dateFrom, dateTo) {
    if (!dateFrom) {
        return false
    }

    try {
        return sortLeaveDatesByFrom(leaveDates).some((leaveDate) => {
            const leaveFromDate = getLeaveDate(leaveDate.from)
            const leaveToDate = getLeaveDate(leaveDate.to || leaveDate.from)

            return (
                (dateTo
                    ? isWithinInterval(leaveFromDate, {
                          start: dateFrom,
                          end: dateTo,
                      })
                    : isSameOrAfter(leaveFromDate, dateFrom)) ||
                (dateTo
                    ? isWithinInterval(leaveToDate, {
                          start: dateFrom,
                          end: dateTo,
                      })
                    : isSameOrAfter(leaveToDate, dateFrom))
            )
        })
    } catch (err) {
        return false
    }
}

/**
 * Tests if an array of leave dates overlap between them.
 *
 * @param leaveDates {Object[]}
 * @param leaveDates[].from {Object}
 * @param leaveDates[].to {Object}
 * @return {Boolean}
 */
export function hasLeaveDatesOverlap(leaveDates = []) {
    const leaveDatesSorted = sortLeaveDatesByFrom(leaveDates)
    return leaveDatesSorted.some((item, i) => {
        if (i === 0) {
            return false
        }

        const currDateItem = item
        const prevDateItem = leaveDatesSorted[i - 1]

        return isWithinInterval(getLeaveDate(currDateItem.from), {
            start: getLeaveDate(prevDateItem.from),
            end: getLeaveDate(prevDateItem.to || prevDateItem.from),
        })
    })
}

/**
 * Validation for leave dates.
 *
 * @param leaveDates {Object[]}
 * @param leaveDates[].from {Object}
 * @param leaveDates[].to {Object}
 * @return {
 *      {
 *          isValid: boolean,
 *          error: {
 *              code: string,
 *              message: string
 *          }
 *      }|{
 *          isValid: boolean
 *      }
 * }
 */
export function validateLeaveDates(leaveDates) {
    if (!Array.isArray(leaveDates) || leaveDates.length === 0) {
        return {
            isValid: false,
            error: {
                code: "leave_date/empty",
                message: "Leave request dates must be provided.",
            },
        }
    }

    try {
        const leaveDatesSorted = sortLeaveDatesByFrom(leaveDates)
        let result = {
            isValid: true,
        }

        for (let i = 0; i < leaveDatesSorted.length; i++) {
            const item = leaveDatesSorted[i]

            if (!item.from || !item.from.year || !item.from.dayOfYear) {
                result = {
                    isValid: false,
                    error: {
                        code: "leave_date/from_required",
                        message: 'Leave request "from.year" and "from.dayOfYear" values are both required.',
                    },
                }
                break
            }

            if (
                !Number.isSafeInteger(item.from.year) ||
                !Number.isSafeInteger(item.from.dayOfYear) ||
                (!!item.to && (!Number.isSafeInteger(item.to.year) || !Number.isSafeInteger(item.to.dayOfYear)))
            ) {
                result = {
                    isValid: false,
                    error: {
                        code: "leave_date/integer_required",
                        message: "Invalid value was passed. Leave request date field values should contain integers.",
                    },
                }
                break
            }

            if (item.from && item.to && isAfter(getLeaveDate(item.from), getLeaveDate(item.to))) {
                result = {
                    isValid: false,
                    error: {
                        code: "leave_date/from_after_to",
                        message: 'Invalid date range was passed. The "from" date cannot come after the "to" date.',
                    },
                }
                break
            }
        }

        if (hasLeaveDatesOverlap(leaveDatesSorted)) {
            result = {
                isValid: false,
                error: {
                    code: "leave_date/range_overlap",
                    message: "Leave dates are overlapping.",
                },
            }
        }

        return result
    } catch (err) {
        throw {
            isValid: false,
            error: {
                code: "leave_date/unexpected_error",
                message: err.message,
            },
        }
    }
}

/**
 * Is sameOrAfter function with "day" precision.
 *
 * @param dateA {Date|Number}
 * @param dateB {Date|Number}
 * @return {Boolean}
 */
function isSameOrAfter(dateA, dateB) {
    return (isSameYear(dateA, dateB) && isSameMonth(dateA, dateB) && isSameDay(dateA, dateB)) || isAfter(dateA, dateB)
}
