import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import api from '../api.service';
import {getFileStream, getFileStreamWriter, addToAbortControllers, downloadFile} from './file.service';

const queryString = require('query-string');

class FileApi {

    getCancelToken() {
        return api.getCancelToken();
    }

    isCancel(err) {
        return api.isCancel(err);
    }

    getFile(fileId) {
        return api.get(`/drive/file/${fileId}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            })
    }

    getFileSignedUrl(fileId, options = {}) {
        const qs = queryString.stringify(options || {});
        return api.get(`/drive/signed/${fileId}${!isEmpty(options) ? '?' + qs : ''}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            })
    }

    deleteFile(fileId) {
        return api.delete(`/drive/file/${fileId}`).catch(err => {
            throw api.getResponseError(err);
        })
    }

    /**
     * Get a file's contents (Blob)
     * @param fileId {String}
     * @param [options] {Object}
     * @param [options.start] {Number} Stream size in bytes. If provided, the file will be streamed starting from the bytes specified.
     * @param [options.end] {Number} Stream size in bytes. If provided, the file will be streamed until the bytes specified.
     * @param [options.attach] {*} If specified, the file will be returned with Content-Disposition: attachment (https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition)
     * @param [options.size] {String} Defaults to original. Options: 'preview'
     * @param [options.onDownloadProgress] {Function(loaded, total)} Progress callback
     * @param [reqOptions] {Object} Optional Axios request options
     * @return {Promise<*>}
     */
    getFileContents(fileId, options = {}, reqOptions = {}) {

        const qs = queryString.stringify(options || {});

        return api.get(`/drive/${fileId}${!isEmpty(options) ? '?' + qs : ''}`, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                if (typeof options.onDownloadProgress === 'function') {
                    options.onDownloadProgress(progressEvent.loaded, progressEvent.total);
                }
            },
            ...reqOptions
        })
            .then(res => res.data)
            .catch(err => {
                throw get(err, 'response.data') || err
            })
    }

    streamFileDownload(file, options = {}) {
        const controller = new AbortController();
        const signal = controller.signal;
        const qs = queryString.stringify({
            attach: true,
            ...(options || {})
        });
        const isExternalUrl = file.source === 'external';
        const url = isExternalUrl ? file.path : `/drive/${file._id}${!isEmpty(options) ? '?' + qs : ''}`;

        addToAbortControllers(controller);

        return (isExternalUrl ? fetch(url, {signal}) : api.fetch(url, {signal}))
            .then(res => {
                const fileStream = getFileStream(file);
                const readableStream = res.body;

                if (window.WritableStream && readableStream.pipeTo) {
                    return readableStream.pipeTo(fileStream);
                }

                // Fallback
                window.writer = getFileStreamWriter(fileStream);
                const reader = res.body.getReader();
                const pump = () => reader
                    .read()
                    .then(res => res.done
                        ? window.writer.close()
                        : window.writer.write(res.value).then(pump));

                return pump();

            })
            .catch(err => {
                if (isExternalUrl) {
                    // Some external URLs do not permit download (error 302 redirect).
                    // Try to just download by triggering a download link click and let the browser deal with it.
                    downloadFile(url, file.name);
                } else {
                    throw api.getResponseError(err);
                }
            })
    }

}

export default new FileApi()
