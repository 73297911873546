import React from "react"
import Button from "./Button"
import cx from "classnames"
import BlurHandler from "./BlurHandler"

export default class ButtonConfirm extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            confirm: false,
        }
    }
    render() {
        //See Button.scss
        const cn = cx("dna-button dna-confirm-button", this.props.className, {
            "confirm-it": this.state.confirm,
            "dna-button-clear": this.props.clear,
            "dna-button-disabled": this.props.disabled,
            "dna-button-clear": this.props.clear,
            "dna-danger-button": this.props.danger,
            "dna-button-block": this.props.block,
            "dna-space-right": this.props.spaceRight,
            "dna-space-left": this.props.spaceLeft,
            clear: this.props.clear,
            "dna-dark-button": this.props.dark,
            "dna-accent-button": this.props.accent,
            "lime-button": this.props.lime,
            "dna-red-button": this.props.red,
            "dna-mint-button": this.props.mint,
            solid: this.props.solid,
            "has-svg": this.props.icon,
        })

        return (
            <BlurHandler
                onBlur={() => {
                    this.setState({ confirm: false })
                }}
            >
                <div
                    className={cn}
                    onClick={(e) => {
                        e.stopPropagation()
                        this.setState({ confirm: true })
                        if (this.props.onConfirmClick) {
                            this.props.onConfirmClick()
                        }
                    }}
                >
                    {this.props.children}
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            this.setState(
                                {
                                    confirm: false,
                                },
                                () => {
                                    this.props.onClick(e)
                                }
                            )
                        }}
                    >
                        {this.props.text || "You sure?"}
                    </Button>
                </div>
            </BlurHandler>
        )
    }
}
