import React from "react"

export default ({ width = 6 }) => (
    <svg width={width} height={width} viewBox="0 0 26 46" className="dna-svg-icon dna-icon-path dna-icon-chevron">
        <path
            d="M31.232233,34.767767 C32.2085438,35.7440777 33.7914562,35.7440777 34.767767,34.767767 C35.7440777,33.7914562 35.7440777,32.2085438 34.767767,31.232233 L14.767767,11.232233 C13.7914562,10.2559223 12.2085438,10.2559223 11.232233,11.232233 L-8.767767,31.232233 C-9.7440777,32.2085438 -9.7440777,33.7914562 -8.767767,34.767767 C-7.7914562,35.7440777 -6.2085438,35.7440777 -5.232233,34.767767 L12.9997921,16.5357418 L31.232233,34.767767 Z"
            id="Path"
            transform="translate(13.000000, 23.000000) rotate(90.000000) translate(-13.000000, -23.000000) "
        ></path>
    </svg>
)
