import React from "react"
import cx from "classnames"
import Tip from "./Tip"
import Badge from "@material-ui/core/Badge"
import IconX from "./icons/IconX"
import { displayRole } from "./Utils"
import { getCustomRoleLabelByCustomRole } from "./MissionUtils"
import { v4 as uniqid } from "uuid"

const ToolTipComponent = (props) => {
    return (
        <div className="dna-avatar-details">
            <JustTheAvatar
                {...props}
                fixSize={true}
                noTip={true}
                size="large"
                active={false}
                color={true}
                noBagdes={true}
                noSize={true}
            />

            <div className="dna-avatar-detail-contents">
                {props.firstName && (
                    <div>
                        <h3>
                            {props.firstName} {props.lastName}
                        </h3>

                        {props.customRole ? (
                            <label>
                                {props.customRole.map((c) => {
                                    return getCustomRoleLabelByCustomRole(c) + " "
                                })}
                            </label>
                        ) : (
                            <label>
                                {props.role
                                    ? displayRole(props.role, props.org)
                                    : props.title
                                    ? !props.title
                                    : "Team member"}
                            </label>
                        )}
                        {!!props.state && !!props.country && <label>{props.state + ", " + props.country}</label>}
                        {!!props.timezone && <label>{props.timezone}</label>}

                        {!!props.email && (
                            <a
                                style={{ fontSize: 12, textDecoration: "none", border: 0 }}
                                href={"mailto:" + props.email}
                            >
                                {props.email}
                            </a>
                        )}
                    </div>
                )}

                {props.msg && <div className="dna-avatar-msg">{props.msg}</div>}

                {props.onRemove && (
                    <div
                        className="cursor dna-avatar-remove-trigger x-ignore-class dont-close-action-item x-av-remove-link"
                        onMouseDown={(e) => {
                            e.stopPropagation()
                            e.preventDefault()

                            props.onRemove(props._id)
                        }}
                    >
                        Remove this person
                    </div>
                )}

                {!props.firstName && (
                    <div className="dna-col">
                        <h3>Hello.</h3>
                        <p>I don't have an avatar yet.</p>
                    </div>
                )}
            </div>
        </div>
    )
}

const WithBadge = (props) => {
    if (!props.noBagdes && (props.messenger?.length || props.newMessages?.length)) {
        const color = props.newMessages?.length ? "secondary" : "primary"
        const count = props.newMessages?.length || props.messenger?.length
        return (
            <Badge color={color} badgeContent={count}>
                {props.children}
            </Badge>
        )
    } else {
        return props.children
    }
}

const JustTheAvatar = (props) => {
    const { avatar, size, avatarStyle, active, className, adding, color, addUser, round, style } = props

    const av = avatar || props.avatar || props.ref?.avatar || props.person?.ref?.avatar || props.person?.avatar

    const img = av ? `url(${av})` : undefined

    if (round) {
        return (
            <WithBadge noBagdes={props.noBagdes} messenger={props.messenger} newMessages={props.newMessages}>
                <div
                    className={cx("dna-round-avatar", props.className, {
                        smaller: !props.noSize && (props.smaller || size === "smaller"),
                        small: !props.noSize && (props.small || size === "small"),
                    })}
                    style={{ backgroundImage: img, ...style }}
                ></div>
            </WithBadge>
        )
    }

    return (
        <WithBadge noBagdes={props.noBagdes} messenger={props.messenger} newMessages={props.newMessages}>
            <div
                key={0}
                onClick={props.onClick}
                style={!props.fixSize ? avatarStyle : undefined}
                className={cx("dna-avatar", className, {
                    smallest: size === "smallest",
                    tiny: size === "tiny",
                    small: size === "small",
                    large: size === "large",
                    huge: size === "huge",
                    active: active,
                    "add-user": addUser,
                    color: color,
                })}
            >
                {adding && (
                    <Tip title="Add or remove people.">
                        <IconX width={14} />
                    </Tip>
                )}

                {!adding && (
                    <div className="dna-avatar-inner">
                        <div className="dna-avatar-img" style={{ backgroundImage: img }} />
                    </div>
                )}
            </div>
        </WithBadge>
    )
}

class Avatar extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            details: false,
        }
    }
    render() {
        let theDetails = { ...this.props }

        if (theDetails.ref) delete theDetails.ref

        if (this.props.noTip) {
            return <JustTheAvatar {...theDetails} />
        } else {
            return [
                <Tip
                    key={uniqid()}
                    html={<ToolTipComponent {...theDetails} />}
                    position={this.props.position || "top"}
                    distance={this.props.distance || 0}
                    animation="shift"
                    delay={300}
                    interactive={true}
                    interactiveBorder={70}
                    theme="dna-avatar-tip"
                    offset={this.props.offset || "0, 0"}
                    {...this.props.tipProps}
                    noFlip={this.props.noFlip}
                >
                    <JustTheAvatar {...theDetails} />
                </Tip>,
            ]
        }
    }
}

Avatar.displayName = "Avatar"
export default Avatar

/*
ON FIRE

https://codepen.io/shubniggurath/pen/RdpmGJ
//https://codepen.io/AlikinVV/pen/wOyGNJ

.bubbles {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 150px;
	height: 150px;
}


<div class="box">



    <svg id="organic-blob" width="150" height="250" xmlns="http://www.w3.org/2000/svg"  fill="#ff2900">

        <g filter="url(#goo)">
        <circle r="50" cy="172.5" cx="75">
            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 72.5 175" to="360 72.5 175" dur="2s" repeatCount="indefinite"/>
            </circle>
        <circle r="50" cy="177.5" cx="75">
            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="360 77.5 175" to="0 77.5 175" dur="3s" repeatCount="indefinite"/>
            </circle>
        <circle r="50" cy="175" cx="72.5">
            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 75 172.5" to="360 75 172.5" dur="4s" repeatCount="indefinite"/>
            </circle>
        <circle r="50" cy="175" cx="77.5">
            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="360 75 177.5" to="0 75 177.5" dur="3.5s" repeatCount="indefinite"/>
            </circle>

        </g>

        <g filter="url(#goo)" class="bubbles" cy="20"></g>
    </svg>

    <svg class="mask" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
</div>





let bubblesCount = 70;
let bubbles = document.querySelector('.bubbles');

let getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

for (let i = 0; i < bubblesCount; i++) {
    let circle = document.createElement('circle');

    circle.setAttribute('r', getRandomInt(5, 15));
    circle.setAttribute('cy', 300);
    circle.setAttribute('cx', getRandomInt(40, 110));
    circle.setAttribute('fill', '#ff2900');
    bubbles.append(circle);
}

let html = $('.bubbles').html();
$('.bubbles').html(html);

let circles = document.querySelectorAll('.bubbles circle');

for (let i = 0; i < circles.length; i++) {
    let del = getRandomInt(0, 1000 * 3);
    let xv = getRandomInt(-35, 35);

    anime({
        targets: circles[i],
        r: {
            value: 0,
            duration: 500,
            delay: del + 1000
        },
        translateX: {
            value: xv,
            duration: 800,
            delay: del,
        },
        translateY: {
            value: [0, -150],
            duration: 2000,
            delay: del,
        },
        loop: true,
        easing: 'linear'
    })
}


*/
