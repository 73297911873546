import { toZonedTime } from "date-fns-tz"
import { eachMonthOfInterval } from "date-fns/eachMonthOfInterval"

import isBefore from "date-fns/isBefore"
import isAfter from "date-fns/isAfter"
import startOfYear from "date-fns/startOfYear"
import endOfYear from "date-fns/endOfYear"
import differenceInYears from "date-fns/differenceInYears"
import groupBy from "lodash/groupBy"

const holidays = {
    GR: [
        {
            year: 2023,
            dates: [
                "Jan 1",
                "Jan 6",
                "Feb 27",
                "Mar 25",
                "Apr 14",
                "Apr 17",
                "May 1",
                "Jun 4",
                "Jun 5",
                "Aug 15",
                "Oct 28",
                "Dec 25",
                "Dec 26",
            ],
        },
        {
            year: 2024,
            dates: [
                "Jan 1",
                "Jan 6",
                "Mar 18",
                "Mar 25",
                "May 1",
                "May 3",
                "May 6",
                "Jun 23",
                "Jun 24",
                "Aug 15",
                "Oct 28",
                "Dec 25",
                "Dec 26",
            ],
        },
    ],
    CA: [
        {
            year: 2023,
            dates: ["Jan 1", "Apr 7", "May 22", "Jul 1", "Aug 7", "Sep 4", "Sep 30", "Oct 9", "Nov 11", "Dec 25"],
        },
        {
            year: 2024,
            dates: ["Jan 1", "Mar 29", "May 20", "Jul 1", "Aug 5", "Sep 2", "Sep 30", "Oct 14", "Nov 11", "Dec 25"],
        },
    ],
    FR: [
        {
            year: 2023,
            dates: [
                "Jan 1",
                "Apr 9",
                "Apr 10",
                "May 1",
                "May 8",
                "May 18",
                "May 29",
                "Jul 14",
                "Aug 15",
                "Nov 1",
                "Nov 11",
                "Dec 25",
            ],
        },
        {
            year: 2024,
            dates: [
                "Jan 1",
                "Mar 31",
                "Apr 1",
                "May 1",
                "May 8",
                "May 9",
                "May 20",
                "Jul 14",
                "Aug 15",
                "Nov 1",
                "Nov 11",
                "Dec 25",
            ],
        },
    ],
    UK: [
        {
            year: 2023,
            dates: ["Jan 2", "Apr 7", "Apr 10", "May 1", "May 8", "May 29", "Aug 28", "Dec 25", "Dec 26"],
        },
        {
            year: 2024,
            dates: ["Jan 1", "Mar 29", "Apr 1", "May 6", "May 27", "Aug 6", "Dec 25", "Dec 26"],
        },
    ],
    US: [
        {
            year: 2023,
            dates: ["Jan 1", "Jan 16", "Feb 20", "May 29", "Jul 4", "Sep 4", "Nov 10", "Nov 23", "Dec 25"],
        },
        {
            year: 2024,
            dates: ["Jan 1", "Jan 15", "Feb 19", "May 27", "Jul 4", "Sep 2", "Nov 11", "Nov 28", "Dec 25"],
        },
    ],
}

const getGroupedYears = (code) => {
    const group = holidays[code].map((o) => {
        return {
            year: o.year,
            dates: o.dates.map((d) => new Date(d + " " + o.year)),
        }
    })

    return groupBy(group, "year")
}

function getAllStatDays(code) {
    if (!holidays[code]) return []

    let days = []
    holidays[code].forEach((hd, i) => {
        days = [...days, ...hd.dates.map((dd) => new Date(dd + " " + hd.year))]
    })

    return days
}

const formulas = {
    GR: greekRules,
}

function greekRules({ person, orgData, year, holidays }) {
    if (!person.startDate) {
        return 0
    }

    const startDate = toZonedTime(person.startDate)

    const preService = person.yearsOfPreService || "0-0-0"
    const calculationYear = year

    const startYear = new Date(startDate).getFullYear()
    const preServiceYears = convertPreServiceToYears(preService)
    const yearsWorked = calculationYear - startYear

    let totalYearsWorked = preServiceYears + yearsWorked

    const has10YearsAtCompany = differenceInYears(new Date(), toZonedTime(person.startDate)) >= 10

    const has12Years = (totalYearsWorked >= 12 && totalYearsWorked < 25) || has10YearsAtCompany
    const has25Years = totalYearsWorked >= 25

    const calculatedLeave = calculateAnnualLeave({
        yearsWorked,
        has12Years,
        has25Years,
        holidays,
        orgData,
        startDate,
        year,
        orgPerson: person,
    })

    return calculatedLeave
}

const getHolidayCodes = (countryCode) => {
    if (holidayCodes[countryCode]) {
        return holidayCodes[countryCode]
    } else {
        return holidayCodes["DEFAULT"]
    }
}

const holidayCodes = {
    DEFAULT: [
        {
            id: "annual-leave",
            code: "ALV",
            fixed: true,
            description: "Annual holiday leave",
            isProjectLevel: true,
        },
        {
            id: "training-leave",
            code: "TRNL",
            description: "Training",
        },
        {
            id: "personal-leave",
            code: "PRSL",
            description: "Personal time",
        },
        {
            id: "medical-leave",
            code: "MDCL",
            description: "Medical leave",
        },
    ],
    US: [
        {
            id: "annual-leave",
            description: "Paid Time Off (PTO)",
            code: "PTO",
            fixed: true,
            isProjectLevel: true,
            days: [],
            comments:
                "Paid time off that includes vacation, personal days, and sick days. Typically 10-20 days per year depending on the company and tenure.",
            minHours: null,
            allowHalfDays: true,
            maxHours: null,
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                {
                    year: 2024,
                    amount: 5,
                },
                {
                    year: 2025,
                    amount: 5,
                },
            ],
            comments:
                "Paid or unpaid leave for illness, varies by state. Some states mandate paid sick leave for full-time employees.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                {
                    year: 2024,
                    amount: 84,
                },
                {
                    year: 2025,
                    amount: 84,
                },
            ],
            comments:
                "Up to 12 weeks of unpaid leave under FMLA for birth mothers. Some employers may offer paid leave.",
        },
        {
            id: "paternity-leave",
            description: "Paternity Leave",
            code: "PAT",
            days: [
                {
                    year: 2024,
                    amount: 84,
                },
                {
                    year: 2025,
                    amount: 84,
                },
            ],
            comments:
                "Up to 12 weeks of unpaid leave under FMLA for fathers or non-birth parents. Paid leave may be offered by some employers.",
        },
        {
            id: "bereavement-leave",
            description: "Bereavement Leave",
            code: "BER",
            days: [
                {
                    year: 2024,
                    amount: 3,
                },
                {
                    year: 2025,
                    amount: 3,
                },
            ],
            comments:
                "Typically 3-5 days of paid leave for the death of a close family member. Not mandated federally but offered by many employers.",
        },
        {
            id: "family-care-leave",
            description: "Family Care Leave",
            code: "FCL",
            days: [
                {
                    year: 2024,
                    amount: 84,
                },
                {
                    year: 2025,
                    amount: 84,
                },
            ],
            comments:
                "Up to 12 weeks of unpaid leave under FMLA to care for a family member with a serious health condition.",
        },
        {
            id: "jury-duty-leave",
            description: "Jury Duty Leave",
            code: "JUR",
            days: [],
            comments: "",
        },
        {
            id: "military-leave",
            description: "Military Leave",
            code: "MIL",
            days: [],
            comments:
                "Unpaid leave under the Uniformed Services Employment and Reemployment Rights Act (USERRA) for military service. Some employers may offer paid leave.",
        },
        {
            id: "unpaid-leave",
            description: "Unpaid Leave",
            code: "UPL",
            days: [],
            comments:
                "Leave without pay, typically job-protected under specific circumstances such as medical reasons or personal leave.",
        },
        {
            id: "training-leave",
            code: "TRNL",
            description: "Training",
        },
    ],
    CA: [
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            fixed: true,
            isProjectLevel: true,
            days: [],
            comments:
                "Typically 2-4 weeks of paid vacation leave per year depending on employment contract or years of service.",
            minHours: null,
            allowHalfDays: true,
            maxHours: null,
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                {
                    year: 2024,
                    amount: 10,
                },
                {
                    year: 2025,
                    amount: 10,
                },
            ],
            comments:
                "Paid or unpaid leave for illness, varies by province. Federally regulated workers get at least 10 days per year.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                {
                    year: 2024,
                    amount: 119,
                },
                {
                    year: 2025,
                    amount: 119,
                },
            ],
            comments: "17 weeks of unpaid leave for birth mothers, with possible employment insurance benefits.",
        },
        {
            id: "parental-leave",
            description: "Parental Leave",
            code: "PAR",
            days: [
                {
                    year: 2024,
                    amount: 104,
                },
                {
                    year: 2025,
                    amount: 104,
                },
            ],
            comments: "Up to 63 weeks of unpaid leave, combined with maternity leave if applicable.",
        },
        {
            id: "bereavement-leave",
            description: "Bereavement Leave",
            code: "BER",
            days: [
                {
                    year: 2024,
                    amount: 5,
                },
                {
                    year: 2025,
                    amount: 5,
                },
            ],
            comments: "Up to 5 days leave (at least 3 paid) upon the death of a close family member.",
        },
        {
            id: "compassionate-care-leave",
            description: "Compassionate Care Leave",
            code: "CARE",
            days: [
                {
                    year: 2024,
                    amount: 28,
                },
                {
                    year: 2025,
                    amount: 28,
                },
            ],
            comments:
                "Up to 28 weeks of unpaid leave to care for a critically ill family member, with possible EI benefits.",
        },
        {
            id: "jury-duty-leave",
            description: "Jury Duty Leave",
            code: "JUR",
            days: [],
            comments: "Unpaid leave for jury duty, with job protection during the period of jury service.",
        },
        {
            id: "unpaid-leave",
            description: "Unpaid Leave",
            code: "UPL",
            days: [],
            comments:
                "Employee can take unpaid leave at employer's discretion, generally job-protected for specific situations.",
        },
        {
            id: "training-leave",
            code: "TRNL",
            description: "Training",
        },
    ],
    CA_FR: [
        {
            id: "annual-leave",
            description: "Congé annuel",
            code: "ANLVE",
            fixed: true,
            isProjectLevel: true,
            days: [],
            comments:
                "Typiquement 2 à 4 semaines de congé payé par an, en fonction du contrat de travail ou des années de service.",
            minHours: null,
            allowHalfDays: true,
            maxHours: null,
        },
        {
            id: "sick-leave",
            description: "Congé maladie",
            code: "SICK",
            days: [
                {
                    year: 2024,
                    amount: 10,
                },
                {
                    year: 2025,
                    amount: 10,
                },
            ],
            comments:
                "Congé payé ou non pour maladie, variable selon la province. Les travailleurs sous réglementation fédérale ont droit à au moins 10 jours par an.",
        },
        {
            id: "maternity-leave",
            description: "Congé de maternité",
            code: "MAT",
            days: [
                {
                    year: 2024,
                    amount: 119,
                },
                {
                    year: 2025,
                    amount: 119,
                },
            ],
            comments:
                "17 semaines de congé non payé pour les mères biologiques, avec des prestations d'assurance emploi possibles.",
        },
        {
            id: "parental-leave",
            description: "Congé parental",
            code: "PAR",
            days: [
                {
                    year: 2024,
                    amount: 104,
                },
                {
                    year: 2025,
                    amount: 104,
                },
            ],
            comments: "Jusqu'à 63 semaines de congé non payé, combiné avec le congé de maternité si applicable.",
        },
        {
            id: "bereavement-leave",
            description: "Congé de deuil",
            code: "BER",
            days: [
                {
                    year: 2024,
                    amount: 5,
                },
                {
                    year: 2025,
                    amount: 5,
                },
            ],
            comments: "Jusqu'à 5 jours de congé (au moins 3 payés) en cas de décès d'un proche.",
        },
        {
            id: "compassionate-care-leave",
            description: "Congé de soins compassionnels",
            code: "CARE",
            days: [
                {
                    year: 2024,
                    amount: 28,
                },
                {
                    year: 2025,
                    amount: 28,
                },
            ],
            comments:
                "Jusqu'à 28 semaines de congé non payé pour s'occuper d'un membre de la famille gravement malade, avec des prestations d'assurance emploi possibles.",
        },
        {
            id: "jury-duty-leave",
            description: "Congé pour jury",
            code: "JUR",
            days: [],
            comments:
                "Congé non payé pour servir dans un jury, avec protection d'emploi pendant la période du service.",
        },
        {
            id: "unpaid-leave",
            description: "Congé sans solde",
            code: "UPL",
            days: [],
            comments:
                "L'employé peut prendre un congé sans solde à la discrétion de l'employeur, généralement protégé pour des situations spécifiques.",
        },
        {
            id: "training-leave",
            code: "TRNL",
            description: "Formation",
        },
    ],
    GR: [
        {
            id: "annual-leave",
            description: "Κανονική",
            code: "ANLVE",
            fixed: true,
            isProjectLevel: true,
            days: [],
            comments: "",
            minHours: null,
            allowHalfDays: null,
            maxHours: null,
        },
        {
            id: "79153038-6017-45e9-b3ef-ff0f09f4c7f8",
            description: "Εκπαιδευτική",
            code: "EDU",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: null,
                },
                {
                    year: 2025,
                    amount: null,
                },
            ],
            comments: null,
        },
        {
            id: "89f7f2f2-e5fd-4cec-9093-9403dd67fb1f",
            description: "Αναρρωτική",
            code: "ILL",
            days: [
                {
                    year: 2024,
                    amount: null,
                },
                {
                    year: 2025,
                    amount: null,
                },
            ],
        },
        {
            id: "3ba01e77-bb89-40cf-bedb-8bd1c4f3bf15",
            description: "Αιμοδοτική",
            code: "BL",
            days: [
                {
                    year: 2024,
                    amount: 4,
                },
                {
                    year: 2025,
                    amount: 4,
                },
            ],
            comments: "",
            minHours: null,
            maxHours: 16,
            timesPerYear: 2,
        },
        {
            id: "2534de14-b524-461f-bc22-ad84245bdb66",
            description: "Γάμου",
            code: "MAR",
            days: [
                {
                    year: 2025,
                    amount: 5,
                },
                {
                    year: 2024,
                    amount: 5,
                },
            ],
        },
        {
            id: "2b28e118-ccde-4168-8f2b-35aea1853dd2",
            description: "Μητρότητας/Λοχείας",
            code: "MOT",
            days: [
                {
                    year: 2024,
                    amount: 119,
                },
                {
                    year: 2025,
                    amount: 119,
                },
            ],
        },
        {
            id: "e47eace6-5b08-457f-9987-9408917dac9c",
            description: "Πατρότητας",
            code: "FAT",
            days: [
                {
                    year: 2024,
                    amount: 15,
                },
                {
                    year: 2025,
                    amount: 15,
                },
            ],
            comments: "This type of leave can be applied for up to 4 months without payment",
            minHours: null,
            maxHours: null,
        },
        {
            id: "0dce71c7-e92e-46a7-be02-c88170988af7",
            description: "Γονική",
            code: "PAR",
            days: [
                {
                    year: 2024,
                    amount: 122,
                },
                {
                    year: 2025,
                    amount: 122,
                },
            ],
        },
        {
            id: "7b189429-9a36-4896-9ecf-e5cf6b027ee8",
            description: "Πένθους",
            code: "BER",
            days: [
                {
                    year: 2024,
                    amount: 2,
                },
                {
                    year: 2025,
                    amount: 2,
                },
            ],
            comments: "This leave can be obtained if you lose only 1st degree relatives",
            minHours: null,
            maxHours: null,
        },
        {
            id: "84736215-fb9e-4faf-8904-dd3a3934cbab",
            description: "Εκλογική",
            code: "ELEC",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "76bb6340-2a84-4e1a-aecf-a86e84cdf617",
            description: "Φοιτητική",
            code: "STUD",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 30,
                },
                {
                    year: 2025,
                    amount: 30,
                },
            ],
        },
        {
            id: "74130a8a-b563-4478-8dda-db0b56ed96df",
            description: "Παρακολούθηση Σχολικής Επίδοσης",
            code: "MON",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 4,
                },
                {
                    year: 2025,
                    amount: 4,
                },
            ],
            comments: "",
            minHours: 1,
            allowHalfDays: null,
            maxHours: 8,
        },
        {
            id: "ac693fae-3e0a-4831-b840-5d3dfa1d8cfa",
            description: "Δικαστική",
            code: "JUD",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "3ad608d0-e212-467c-a94e-6b43739a9eb9",
            description: "Άνευ Αποδοχών",
            code: "WP",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "dfadc2ef-b822-45e7-999b-8d2a0d3eda8f",
            description: "Άλλη",
            code: "ANOT",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "a7553eab-2345-4d31-8294-96777c8f062e",
            description: "Μονογονεϊκών Οικογενειών",
            code: "MPAR",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 6,
                },
                {
                    year: 2025,
                    amount: 6,
                },
            ],
        },
        {
            id: "13f805fa-1c3d-4b3e-bb91-0cbd8014a2f6",
            description: "Άδεια Φροντίδας Τέκνου",
            code: "CAR",
            isProjectLevel: false,
            days: [],
            comments: "",
            minHours: 1,
            allowHalfDays: null,
            maxHours: 32,
            timesPerYear: null,
        },
        {
            id: "bbc44ec2-db04-42fb-bb7a-faf204e7cbbb",
            description: "Άδεια Προγεννετικών Εξετάσεων",
            code: "PREN",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "4c3e1941-3ead-4b33-be96-2a8d89cc235c",
            description: "Άδεια λόγω Σοβαρών Νοσημάτων Τέκνων",
            code: "ILCH",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 10,
                },
                {
                    year: 2025,
                    amount: 10,
                },
            ],
        },
        {
            id: "46db2ee2-faed-4f88-b8ff-4ee4232e92f8",
            description: "Άδεια λόγω Ασθένειας Τέκνου",
            code: "ILIN",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "218af171-2681-4147-8dc0-3ac8e1486cda",
            description: "Άδεια Αποκλειστικής Επιμέλειας Παιδιού",
            code: "EXCAR",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 6,
                },
                {
                    year: 2025,
                    amount: 6,
                },
            ],
        },
    ],
    GR_EN: [
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            fixed: true,
            isProjectLevel: true,
            days: [],
            comments: "",
            minHours: null,
            allowHalfDays: null,
            maxHours: null,
        },
        {
            id: "79153038-6017-45e9-b3ef-ff0f09f4c7f8",
            description: "Educational",
            code: "EDU",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: null,
                },
                {
                    year: 2025,
                    amount: null,
                },
            ],
            comments: null,
        },
        {
            id: "89f7f2f2-e5fd-4cec-9093-9403dd67fb1f",
            description: "Sick",
            code: "ILL",
            days: [
                {
                    year: 2024,
                    amount: null,
                },
                {
                    year: 2025,
                    amount: null,
                },
            ],
        },
        {
            id: "3ba01e77-bb89-40cf-bedb-8bd1c4f3bf15",
            description: "Blood Donation",
            code: "BL",
            days: [
                {
                    year: 2024,
                    amount: 4,
                },
                {
                    year: 2025,
                    amount: 4,
                },
            ],
            comments: "",
            minHours: null,
            maxHours: 16,
            timesPerYear: 2,
        },
        {
            id: "2534de14-b524-461f-bc22-ad84245bdb66",
            description: "Marriage",
            code: "MAR",
            days: [
                {
                    year: 2025,
                    amount: 5,
                },
                {
                    year: 2024,
                    amount: 5,
                },
            ],
        },
        {
            id: "2b28e118-ccde-4168-8f2b-35aea1853dd2",
            description: "Maternity",
            code: "MOT",
            days: [
                {
                    year: 2024,
                    amount: 119,
                },
                {
                    year: 2025,
                    amount: 119,
                },
            ],
        },
        {
            id: "e47eace6-5b08-457f-9987-9408917dac9c",
            description: "Paternity",
            code: "FAT",
            days: [
                {
                    year: 2024,
                    amount: 15,
                },
                {
                    year: 2025,
                    amount: 15,
                },
            ],
            comments: "This type of leave can be applied for up to 4 months without payment",
            minHours: null,
            maxHours: null,
        },
        {
            id: "0dce71c7-e92e-46a7-be02-c88170988af7",
            description: "Parental",
            code: "PAR",
            days: [
                {
                    year: 2024,
                    amount: 122,
                },
                {
                    year: 2025,
                    amount: 122,
                },
            ],
        },
        {
            id: "7b189429-9a36-4896-9ecf-e5cf6b027ee8",
            description: "Bereavement",
            code: "BER",
            days: [
                {
                    year: 2024,
                    amount: 2,
                },
                {
                    year: 2025,
                    amount: 2,
                },
            ],
            comments: "This leave can be obtained if you lose only 1st degree relatives",
            minHours: null,
            maxHours: null,
        },
        {
            id: "84736215-fb9e-4faf-8904-dd3a3934cbab",
            description: "Election",
            code: "ELEC",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "76bb6340-2a84-4e1a-aecf-a86e84cdf617",
            description: "Student",
            code: "STUD",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 30,
                },
                {
                    year: 2025,
                    amount: 30,
                },
            ],
        },
        {
            id: "74130a8a-b563-4478-8dda-db0b56ed96df",
            description: "Monitoring School Performance",
            code: "MON",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 4,
                },
                {
                    year: 2025,
                    amount: 4,
                },
            ],
            comments: "",
            minHours: 1,
            allowHalfDays: null,
            maxHours: 8,
        },
        {
            id: "ac693fae-3e0a-4831-b840-5d3dfa1d8cfa",
            description: "Jury Duty",
            code: "JUD",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "3ad608d0-e212-467c-a94e-6b43739a9eb9",
            description: "Unpaid",
            code: "WP",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "dfadc2ef-b822-45e7-999b-8d2a0d3eda8f",
            description: "Other",
            code: "ANOT",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "a7553eab-2345-4d31-8294-96777c8f062e",
            description: "Single Parent Families",
            code: "MPAR",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 6,
                },
                {
                    year: 2025,
                    amount: 6,
                },
            ],
        },
        {
            id: "13f805fa-1c3d-4b3e-bb91-0cbd8014a2f6",
            description: "Childcare Leave",
            code: "CAR",
            isProjectLevel: false,
            days: [],
            comments: "",
            minHours: 1,
            allowHalfDays: null,
            maxHours: 32,
            timesPerYear: null,
        },
        {
            id: "bbc44ec2-db04-42fb-bb7a-faf204e7cbbb",
            description: "Prenatal Examinations Leave",
            code: "PREN",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "4c3e1941-3ead-4b33-be96-2a8d89cc235c",
            description: "Serious Child Illness Leave",
            code: "ILCH",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 10,
                },
                {
                    year: 2025,
                    amount: 10,
                },
            ],
        },
        {
            id: "46db2ee2-faed-4f88-b8ff-4ee4232e92f8",
            description: "Child Illness Leave",
            code: "ILIN",
            isProjectLevel: false,
            days: [],
        },
        {
            id: "218af171-2681-4147-8dc0-3ac8e1486cda",
            description: "Exclusive Child Custody Leave",
            code: "EXCAR",
            isProjectLevel: false,
            days: [
                {
                    year: 2024,
                    amount: 6,
                },
                {
                    year: 2025,
                    amount: 6,
                },
            ],
        },
    ],
    //EU

    AT: [
        // Austria
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 25 },
                { year: 2025, amount: 25 },
            ],
            comments: "Minimum of 5 weeks paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 10 },
                { year: 2025, amount: 10 },
            ],
            comments: "Paid sick leave for 6-12 weeks.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 112 },
                { year: 2025, amount: 112 },
            ],
            comments: "8 weeks before and after birth at full pay.",
        },
    ],
    BE: [
        // Belgium
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 20 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 10 },
                { year: 2025, amount: 10 },
            ],
            comments: "Paid sick leave varies by contract.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 105 },
                { year: 2025, amount: 105 },
            ],
            comments: "15 weeks of paid maternity leave.",
        },
    ],
    BG: [
        // Bulgaria
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 20 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 10 },
                { year: 2025, amount: 10 },
            ],
            comments: "Paid sick leave up to 18 months.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 410 },
                { year: 2025, amount: 410 },
            ],
            comments: "410 days paid at 90% of salary.",
        },
    ],
    HR: [
        // Croatia
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 20 days paid leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 15 },
                { year: 2025, amount: 15 },
            ],
            comments: "Up to 42 days of paid sick leave.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 365 },
                { year: 2025, amount: 365 },
            ],
            comments: "365 days of paid maternity leave at 100% salary.",
        },
    ],
    CY: [
        // Cyprus
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 20 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 9 },
                { year: 2025, amount: 9 },
            ],
            comments: "Paid sick leave varies based on insurance.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 126 },
                { year: 2025, amount: 126 },
            ],
            comments: "18 weeks of paid maternity leave.",
        },
    ],
    CZ: [
        // Czech Republic
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 4 weeks paid leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 14 },
                { year: 2025, amount: 14 },
            ],
            comments: "Paid sick leave covered by insurance.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 196 },
                { year: 2025, amount: 196 },
            ],
            comments: "28 weeks of paid maternity leave.",
        },
    ],
    DK: [
        // Denmark
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 25 },
                { year: 2025, amount: 25 },
            ],
            comments: "5 weeks minimum paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 14 },
                { year: 2025, amount: 14 },
            ],
            comments: "Paid sick leave varies by collective agreement.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 126 },
                { year: 2025, amount: 126 },
            ],
            comments: "18 weeks of paid maternity leave.",
        },
    ],
    EE: [
        // Estonia
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 28 },
                { year: 2025, amount: 28 },
            ],
            comments: "Minimum of 28 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 14 },
                { year: 2025, amount: 14 },
            ],
            comments: "Paid sick leave from the second day onwards.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 140 },
                { year: 2025, amount: 140 },
            ],
            comments: "140 days of paid maternity leave at 100% salary.",
        },
    ],
    FI: [
        // Finland
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 30 },
                { year: 2025, amount: 30 },
            ],
            comments: "30 days of paid annual leave (2.5 days per month).",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 9 },
                { year: 2025, amount: 9 },
            ],
            comments: "Paid sick leave, with compensation by insurance.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 105 },
                { year: 2025, amount: 105 },
            ],
            comments: "105 days of paid maternity leave.",
        },
    ],
    FR: [
        // France
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 25 },
                { year: 2025, amount: 25 },
            ],
            comments: "Minimum of 5 weeks paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 10 },
                { year: 2025, amount: 10 },
            ],
            comments: "Paid sick leave, typically with medical certification.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 112 },
                { year: 2025, amount: 112 },
            ],
            comments: "16 weeks of paid maternity leave, with possible extensions.",
        },
    ],
    DE: [
        // Germany
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 24 },
                { year: 2025, amount: 24 },
            ],
            comments: "24 days minimum paid leave for full-time employees.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 6 },
                { year: 2025, amount: 6 },
            ],
            comments: "Paid sick leave up to 6 weeks, then sickness benefit.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 98 },
                { year: 2025, amount: 98 },
            ],
            comments: "6 weeks before and 8 weeks after birth at full pay.",
        },
    ],
    HU: [
        // Hungary
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "20 days minimum paid leave, increasing with age.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 15 },
                { year: 2025, amount: 15 },
            ],
            comments: "Paid sick leave, partially funded by employer.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 168 },
                { year: 2025, amount: 168 },
            ],
            comments: "24 weeks of paid maternity leave.",
        },
    ],
    IE: [
        // Ireland
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "4 weeks minimum paid leave for full-time employees.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 3 },
                { year: 2025, amount: 3 },
            ],
            comments: "3 paid sick days per year, increasing over time.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 182 },
                { year: 2025, amount: 182 },
            ],
            comments: "26 weeks of paid maternity leave, plus optional 16 weeks unpaid.",
        },
    ],
    IT: [
        // Italy
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum 4 weeks paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 10 },
                { year: 2025, amount: 10 },
            ],
            comments: "Paid sick leave for up to 180 days.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 140 },
                { year: 2025, amount: 140 },
            ],
            comments: "22 weeks of paid maternity leave at 80% salary.",
        },
    ],
    LV: [
        // Latvia
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 4 weeks paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 10 },
                { year: 2025, amount: 10 },
            ],
            comments: "Paid sick leave up to 10 days, with extended unpaid leave available.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 112 },
                { year: 2025, amount: 112 },
            ],
            comments: "16 weeks of paid maternity leave at 80% of salary.",
        },
    ],
    LT: [
        // Lithuania
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 20 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 14 },
                { year: 2025, amount: 14 },
            ],
            comments: "Paid sick leave, funded by employer for the first days and by insurance afterward.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 126 },
                { year: 2025, amount: 126 },
            ],
            comments: "18 weeks of paid maternity leave.",
        },
    ],
    LU: [
        // Luxembourg
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 26 },
                { year: 2025, amount: 26 },
            ],
            comments: "Minimum of 26 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 13 },
                { year: 2025, amount: 13 },
            ],
            comments: "Paid sick leave covered by social security.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 112 },
                { year: 2025, amount: 112 },
            ],
            comments: "16 weeks of paid maternity leave at full salary.",
        },
    ],
    MT: [
        // Malta
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 24 },
                { year: 2025, amount: 24 },
            ],
            comments: "Minimum of 24 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 14 },
                { year: 2025, amount: 14 },
            ],
            comments: "Paid sick leave, varying by collective agreements.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 126 },
                { year: 2025, amount: 126 },
            ],
            comments: "18 weeks of paid maternity leave.",
        },
    ],
    NL: [
        // Netherlands
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 4 weeks paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 104 },
                { year: 2025, amount: 104 },
            ],
            comments: "Paid sick leave up to 2 years at 70% of salary.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 112 },
                { year: 2025, amount: 112 },
            ],
            comments: "16 weeks of paid maternity leave.",
        },
    ],
    PL: [
        // Poland
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "20 days for employees with less than 10 years of service; 26 days otherwise.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 33 },
                { year: 2025, amount: 33 },
            ],
            comments: "Paid sick leave for up to 33 days, then covered by social security.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 140 },
                { year: 2025, amount: 140 },
            ],
            comments: "20 weeks of paid maternity leave at 100% salary.",
        },
    ],
    PT: [
        // Portugal
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 22 },
                { year: 2025, amount: 22 },
            ],
            comments: "Minimum of 22 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 3 },
                { year: 2025, amount: 3 },
            ],
            comments: "Paid sick leave starting after 3 days of illness.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 120 },
                { year: 2025, amount: 120 },
            ],
            comments: "120 days of paid maternity leave.",
        },
    ],
    RO: [
        // Romania
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 20 days paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 5 },
                { year: 2025, amount: 5 },
            ],
            comments: "Paid sick leave with varying duration.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 126 },
                { year: 2025, amount: 126 },
            ],
            comments: "126 days of paid maternity leave.",
        },
    ],
    SK: [
        // Slovakia
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 4 weeks paid leave, increasing with age.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 10 },
                { year: 2025, amount: 10 },
            ],
            comments: "Paid sick leave funded by social insurance.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 182 },
                { year: 2025, amount: 182 },
            ],
            comments: "182 days of paid maternity leave.",
        },
    ],
    SI: [
        // Slovenia
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 20 },
                { year: 2025, amount: 20 },
            ],
            comments: "Minimum of 4 weeks paid leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 30 },
                { year: 2025, amount: 30 },
            ],
            comments: "Paid sick leave up to 30 days, covered by employer.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 105 },
                { year: 2025, amount: 105 },
            ],
            comments: "105 days of paid maternity leave.",
        },
    ],
    ES: [
        // Spain
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 30 },
                { year: 2025, amount: 30 },
            ],
            comments: "30 calendar days of paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 15 },
                { year: 2025, amount: 15 },
            ],
            comments: "Paid sick leave varies, typically covered after the 4th day.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 112 },
                { year: 2025, amount: 112 },
            ],
            comments: "16 weeks of paid maternity leave.",
        },
    ],
    SE: [
        // Sweden
        {
            id: "annual-leave",
            description: "Annual Leave",
            code: "ANLVE",
            days: [
                { year: 2024, amount: 25 },
                { year: 2025, amount: 25 },
            ],
            comments: "Minimum of 5 weeks paid annual leave.",
        },
        {
            id: "sick-leave",
            description: "Sick Leave",
            code: "SICK",
            days: [
                { year: 2024, amount: 14 },
                { year: 2025, amount: 14 },
            ],
            comments: "Paid sick leave; first 14 days covered by employer.",
        },
        {
            id: "maternity-leave",
            description: "Maternity Leave",
            code: "MAT",
            days: [
                { year: 2024, amount: 240 },
                { year: 2025, amount: 240 },
            ],
            comments: "480 days of parental leave shared, paid by social insurance.",
        },
    ],
}

function calculateAnnualLeave({ yearsWorked, has12Years, has25Years, orgPerson, orgData, startDate, year }) {
    const std = toZonedTime(orgPerson.startDate)

    if (isAfter(std, endOfYear(startDate))) {
        return 0
    }

    const isSix = orgData.daysPerWeek >= 6
    let hasEndDate = orgPerson.endDate ? toZonedTime(orgPerson.endDate) : false

    let lastDate, monthsInCalc

    if (hasEndDate && isAfter(hasEndDate, endOfYear(year))) {
        lastDate = endOfYear(year)
    } else if (hasEndDate && isBefore(hasEndDate, startOfYear(year))) {
        return 0
    }
    if (hasEndDate && isBefore(std, hasEndDate)) {
        lastDate = hasEndDate
        monthsInCalc = eachMonthOfInterval({
            start: std,
            end: lastDate,
        }).lwength
    }

    if (yearsWorked === 0) {
        const months = eachMonthOfInterval({
            start: std,
            end: lastDate || endOfYear(startDate),
        }).length

        let multiplier = !orgData.daysPerWeek || orgData.daysPerWeek === 5 ? 20 : 24

        if (has12Years) {
            if (!orgData.daysPerWeek || orgData.daysPerWeek === 5) {
                multiplier = 25
            } else if (orgData.daysPerWeek >= 6) {
                multiplier = 30
            }
        } else if (has25Years) {
            if (!orgData.daysPerWeek || orgData.daysPerWeek === 5) {
                multiplier = 26
            } else if (orgData.daysPerWeek >= 6) {
                multiplier = 31
            }
        }

        return Math.round(months * (multiplier / 12))
    } else if (yearsWorked === 1) {
        if (has12Years) return isSix ? 30 : 25
        if (has25Years) return isSix ? 31 : 26
        //FINALLY
        const x1 = isSix ? 25 : 21

        let f

        if (monthsInCalc) {
            f = Math.round((x1 / 12) * monthsInCalc)
        } else {
            f = x1
        }

        return f
    } else if (yearsWorked >= 2) {
        if (has12Years) return isSix ? 30 : 25
        if (has25Years) return isSix ? 31 : 26

        const x2 = isSix ? 26 : 22

        let f2

        if (monthsInCalc) {
            f2 = Math.round((x2 / 12) * monthsInCalc)
        } else {
            f2 = x2
        }

        //FINALLY
        return f2
    }
    return 0 // Default case, should not occur based on given scenarios
}

function convertPreServiceToYears(preService) {
    const [years, months, days] = preService.split("-").map(Number)
    return years + months / 12 + days / 365
}

export { holidays, formulas, holidayCodes, getAllStatDays, getHolidayCodes }
