import { timestampToUtcDate, getAiEndDate } from "./DateUtils"
import { getTimeUtc } from "../utils/dates"
import startOfDay from "date-fns/startOfDay"
import addDays from "date-fns/addDays"
import setHours from "date-fns/setHours"
import addHours from "date-fns/addHours"
import format from "date-fns/format"
import { isBefore } from "date-fns/isBefore"

import { isWithinInterval } from "date-fns/isWithinInterval"
import { toZonedTime } from "date-fns-tz"

import uniqBy from "lodash/uniqBy"

export function getClEstimatedDuration(c) {
    return c.estimatedDuration || c.duration
}

export function getTsMission(ts, app) {
    const tsMission = ts?.snapshot?.mission || ts.mission

    let m = app?.state.missions.find((mm) => mm._id === getObjectId(tsMission))

    if (!m || Object.keys(m).length === 0) {
        return tsMission
    }

    return m
}
export function getTsOrg(ts) {
    const o = ts?.snapshot?.org || ts.org || ts.mission?.org
    if (Object.keys(o).length === 0) {
        return null
    } else {
        return o
    }
}
export function getTsPlanItem(ts) {
    return ts?.snapshot?.planItem || ts.planItem
}
export function displayRole(role, org) {
    if (!role) {
        return null
    }
    if (!org && role) {
        return typeof role === "object" ? role.role || "Team member" : role
    }

    if (typeof role === "object" || role.role) {
        if (role.role) {
            role = role.role
        } else {
            return "Team member"
        }
    }

    if ((org.roles || []).length === 0) {
        return role || ""
    } else {
        const r = org.roles.find((r) => role === r.id || role === r._id)
        return r ? r.name : role || ""
    }
    return ""
}

export function getMyTree(tree, id) {
    if (tree._id === id) {
        return tree
    } else if (tree.children != null) {
        var i
        var result = null
        for (i = 0; result == null && i < tree.children.length; i++) {
            result = getMyTree(tree.children[i], id)
        }
        return result
    }

    return null
}

export function getKidActionItems(kid, arr, parrent) {
    kid.children.forEach((k, i) => {
        const gottenKidArray = getKidActionItems(k, arr, parrent)

        arr = uniqBy([...arr, ...k.actionItems, ...gottenKidArray], "_id")
    })

    return arr
}

export function sortAlpha(arr, key = "title") {
    if (!key) {
        alert("key is required")
        return arr
    }
    return arr?.sort((a, b) => {
        if (a[key] < b[key]) {
            return -1
        }
        if (a[key] > b[key]) {
            return 1
        }
        return 0
    })
}

export function hoursToMillis(hours) {
    return typeof hours === "number" ? hours * (60 * 60000) : hours
}

export function millisToHours(millis, decimals = 2) {
    return typeof millis === "number" ? parseFloat((millis / (60 * 60000)).toFixed(decimals)) : millis
}

//Returns natural language of actionitem fields

const diffArray = ["Low", "Normal", "Hard"]
const metArray = ["Critical", "Issue", "Bug", "Change request"]

export function getAiKeyValue(key, value) {
    switch (key) {
        case "endDate":
            return value ? format(toZonedTime(value), "EEE MMM dd hh':'mm a") : ""
        case "startDate":
            return value ? format(toZonedTime(value), "EEE MMM dd hh':'mm a") : ""
        case "estimatedDuration":
            return value + " hours"
        case "difficulty":
            return diffArray[value]
        case "metaType":
            return metArray[value]
        default:
            return ""
    }
}
export function getAiKeyText(key) {
    switch (key) {
        case "endDate":
            return "end date"
            break
        case "estimatedDuration":
            return "duration"
            break
        case "checklist":
            return "subtask checklist"
            break
        case "startDate":
            return "start date"
            break
        case "status":
            return "status"
            break
        case "endDate":
            return "end date"
            break
        case "myDependencies":
            return "my dependencies"
            break
        case "dependencyOnMe":
            return "depending on me"
            break
        case "title":
            return "title"
            break
        case "description":
            return "description"
            break
        case "people":
            return "people assigned"
            break
        case "sortIndex":
            return "order"
            break
        case "blockedReason":
            return "blocked reason"
            break
        case "blocked by":
            return "end date"
            break
        case "metaType":
            return "type"
            break
        case "startHour":
            return "start hour"
            break
        case "y":
            return "vertical position"
            break
        case "difficulty":
            return "difficulty level"
            break
        case "cost":
            return "cost"
            break
        case "col":
            return "status"
            break
        case "tags":
            return "tags"
            break
        default:
            return ""
    }
}

export function isActionItemInRange(ai, range1, range2, mission) {
    let ok = false
    const miss = mission
    const theStart = toZonedTime(ai.startDate)
    const theEnd = getAiEndDate(ai, miss)

    if (
        isWithinInterval(theStart, { start: range1, end: range2 }) ||
        isWithinInterval(theEnd, { start: range1, end: range2 }) ||
        (isBefore(theStart, range1) && isBefore(range2, theEnd))
    ) {
        ok = true
    }

    return ok
}
/**DEPRECATED USE /utils/utils.js*/
export function getRefId(obj) {
    if (!obj) return

    if (typeof obj === "string") return obj

    if (!obj.ref && obj._id) return obj._id

    if (obj.ref && typeof obj.ref === "string") return obj.ref

    if (obj.ref && obj.ref._id) return obj.ref._id
}

export function makisGetObjIdSimple(thing) {
    if (!thing) return null

    if (typeof thing === "string") {
        return thing
    } else {
        return thing._id
    }
}

/**DEPRECATED USE /utils/utils.js
 *
 * Gets an object id string
 * Takes under consideration populated references as well.
 * @param id {String|Object} The MongoDB ObjectID
 * @param [handlePopulatedRef] {Boolean} Whether to handle populated references or not
 * @return {*}
 */
export function getObjectId(id, handlePopulatedRef = true) {
    if (!id) {
        return id
    }

    if (typeof id === "string") {
        return id
    }

    if (id._id && handlePopulatedRef === true) {
        // Populated reference. Re-iterate once with child _id
        return getObjectId(id._id, false)
    }

    return id
}

/**
 * Given a start date, estimated duration and start hour of the week, returns the task's end date.
 *
 * @param startDate {Number} The start date as a number of milliseconds since the Unix Epoch
 * @param durationHours {Number} Estimated duration in hours
 * @param [startHourOfWeek] {Number} The start time of the week. E.g. Monday start of day => 0 to Friday end of day => 40
 * @param [options] {Object}
 * @param [options.hoursInDay] {Number} How many hours exist in a working day
 * @param [options.workStartHour] {Number} The hour that work is considered to start. Usually 8 or 9 in the morning.
 * @returns {Number} The end date as a number of milliseconds since the Unix Epoch
 */
export function getTaskEndDate(startDate, durationHours, startHourOfWeek = 0, options = {}) {
    options = {
        hoursInDay: 8,
        workStartHour: 9,
        ...options,
    }

    if (durationHours < 0 || startHourOfWeek < 0 || startHourOfWeek >= 7 * options.hoursInDay) {
        return undefined
    }

    try {
        const startHourOffset = startHourOfWeek % options.hoursInDay
        const totalDuration = startHourOffset + durationHours
        const durationInLastDay = durationHours % options.hoursInDay
        const durationDays = Math.ceil(totalDuration / options.hoursInDay)
        const daysToAdd = durationDays - 1 >= 0 ? durationDays - 1 : 0
        let hoursToAdd = startHourOffset + durationInLastDay

        if (durationHours !== 0 && durationInLastDay === 0 && startHourOffset === 0) {
            hoursToAdd = options.hoursInDay
        }

        let endDateMoment = timestampToUtcDate(startDate)
        endDateMoment = startOfDay(endDateMoment)
        endDateMoment = addDays(endDateMoment, daysToAdd)
        endDateMoment = setHours(endDateMoment, options.workStartHour)
        endDateMoment = addHours(endDateMoment, hoursToAdd)
        return getTimeUtc(endDateMoment)
    } catch (err) {
        return undefined
    }
}

/**
 * Given a start date and start hour of the week, returns the task's start date.
 *
 * @param startDate {Number} The start date as a number of milliseconds since the Unix Epoch
 * @param [startHourOfWeek] {Number} The start time of the week. E.g. Monday start of day => 0 to Friday end of day => 40
 * @param [options] {Object}
 * @param [options.hoursInDay] {Number} How many hours exist in a working day
 * @param [options.workStartHour] {Number} The hour that work is considered to start. Usually 8 or 9 in the morning.
 * @returns {Number} The end date as a number of milliseconds since the Unix Epoch
 */
export function getTaskStartDate(startDate, startHourOfWeek = 0, options = {}) {
    options = {
        hoursInDay: 8,
        workStartHour: 9,
        ...options,
    }

    if (startHourOfWeek < 0 || startHourOfWeek >= 7 * options.hoursInDay) {
        return undefined
    }

    try {
        const startHourOffset = startHourOfWeek % options.hoursInDay
        let startDateMoment = timestampToUtcDate(startDate)
        startDateMoment = startOfDay(startDateMoment)
        startDateMoment = setHours(startDateMoment, options.workStartHour)
        startDateMoment = addHours(startDateMoment, startHourOffset)
        return getTimeUtc(startDateMoment)
    } catch (err) {
        return undefined
    }
}

/**
 * Determines if a link was clicked to be opened in a new tab/window.
 * @param e
 * @return {boolean}
 */
export function isClickBlank(e) {
    return (
        e.ctrlKey ||
        e.shiftKey ||
        e.metaKey || // apple
        (e.button && e.button === 1) // middle click, >IE9 + everyone else
    )
}

export function debouncePromise(promise, wait = 200, options = {}) {
    let timerId = null

    function cancelTimer() {
        clearTimeout(timerId)
    }

    function debounced(...args) {
        cancelTimer()
        return new Promise((resolve) => {
            timerId = setTimeout(() => resolve(promise(...args)), wait)
        })
    }

    debounced.cancel = cancelTimer
    return debounced
}

export function getAverageValue(values) {
    try {
        const count = (values || []).length
        const total = (values || []).reduce((total, value) => total + (value || 0))
        return total / count
    } catch (err) {
        return 0
    }
}

export function capitalizeFirstLetter(str) {
    return typeof str === "string" ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

/**
 * Converts sizes between different units.
 * @param value {Number} The value to convert
 * @param from {String} Convert from unit. e.g. 'KB'
 * @param to {String} Convert to unit. e.g. 'MB'
 * @param [options] {Object}
 * @param [options.binary] {Boolean} If true, will convert to binary. Defaults to false (decimal)
 * @return {number|undefined}
 */
export function convertSize(value, from, to, options = {}) {
    if (typeof value !== "number" || !from || !to) {
        return
    }

    const units = [
        ["byte", "bytes"],
        ["kilobyte", "kilobytes", "kb", "kbs"],
        ["megabyte", "megabytes", "mb", "mbs"],
        ["gigabyte", "gigabytes", "gb", "gbs"],
        ["terabyte", "terabytes", "tb", "tbs"],
        ["petabyte", "petabytes", "pb", "pbs"],
    ]

    const base = options.binary === true ? 1024 : 1000
    const fromUnitIndex = units.findIndex((unitGroup) => unitGroup.includes(from.toLowerCase())) + 1
    const toUnitIndex = units.findIndex((unitGroup) => unitGroup.includes(to.toLowerCase())) + 1
    const exp = Math.abs(toUnitIndex - fromUnitIndex)

    if (fromUnitIndex < 1 || toUnitIndex < 1) {
        return
    }

    if (exp === 0) {
        return value
    }

    return toUnitIndex > fromUnitIndex ? value / Math.pow(base, exp) : value * Math.pow(base, exp)
}

/**
 * Checks if email is valid.
 * @param email {String} An email address
 * @return {boolean}
 */
export function isValidEmail(email) {
    /* eslint no-useless-escape:0 */
    if (typeof email !== "string") {
        return false
    }
    const regexValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/
    return regexValidation.test(email)
}

/**
 * Gets a validated email address, trimmed and lower cased.
 * @param email {String} Email address
 * @return {String|null}
 */
export function getValidEmail(email) {
    const formatted = typeof email === "string" ? email.trim().toLowerCase() : null
    return isValidEmail(formatted) ? formatted : null
}

export function flattenTree(data, level = 0, index = 0) {
    let result = [],
        obj
    data.forEach((item) => {
        result.push((obj = item))

        if (item.children?.length) {
            let children = flattenTree(item.children, level, index + 1)
            if (level > index) {
                obj.children = children
            } else {
                result = result.concat(children)
            }
        }
    })
    return result
}

function getTreeNodeById(tree, id) {
    let result = null
    if (id === tree._id) {
        return tree
    } else {
        if (tree.children) {
            tree.children.some((node) => (result = getTreeNodeById(node, id)))
        }
        return result
    }
}

export function listToTree(list, _id) {
    var map = {},
        node,
        roots = [],
        i

    for (i = 0; i < list.length; i += 1) {
        map[list[i]._id] = i // initialize the map
        list[i].children = [] // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = { ...list[i], key: list[i]._id }

        if (node.parentId) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parentId]]?.children.push(node)
        } else {
            roots.push(node)
        }
    }

    if (_id) {
        let found
        list.forEach((item, i) => {
            if (!found) found = getTreeNodeById(item, _id)
        })

        return found
    }

    return roots
}

export function isFreeEmail(email) {
    const emails = [
        "@gmail.",
        "@yahoo.",
        "@hotmail",
        "@aol.",
        "@msn.",
        "@live.",
        "@outlook.",
        "@wanadoo.",
        "@free.",
        "@gmx.",
        "@web.",
        "@yandex.",
        "@ymail.",
        "@hey.",
        "@mail.com",
        "@sympatico.ca",
        "@rogers.com",
        "@sharklasers",
        "@guerilla",
        "@spam4",
        "@grr.la",
    ]

    return emails.some((em) => email.includes(em))
}

export function getDomain(email) {
    return email?.substr(email?.indexOf("@") + 1, email?.length)
}
