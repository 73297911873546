import { getMissionStatus, getObjectId, isMissionComplete, ensureNumber } from "../utils/utils"
import { isMissionBillable, missionFinancials } from "../utils/financials"
import { getAllActionItems } from "../utils/actionItem"
import { differenceInWeeks, differenceInDays, startOfDay, isAfter } from "date-fns"
import { toZonedTime } from "date-fns-tz"
import { getTimeUtc } from "../utils/dates"

export const percentComplete = (mission) => {
    const allActionItems = getAllActionItems(mission)
    const totalPoints = allActionItems.reduce((cumm, obj) => cumm + ensureNumber(obj.complexityMultiplier || 10), 0)
    const donePoints = allActionItems
        .filter((ai) => ai.status === "done")
        .reduce((cumm, obj) => cumm + ensureNumber(obj.complexityMultiplier || 10), 0)

    return totalPoints > 0 ? Math.round((donePoints / totalPoints) * 100) : 0
}

export const completeMission = (mission, app) => {
    app.missionUpdate(mission._id, {
        projectStatus: "completed",
        missionStop: getTimeUtc(new Date()),
    })
}
export const openMission = (mission, app) => {
    app.missionUpdate(mission._id, {
        projectStatus: "active",
        missionStop: null,
    })
}

export const estimatedCompletionDate = () => {}

export function missionWarnings({ missions, app, orgData }) {
    const data = []

    missions.forEach((mission) => {
        const status = getMissionStatus(mission)
        if (status !== "active" || isMissionComplete(mission)) return

        const isBillable = isMissionBillable(mission)
        const org = orgData || app.state.orgs?.find((o) => getObjectId(o) === getObjectId(mission.org))
        const mf = missionFinancials({ mission, app, orgData: org })

        if (isBillable && mission.budgetAvailable && mission.budgetAvailable !== mf.estRevenue) {
            data.push(
                createWarning("undefined-budget", 2, mission, {
                    budgetAvailable: mission.budgetAvailable,
                    definedBudget: mf.estRevenue,
                })
            )
        }

        mission.planItems.forEach((pi) => {
            if (pi.type === "person" && !pi.person) {
                const startDate = toZonedTime(pi.startDate)
                const today = startOfDay(new Date())
                const diffWeeks = differenceInWeeks(today, startDate)
                const daysTo = differenceInDays(startDate, new Date())

                if (isAfter(startDate, today) && daysTo <= 10) {
                    data.push(createWarning("unfilled-role", daysTo > 5 ? 2 : 3, mission, { pi, startDate }))
                } else if (diffWeeks <= 0) {
                    data.push(createWarning("unfilled-role", 3, mission, { pi, startDate: today }))
                } else if (diffWeeks <= 2) {
                    data.push(createWarning("unfilled-role", 2, mission, { pi, startDate }))
                }
            }
        })
    })

    return data
}

function createWarning(type, severity, mission, additionalFields = {}) {
    return {
        date: startOfDay(new Date()).getTime(),
        type,
        severity,
        mission,
        missionId: mission._id,
        ...additionalFields,
    }
}
