import React from 'react';
import classnames from 'classnames';

import './ViewDocument.scss';
import apiFile from '../../services/file/file.api';
import ViewUnsupported from './ViewUnsupported';
import {getFileUrl} from '../../services/file/file.utils';

export default class ViewDocument extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            url: null,
            isLoaded: false
        };

        this.cancelTokenSource = null;
    }

    componentDidMount() {
        const {file} = this.props;
        this.setState({url: getFileUrl(file)});
        this.setIsLoaded();
    }

    setIsLoaded() {
        const {onLoad} = this.props;

        setTimeout(() => {
            onLoad && onLoad();
            this.setState({
                isLoaded: true
            });
        }, 200);
    }

    componentWillUnmount() {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel();
        }
    }

    onUnsupportedLoad() {
        const {onLoad} = this.props;
        onLoad && onLoad();
    }

    render() {
        const {file} = this.props;
        const {url, isLoaded} = this.state;

        const cx = classnames(
            'dna-drive-view--document',
            {'dna-drive-view--document-is-hidden': !isLoaded}
        );

        return (<>
            {!!url && <div className={cx}>
                <object type={file.mimetype} data={url}>
                    <param name='title' value={file.name}/>
                    <ViewUnsupported onLoad={() => this.onUnsupportedLoad()} file={file}/>
                </object>
            </div>}
        </>);
    }
}