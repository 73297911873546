import get from "lodash/get"
import api from "../api.service"
const queryString = require("query-string")

class TemplateApi {
    /**
     * Create a new template
     *
     * @param data {Object}
     * @param data.title {String} Template title
     * @param data.templateType {'mission'|'planItem'|'actionItem'} Template type
     * @param data.template {Object}
     * @param data.template.data {Object} The template data, depending on the "templateType"
     * @param [data.template.dataPlanItems] {Object[]} A list of template data for mission plan items. ONLY with templateType === "mission"
     * @param [data.template.dataActionItems] {Object[]} A list of template data for mission action items. ONLY with templateType === "mission"
     * @param [data.org] {String} The org ID to be scoped within. Required if "mission" is not provided
     * @param [data.mission] {String} The mission ID to be scoped within. Required if "org" is not provided
     * @param [data.isPrivate] {Boolean} If true, will be private to the user. Defaults to true
     * @param [data.description] {String} Optional description text
     * @return {Promise<*>}
     */
    createTemplate(data) {
        return api
            .post(`/template`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * GET template list
     *
     * @param query {Object}
     * @param [query.isPrivate] {Boolean} If true, will return the user's personal templates only
     * @param [query.org] {String} An org ID.
     * @param [query.mission] {String} A mission ID.
     * @return {Promise<*>}
     */
    getTemplateList(query = {}) {
        const qs = queryString.stringify(query)
        return api
            .get(`/template?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * Update one or more templates.
     *
     * NOTE:
     *
     * You cannot update the org or mission fields of a template. Once those are set, they cannot be changed.
     * To move a template to a different org or mission, create a new duplicate template with those fields defined.
     *
     * @param data {object[]} A single or list of templates to update
     * @param data[]._id {string} The template ID to update
     * @param [data[].isPrivate] {boolean}
     * @param [data[].title] {string}
     * @param [data[].description] {string}
     * @param [data[].template] {object}
     * @param [data[].template.data] {object}
     * @param [data[].template.dataPlanItems] {object}
     * @param [data[].template.dataActionItems] {object}
     *
     * @return {Promise<*>}
     */
    updateTemplateBulk(data) {
        return api
            .put(`/template/bulk`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }

    /**
     * @param templateIds {string[]} One or more template IDs to delete
     * @returns {Promise<* | void>}
     */
    deleteTemplateBulk(templateIds) {
        return api
            .post(`/template/delete/bulk`, templateIds)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err, "response.data") || err
            })
    }
}

export default new TemplateApi()
