import React from "react"
import { enableNoCCTrial } from "../comp/StripeUtils"
import { getObjectId } from "./Utils"
import { addParam, removeParam } from "../comp/History"
import "./LicenseChecker.scss"
import { toast } from "react-toastify"
import Button from "../comp/Button"
import {
    getMissionUsageByType,
    hasMissionUnlimitedPeopleUsage,
    getMissionSubscriptionPlanItemByType,
} from "./StripeUtils"

const LicenseChecker = ({ app, mission }) => {
    const org = app.state.orgs.find((o) => getObjectId(o) === getObjectId(mission.org))

    const usage = getMissionUsageByType("people", mission)

    let howManyAreObservers = 0

    let howManyTeamMembers = mission.people?.filter((p) => {
        return p.permission <= 3
    }).length

    if (org) {
        howManyAreObservers = mission.people?.filter((p) => {
            return p.permission > 3
        }).length
    }

    if (
        !hasMissionUnlimitedPeopleUsage(mission) &&
        !getMissionSubscriptionPlanItemByType("people", mission) &&
        usage.value > 5 &&
        !org?.isLicensedActive
    ) {
        return (
            <div className="x-license-checker">
                <div className="x-inset">
                    <img src="/img/astro-sad.png" className="w-50" />
                    <h2 className="magda">We're so sorry...</h2>
                    <p>You need to upgrade to continue your mission.</p>
                    <p>
                        One day we hope to make missionX completely free but for now we rely on subscriptions to develop
                        and provide the features you need.
                    </p>
                    <label>Why am I seeing this?</label>
                    There {howManyTeamMembers === 1 ? "is" : "are"} {howManyTeamMembers} active project member
                    {howManyTeamMembers === 1 ? "" : "s"}.
                    {howManyAreObservers > 0 ? (
                        <p>
                            And, there {howManyAreObservers === 1 ? "is" : "are"} {howManyAreObservers}{" "}
                            {howManyAreObservers === 1 ? "person" : "people"} from your Organization that{" "}
                            {howManyAreObservers === 1 ? "is" : "are"} using your 5 free seats on this project.🖐️ Org
                            managers count as a seat because although not directly involved they get all the insights
                            and reports and can oversee projects.{" "}
                        </p>
                    ) : (
                        <p className="x-inset">Free projects can only have 5 team members.</p>
                    )}
                    {org && !org?.isLicensedActive && (
                        <div>
                            <Button
                                red
                                onClick={() => {
                                    if (this.aboutMeInOrg.permission === 1 || this.aboutMeInOrg.permission === 2) {
                                        enableNoCCTrial({
                                            org,
                                            app: this.props.app,
                                        })
                                    } else {
                                        toast("Ooops. Please ask an organization manager to activate the license.")
                                    }
                                }}
                            >
                                ✨ Start a 30 day org trial! No cc required
                            </Button>
                            <br />
                            <br />
                        </div>
                    )}
                    <Button
                        onClick={(e) => {
                            addParam("mc", "open")
                        }}
                    >
                        🚀 Upgrade just your project
                    </Button>
                    <br />
                    <br />
                    <Button
                        clear
                        onClick={(e) => {
                            toast("❤️😞 Please contact us if you need help or more time. help@missionx.ai", {
                                autoClose: 6000,
                            })
                            removeParam("mission")
                        }}
                    >
                        Exit the project
                    </Button>
                </div>
            </div>
        )
    }

    return null
}
LicenseChecker.displayName = "LicenseChecker"
export default LicenseChecker
