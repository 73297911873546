import api from "../api.service"
import queryString from "query-string"
import toDate from "date-fns/toDate"
import { getTimeUtc } from "../../utils/dates"

/**
 * Get a list of daily currency exchange rates.
 *
 * @param base {string} The base currency E.g. "EUR"
 * @param params {object}
 * @param [params.date] {Date} Required if startDate is not provided. Returns the rates for the provided date (the response will still be an array with a single document)
 * @param [params.startDate] {Date} Required if date is not provided. Returns the rates since the provided startDate
 * @param [params.endDate] {Date} If startDate is provided, limits the rates to the provided endDate
 * @param [params.symbols] {string|string[]} Filter the rates to the provided currencies only. E.g. "USD" or ["USD", "CAD"]
 * @return {Promise<object[]>}
 */

export function getDailyCurrencyRateList(base, params = {}) {
    let { date, startDate, endDate } = { ...params }

    if (date) {
        date = getTimeUtc(toDate(date))
    }

    if (startDate) {
        startDate = getTimeUtc(toDate(startDate))
    }

    if (endDate) {
        endDate = getTimeUtc(toDate(endDate))
    }

    const qs = queryString.stringify({
        base,
        ...params,
        date,
        startDate,
        endDate,
    })

    return api
        .get(`/currency/exchange-rate/daily/list?${qs}`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

/**
 * @param base {string} The base currency E.g. "EUR"
 * @param [options] {object}
 * @param [options.symbols] {string|string[]} Filter the rates to the provided currencies only. E.g. "USD" or ["USD", "CAD"]
 * @return {Promise<object>}
 */
export function getDailyCurrencyRateLatest(base, options = {}) {
    const qs = queryString.stringify({ base, ...options })
    return api
        .get(`/currency/exchange-rate/daily/latest?${qs}`)
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}
