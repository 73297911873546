import React from "react"
import Avatar from "./Avatar"
import Card from "./Card"
import cx from "classnames"
import IconX from "./icons/IconX"
import Tip from "./Tip"
import { getRefId } from "./Utils"
import { createPortal } from "react-dom"
import Loadable from "react-loadable"
import { PiMapPinDuotone, PiPlusCircleDuotone } from "react-icons/pi"
import { AiOutlineUsergroupAdd } from "react-icons/ai"
import Badge from "@material-ui/core/Badge"
const PersonAdder = Loadable({ loader: () => import("./PersonAdder"), loading: () => <span /> })

class People extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            personAdder: false,
        }
    }

    addPerson(e) {
        if (this.props.addHandler) {
            this.props.addHandler()
        } else {
            this.setState({
                adderLeft: e.pageX,
                adderTop: e.pageY,
                personAdder: true,
            })
        }
        if (e) e.stopPropagation()
    }

    onAdd(refId) {
        this.props.onAdd(refId)
        if (this.props.closeOnSelect) {
            this.setState({
                personAdder: false,
            })
        }
    }

    render() {
        const data = this.props.data || []

        const cutoff = this.props.limit || this.props.maxLength || 20

        let searchList = (this.props.searchList || []).slice()

        const meInDataIndex = this.props.app
            ? searchList.findIndex((p) => {
                  return getRefId(p) === this.props.app.state.person._id
              })
            : -1

        if (this.props.app && meInDataIndex !== -1) {
            const meInIt = searchList[meInDataIndex]

            searchList.splice(meInDataIndex, 1)
            searchList.unshift(meInIt)
        }

        const peeps = data
            .filter((p) => !!p?.ref)
            .map((person, item) => {
                const cl = cx("dna-people-person", person.className)

                let personData = { ...person, ...person.ref }

                if (personData.ref) delete personData.ref

                if (item > cutoff) {
                    return null
                }
                if (item === cutoff) {
                    return (
                        <li
                            key={getRefId(person) || item}
                            className="dna-people-person dna-people-overflow dna-center"
                            onClick={this.props.handleClick ? () => this.props.handleClick(person) : undefined}
                        >
                            <Avatar
                                round={this.props.round}
                                org={this.props.org}
                                size={this.props.small ? "smallest" : "small"}
                                position={
                                    this.props.tipPosition ? this.props.tipPosition : item % 2 === 0 ? "top" : "bottom"
                                }
                                distance={
                                    this.props.tipDistance
                                        ? item % 2 === 0
                                            ? this.props.tipDistance
                                            : -this.props.tipDistance
                                        : item % 2 === 0
                                        ? 10
                                        : -8
                                }
                                noFlip={this.props.noFlip}
                                noTip
                                tipProps={this.props.tipProps}
                            />
                            <Badge
                                badgeContent={`+${this.props.data.length - cutoff}`}
                                color="primary"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                            >
                                <div className="dna-flip-text" onClick={this.props.maxLengthClick}></div>
                            </Badge>
                        </li>
                    )
                }

                return (
                    <li
                        key={personData._id + "p" || item}
                        onClick={this.props.handleClick ? () => this.props.handleClick(person) : undefined}
                        className={cl}
                    >
                        <Avatar
                            messenger={personData.messenger || []}
                            newMessages={personData.newMessages || []}
                            round={this.props.round}
                            org={this.props.org}
                            noTip={this.props.noTip}
                            onRemove={this.props.onRemove}
                            position={
                                this.props.tipPosition ? this.props.tipPosition : item % 2 === 0 ? "top" : "bottom"
                            }
                            distance={this.props.tipDistance ? this.props.tipDistance : item % 2 === 0 ? 10 : -8}
                            size={this.props.small ? "smallest" : "small"}
                            {...personData}
                            avatar={person.avatar || personData.avatar}
                            email={person.email}
                            offset="0,15"
                            tipProps={this.props.tipProps}
                            noFlip={this.props.noFlip}
                        />
                    </li>
                )
            })

        const cn = cx("dna-people", this.props.className, {
            "dna-people-small": this.props.small,
            "x-people-round": this.props.round,
        })

        return (
            <React.Fragment>
                <ul
                    id={this.props._id}
                    className={cn}
                    onMouseDown={(e) => {
                        e.stopPropagation()
                    }}
                    style={this.props.style}
                >
                    {peeps}

                    {this.props.addButton && (
                        <li
                            className="dna-people-person dna-people-add-trigger"
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()

                                const me = this

                                if (this.props.onTrigger) {
                                    me.props.onTrigger(e)
                                } else if (
                                    (searchList || []).length === 1 &&
                                    !this.props.invite &&
                                    !this.props.dontAutoAssign &&
                                    !this.props.data.find((p) => getRefId(p) === getRefId(searchList[0]))
                                ) {
                                    me.onAdd(getRefId(searchList[0]))
                                } else {
                                    me.addPerson(e)
                                    if (this.props.afterAddCardTrigger) {
                                        this.props.afterAddCardTrigger(e)
                                    }
                                }
                            }}
                        >
                            {this.props.addMessage !== false && !this.props.round && (
                                <Tip
                                    position={this.props.addMessagePosition}
                                    title={this.props.addMessage || "Add a person to the team"}
                                    delay={600}
                                    distance={20}
                                >
                                    <Avatar noTip size={this.props.small ? "tiny" : "small"} />
                                </Tip>
                            )}
                            {this.props.addMessage === false && !this.props.round && (
                                <Avatar noTip round={this.props.round} size={this.props.small ? "tiny" : "small"} />
                            )}
                            {!this.props.round && <IconX width={9} />}

                            {this.props.round && (
                                <Tip
                                    title={this.props.addMessage || "Add a person..."}
                                    delay={20}
                                    className="x-p-round-trigger"
                                >
                                    <AiOutlineUsergroupAdd />
                                </Tip>
                            )}
                        </li>
                    )}
                </ul>
                {((searchList && searchList.length > 0) || this.props.invite) && (
                    <Card
                        className="dna-add-people-card"
                        portal
                        style={{
                            ...{
                                transform: this.props.transform || "translate(-40%,-50%)",
                                width: "90vw",
                                maxWidth: 440,
                                zIndex: 9999999,
                                position: "fixed",
                                left: this.state.adderLeft,
                                top: this.state.adderTop,
                            },
                        }}
                        closeButton
                        onClose={() => {
                            this.setState({
                                personAdder: false,
                            })
                            if (this.props.onCardClose) {
                                this.props.onCardClose()
                            }
                        }}
                        open={this.state.personAdder}
                    >
                        <div
                            className="x-dna-person-adder"
                            onMouseEnter={(e) => e.stopPropagation()}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            onMouseOver={(e) => e.stopPropagation()}
                        >
                            <PersonAdder
                                org={this.props.org}
                                mission={this.props.mission}
                                closeOnSelect={this.props.closeOnSelect}
                                people={data}
                                searchList={searchList}
                                onAdd={this.onAdd.bind(this)}
                                app={this.props.app}
                                orgContext={this.props.orgContext}
                                adderMsg={this.props.adderMsg}
                            />

                            {this.props.invite && (
                                <div className="dna-people-invite">
                                    <Avatar size="tiny" noTip avatar="/img/avatar-seedling.jpg" />
                                    <p
                                        className=""
                                        onClick={() => {
                                            this.setState({
                                                personAdder: false,
                                            })
                                            this.props.onInviteClick()
                                        }}
                                    >
                                        {this.props.inviteMsg || "Invite someone new."}
                                    </p>
                                </div>
                            )}
                        </div>
                    </Card>
                )}
            </React.Fragment>
        )
    }
}

const AdderPortal = (props) => {
    return createPortal(props.children, document.getElementById("root"))
}

People.displayName = "People"
export default People
