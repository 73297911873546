import { toZonedTime } from "date-fns-tz"
import { ensureNumber } from "../utils/utils"
import { getExchangeRates } from "../utils/exchange-rates"
import { getGreatestEndDate, getSmallestStartDate } from "../utils/dates"
import groupBy from "lodash/groupBy"
import {isWithinInterval} from "date-fns/isWithinInterval"
import {isSameDay} from "date-fns/isSameDay"
import {isAfter} from "date-fns/isAfter"

export function updateStateInvoices({ invoices = [], how, data }) {
    let newInvoices = invoices.slice()
    switch (how) {
        case "add":
            newInvoices.push(data)
            break
        case "push":
            newInvoices.push(data)
            break
        case "merge":
            newInvoices = [...invoices, ...data]
            break
        case "delete":
            const existinIndex = newInvoices.findIndex((ts) => ts._id === data._id)
            if (existinIndex !== -1) {
                newInvoices.splice(existinIndex, 1)
            }
            break
        case "update":
            const existinIndex2 = newInvoices.findIndex((ts) => ts._id === data._id)

            if (existinIndex2 !== -1) {
                newInvoices[existinIndex2] = { ...newInvoices[existinIndex2], ...data }
            }
            break

        default:
    }

    return newInvoices
}

export function calculateSubTotal(invoice, orgData) {
    let subTotal = 0

    ;(invoice.actionItems || []).forEach((item, i) => {
        subTotal += ensureNumber(item.amount)
    })
    ;(invoice.fixedItems || []).forEach((item, i) => {
        subTotal += ensureNumber(item.amount)
    })
    ;(invoice.expenses || []).forEach((item, i) => {
        if (orgData.expenseInvoiceOptions?.includes("tax")) {
            subTotal += ensureNumber(item.tax1) + ensureNumber(item.tax2)
        }
        if (orgData.expenseInvoiceOptions?.includes("tip")) {
            subTotal += ensureNumber(item.tip)
        }

        subTotal += ensureNumber(item.amount)
    })

    return subTotal
}
export function calculateTotal(invoice, orgData) {
    const { discountPercent, taxAPercent, taxBPercent } = invoice
    const subTotal = calculateSubTotal(invoice, orgData)
    let expTotal = 0

    ;(invoice.expenses || []).forEach((item, i) => {
        expTotal +=
            ensureNumber(item.amount) + ensureNumber(item.tax1) + ensureNumber(item.tax2) + ensureNumber(item.tip)
    })
    const removeAsDiscount = ensureNumber(discountPercent)
        ? (ensureNumber(discountPercent) / 100) * invoice.subTotal
        : 0

    const discountTotal = subTotal - removeAsDiscount - expTotal

    const taxA = discountTotal * (ensureNumber(taxAPercent) / 100)
    const taxB = discountTotal * (ensureNumber(taxBPercent) / 100)

    return discountTotal + taxA + taxB + expTotal
}

export async function getInvoicedAmounts({
    invoices,
    from,
    to,
    includeFinalized,
    statusesToInclude = [],
    baseCurrency,
}) {
    let data = {
        invoiced: 0,
        collected: 0,
        rates: [],
    }

    let dayRates

    if (!invoices?.length) {
        return data
    }

    if ((from && !to) || (to && !from)) {
        throw Error("getInvoicedAmounts from/to dates")
    }

    let rangeStart = getSmallestStartDate(invoices.map((d) => ({ startDate: d.dueDate })))
    let rangeEnd = getGreatestEndDate(invoices.map((d) => ({ endDate: d.dueDate })))
    if (isAfter(rangeEnd, new Date())) {
        rangeEnd = new Date()
    }

    const groupedByCurrency = groupBy(invoices, "currency")

    const currenciesNeeded = Object.keys(groupedByCurrency)

    if (currenciesNeeded?.filter((c) => c !== baseCurrency)?.length && baseCurrency) {
        try {
            const ed = isAfter(rangeEnd, new Date()) ? new Date() : rangeEnd
            if (isAfter(rangeStart, ed)) {
                rangeStart = ed
            }
            dayRates = await getExchangeRates({
                baseCurrency: baseCurrency,
                currencies: currenciesNeeded.filter((c) => c !== baseCurrency),
                startDate: rangeStart,
                endDate: ed,
            })

            data.rates = dayRates
        } catch (e) {
            throw Error("getInvoicedAmounts exchange rates not returned:" + e)
        }
    }

    const getPartials = (invoice, fx) => {
        invoice.payments?.forEach((payment, i) => {
            let isPartialInRange = !from || !to ? true : false

            try {
                isPartialInRange = isWithinInterval(toZonedTime(payment.receivedOn), {
                    start: from,
                    end: to,
                })
            } catch (e) {}

            if (isPartialInRange) {
                data.collected += ensureNumber(payment.amount) * fx
            }
        })
    }

    invoices.forEach((invoice, i) => {
        if (invoice.status === "cancelled") {
            return
        }

        if (statusesToInclude.length === 0 && includeFinalized) {
            if (
                invoice.status !== "paid" &&
                invoice.status !== "partially" &&
                invoice.status !== "sent" &&
                !invoice.pdf
            )
                return
        }
        if (statusesToInclude.length > 0) {
            if (!statusesToInclude.includes(invoice.status)) {
                return
            }
        }

        let isInRange = !from || !to ? true : false

        if (!isInRange) {
            const inDate = toZonedTime(invoice.dueDate)

            try {
                isInRange =
                    isWithinInterval(inDate, {
                        start: from,
                        end: to,
                    }) ||
                    isSameDay(to, inDate) ||
                    isSameDay(from, inDate)
            } catch (e) {
                throw Error("Error getInvoicedAmounts")
            }
        }

        let fxRate = 1

        const invoiceDate = toZonedTime(invoice.dueDate)

        if (invoice.currency !== baseCurrency && dayRates?.length) {
            let dateFx
            if (isAfter(invoiceDate, new Date())) {
                dateFx = dayRates.find((dr) => isSameDay(dr.day, new Date()))
                if (dateFx) {
                    fxRate = 1 - (dateFx.rates[invoice.currency] - 1)
                }
            } else {
                dateFx = dayRates.find((ro) => isSameDay(ro.day, invoiceDate))
                if (dateFx) {
                    fxRate = 1 - (dateFx.rates[invoice.currency] - 1)
                }
            }
        }

        if (isInRange) {
            data.invoiced += ensureNumber(invoice.amount) * fxRate

            if (invoice.status === "paid") {
                data.collected += ensureNumber(invoice.amount) * fxRate
            } else if (invoice.status === "partially") {
                getPartials(invoice, fxRate) //loops and adds to data.collected in function
            }
        } else {
            getPartials(invoice, fxRate) //loops and adds to date.collected in function
        }
    })

    return data
}
