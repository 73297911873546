import md5 from 'md5';
import firebase, {getFirebaseInvisibleRecaptcha} from '../firebase.service'

class AuthService {

    constructor() {
        this.getCurrentUserPromise = null;
        this.userId = null;
        this.userRequestId = null;
        this.userToken = null;

        firebase.auth().onIdTokenChanged(async (user) => {
            if (user) {
                this.userId = user.uid;
                this.userToken = await user.getIdToken();
            } else {
                this.userId = null;
                this.userToken = null;
            }
        });

        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                if (!this.userRequestId) {
                    this._setUserRequestId(user.uid);
                    unsubscribe();
                }
            } else {
                this.userRequestId = null;
            }
        });
    }

    getUserRequestId() {
        return this.userRequestId;
    }

    getCurrentUser() {
        const user = firebase.auth().currentUser;

        if (user) {
            this.userId = user.uid;
            return Promise.resolve(user);
        }

        if (this.getCurrentUserPromise) {
            return this.getCurrentUserPromise;
        }

        this.getCurrentUserPromise = new Promise((resolve, reject) => {
            const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                this.getCurrentUserPromise = null;
                unsubscribe();

                if (user) {
                    this.userId = user.uid;
                    resolve(user);
                } else {
                    reject(new Error('No user is logged in.'))
                }
            });
        });

        return this.getCurrentUserPromise;
    }

    getCurrentUserId() {
        return this.userId;
    }

    /**
     * @param appVerifier {*} The response of firebase's RecaptchaVerifier() method call
     * @param phoneNumber {string} The phone number should be in E.164 format (e.g. +16505550101)
     * @returns {Promise<string>} The verification id
     */
    async mfaVerifyPhoneNumber(appVerifier, phoneNumber) {
        const user = await this.getCurrentUser();
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        const multiFactorSession = await user.multiFactor.getSession();
        const phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: multiFactorSession
        };
        return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier);
    }

    /**
     * @param verificationId {string} The response from mfaEnrollVerifyPhoneNumber()
     * @param verificationCode {string} The phone verification code the user entered
     * @param [mfaDisplayName] {string} Optionally, you can specify a display name for the second factor.
     *                                  This is useful for users with multiple second factors,
     *                                  since the phone number is masked during the authentication flow (for example, +1******1234).
     * @returns {Promise<*>}
     */
    async mfaEnroll(verificationId, verificationCode, mfaDisplayName) {
        const user = await this.getCurrentUser();
        const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
        return await user.multiFactor.enroll(multiFactorAssertion, mfaDisplayName || 'My personal phone number');
    }

    _setUserRequestId(uid) {
        const hashParams = [
            uid,
            Date.now(),
            [...Array(5)].map(() => Math.random().toString(36)[2]).join('')
        ];
        this.userRequestId = md5(hashParams.join(''));
    }

}

const authService = new AuthService();

export default authService;
export const getUserRequestId = () => authService.getUserRequestId();
export const getCurrentUser = authService.getCurrentUser.bind(authService);
export const getCurrentUserId = authService.getCurrentUserId.bind(authService);
export const mfaVerifyPhoneNumber = authService.mfaVerifyPhoneNumber.bind(authService);
export const mfaEnroll = authService.mfaEnroll.bind(authService);
