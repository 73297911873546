import React, {useEffect} from "react"
import {setCsrf, setSessionCookie} from '../services/session/session.api';
import firebase from '../services/firebase.service'
import {getFileUrl} from '../services/file/file.utils';

export default function DriveDRoutingComponent ({fileId, fileName}) {
    useEffect(() => {
        try {
            if (!fileId || !fileName) {
                return window.location.href = "/home";
            }

            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    await setCsrf();
                    await setSessionCookie();
                    window.location.href = getFileUrl({_id: fileId, name: fileName});
                } else {
                    window.location.href = "/login";
                }
            });
        } catch(err) {
            window.location.href = "/home";
        }
    }, []);

    return null;
}
