import api from '../api.service';
import {QUICKBOOKS_PRODUCT} from './quickbook.const';
import {
    authToken as onlineAuthToken,
    getAuthRedirect as getOnlineAuthRedirect,
    makeRequest as makeOnlineRequest
} from './online/quickbookOnline.api';

export const quickbooks = {
    api: (orgId) => ({
        listEntries: () => getEntryList(orgId),
        deleteEntry: (entryId) => deleteEntry(orgId, entryId),
        online: {
            createEntry: (payload) => createEntry(orgId, QUICKBOOKS_PRODUCT.ONLINE, payload),
            updateEntry: (entryId, payload) => updateEntry(orgId, entryId, payload),
            getAuthRedirect: () => getOnlineAuthRedirect(orgId),
            authToken: (payload) => onlineAuthToken(orgId, payload),
            makeRequest: makeOnlineRequest(orgId),
            listCustomers: async (companyId) => {
                const realmId = companyId ?? (await quickbooks.api(orgId).listEntries())?.[0]?.companyId;

                if (!realmId) {
                    throw {
                        message: "Ensure you have completed the authentication process with QuickBooks"
                    };
                }

                const orgQboApi = quickbooks.api(orgId).online;
                const {QueryResponse} = await orgQboApi.makeRequest.get(`company/${realmId}/query?query=SELECT%20*%20FROM%20Customer`);
                return QueryResponse;
            }
        },
        time: {
            createEntry: (payload) => createEntry(orgId, QUICKBOOKS_PRODUCT.TIME, payload),
            updateEntry: (entryId, payload) => updateEntry(orgId, entryId, payload),
        }
    }),
}

/**
 * @param orgId
 * @param product
 * @param payload {object}
 * @param payload.clientId {string}
 * @param payload.clientSecret {string}
 * @param payload.webhookVerifierToken {string}
 * @param [payload.scopes] {string[]} See "QUICKBOOK_SCOPE" in "services/constants.service.js". Defaults to all scope values.
 * @param [payload.environment] {'sandbox'|'production'} The environment of the company. Defaults to "production"
 * @returns {*}
 */
function createEntry(orgId, product, payload) {
    return api
        .post(`/quickbook/org/${orgId}`, {
            ...payload,
            product
        })
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err);
        })
}

/**
 * @param orgId
 * @param entryId
 * @param payload {object}
 * @param payload.clientId {string}
 * @param payload.clientSecret {string}
 * @param payload.webhookVerifierToken {string}
 * @param [payload.scopes] {string[]} See "QUICKBOOK_SCOPE" in "services/constants.service.js". Defaults to all scope values.
 * @param [payload.environment] {'sandbox'|'production'} The environment of the company. Defaults to "production"
 * @returns {*}
 */
function updateEntry(orgId, entryId, payload) {
    return api.put(`/quickbook/org/${orgId}/entry/${entryId}`, payload)
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err);
        })
}

/**
 * @param orgId
 * @returns {*}
 */
function getEntryList(orgId) {
    return api.get(`/quickbook/org/${orgId}`)
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err);
        })
}

/**
 * @param orgId
 * @param entryId
 * @returns {*}
 */
function deleteEntry(orgId, entryId) {
    return api.delete(`/quickbook/org/${orgId}/entry/${entryId}`)
        .then(res => res.data)
        .catch(err => {
            throw api.getResponseError(err);
        })
}
