import React from 'react';
import classNames from 'classnames';
import BlurHandler from './BlurHandler';
import './DotMenu.scss';

export default function DotMenu({links, theme, className, onClose}) {

    const onBlur = () => {
        onClose && onClose();
    };

    return (<BlurHandler onBlur={onBlur}>

        <div className={classNames(
            className,
            'dna-dots-menu',
            {[`dna-dots-menu-theme--${theme}`]: ['light', 'dark'].includes(theme)}
        )}>

            <ul className="dna-dots-menu-list">
                {(links || []).map((item, i) => <li className="dna-dots-menu-list-item" key={i}>{item}</li>)}
            </ul>

        </div>

    </BlurHandler>);
}