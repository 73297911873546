import api from "../api.service"
import authService from "../auth/auth.service"

const queryString = require("query-string")

class OrgApi {
    /**
     * POST a new org
     *
     * @param payload {Object}
     * @param payload.title {String} Org title
     * @param [payload.tagline] {String} Brand tagline
     * @param [payload.about] {String} About the organization
     * @param [payload.logo] {*} A logo. NOT SUPPORTED YET
     * @param [payload.domains] {Array<String>} Array of restricted domains, of the format "domain.com" (INVALID: www.domain.com, http://domain.com)
     * @param [payload.inviteDomains] {Array<String>} Array of restricted email domains that can be invited to this organization
     * @param [payload.whoCanInvite] {Array<Number>} Array of permissions that can invite to this organization
     *
     * @param [payload.people] {Object} Options for the person creating the org
     * @param [payload.people.role] {String} Role of creator
     * @param [payload.people.email] {String} Email of creator
     * @return {Promise<Object>}
     */
    createOrg(payload) {
        return api
            .post("/org", payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    uploadLogo(orgId, file) {
        return api
            .uploadFileSingle(`/org/${orgId}/logo`, file, {
                name: "orgLogo",
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * GET a single org by id.
     * @param orgId {String}
     * @param [options] {object}
     * @param [options.withArchivedPeople] {boolean} Defaults to false
     * @return {Promise<Object>}
     */
    getOrg(orgId, options = {}) {
        const qs = queryString.stringify({
            ...options,
            withArchivedPeople: options.withArchivedPeople === true ? '1' : '0',
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .get(`/org/${orgId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * GET organization list.
     * Includes only organizations that user belongs to.
     * @param [options] {object}
     * @param [options.withArchivedPeople] {boolean} Defaults to false
     * @return {Promise<*>}
     */
    getOrgList(options = {}) {
        const qs = queryString.stringify({
            ...options,
            withArchivedPeople: options.withArchivedPeople === true ? '1' : '0',
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .get(`/org?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * PUT an org
     * @param orgId {String}
     * @param data {Object}
     * @return {Promise<Object>}
     */
    updateOrg(orgId, data) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .put(`/org/${orgId}?${qs}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * DELETE an org
     * @param orgId {String}
     * @param [options] {Object}
     * @param [options.missions] {Boolean} If true deletes all Org missions, otherwise just unsets the org and org observers form the missions. Defaults to true.
     * @return {Promise<void>}
     */
    deleteOrg(orgId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .delete(`/org/${orgId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    updateOrgPeople(orgId, personId, data) {
        return api
            .put(`/org/${orgId}/people/${personId}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    updateOrgPeopleEmail(orgId, personId, newEmail) {
        return api
            .put(`/org/${orgId}/people/${personId}/email`, { email: newEmail })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     *
     * @param orgId
     * @param orgPeopleId
     * @param [options] {Object}
     * @param [options.removeFromMissions] {Boolean} If true, will remove the person from all org missions as well
     * @return {Promise<*>}
     */
    deleteOrgPeople(orgId, orgPeopleId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .delete(`/org/${orgId}/people/${orgPeopleId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    addGoal(orgId, data) {
        return api
            .post(`/org/${orgId}/goal`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    updateGoal(orgId, goalId, data) {
        return api
            .put(`/org/${orgId}/goal/${goalId}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    deleteGoal(orgId, goalId) {
        return api
            .delete(`/org/${orgId}/goal/${goalId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId
     * @param inviteeEmail
     * @param data Invitation data
     */
    createInvite(orgId, inviteeEmail, data) {
        return api
            .post(`/org/${orgId}/invite`, Object.assign({}, data, { inviteeEmail }))
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    async handleInvite(token) {
        const user = await authService.getCurrentUser()

        return (
            api
                .post(`/org-invite/handle/${token}`)
                // Refresh token in case email was verified during invite handle
                .then(() => user.getIdToken(true))
                .catch((err) => {
                    throw api.getResponseError(err)
                })
        )
    }

    getInviteByToken(token) {
        return api
            .get(`/org-invite/token/${token}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    acceptInvite(inviteId) {
        return api
            .put(`/org-invite/${inviteId}/accept`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    rejectInvite(inviteId) {
        return api
            .put(`/org-invite/${inviteId}/reject`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    leaveRequestNotify(orgId) {
        return api
            .post(`/org/${orgId}/leave/notify`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param [params] {object}
     * @param [params.personId] {string} The person to filter the roles by (defaults to current user ID)
     * @param [params.startDate] {number} A start date to filter by (role start date <= startDate)
     * @param [params.endDate] {number} An end date to filter by (role end date >= endDate)
     * @returns {Promise<* | void>}
     */
    getOrgRoleList(orgId, params = {}) {
        return api
            .post(`/org/${orgId}/role/list`, params)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param missionIds {string[]}
     * @returns {Promise<* | void>}
     */
    getOrgRoleConflicts(orgId, missionIds = []) {
        return api
            .post(`/org/${orgId}/role/conflicts`, {
                missionIds,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Client
     * ============================================================
     */

    /**
     * POST a new org client
     *
     * @param orgId {string} Org _id
     * @param payload {Object}
     * @param payload.title {String} Client title
     * @param [payload.about] {String} About the client (description)
     * @param [payload.domains] {Array<String>} Array of client domains, of the format "domain.com" (INVALID: www.domain.com, http://domain.com)
     * @return {Promise<*>}
     */
    createOrgClient(orgId, payload) {
        return api
            .post(`/org/${orgId}/client`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * PUT an org client
     *
     * @param orgId {string} Org _id
     * @param clientId {string} Client _id
     * @param payload {Object}
     * @param payload.title {String} Client title
     * @param [payload.about] {String} About the client (description)
     * @param [payload.domains] {Array<String>} Array of client domains, of the format "domain.com" (INVALID: www.domain.com, http://domain.com)
     * @return {Promise<Object>}
     */
    updateOrgClient(orgId, clientId, payload) {
        return api
            .put(`/org/${orgId}/client/${clientId}`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * DELETE an org client
     *
     * @param orgId {string} Org _id
     * @param clientId {string} Client _id
     * @return {Promise<Object>}
     */
    deleteOrgClient(orgId, clientId) {
        return api
            .delete(`/org/${orgId}/client/${clientId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    uploadClientLogo(orgId, clientId, file) {
        return api
            .uploadFileSingle(`/org/${orgId}/client/${clientId}/logo`, file, {
                name: "orgClientLogo",
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Client Purchase Orders
     * ============================================================
     */

    /**
     * POST a new org client purchase order
     *
     * @param orgId {string} Org _id
     * @param clientId {string} Org client _id
     * @param data {Object}
     * @return {Promise<*>}
     */
    createOrgClientOrder(orgId, clientId, data) {
        return api
            .post(`/org/${orgId}/client/${clientId}/order`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * PUT an org client purchase order
     *
     * @param orgId {string} Org _id
     * @param orderId {string} Org client order _id
     * @param data {Object}
     * @return {Promise<*>}
     */
    updateOrgClientOrder(orgId, orderId, data) {
        return api
            .put(`/org/${orgId}/client/order/${orderId}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * DELETE an org client purchase order
     *
     * @param orgId {string} Org _id
     * @param orderId {string} Org client order _id
     * @return {Promise<*>}
     */
    deleteOrgClientOrder(orgId, orderId) {
        return api
            .delete(`/org/${orgId}/client/order/${orderId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Client Invoice
     * ============================================================
     */

    /**
     * POST a new org client invoice
     *
     * @param orgId {string} Org _id
     * @param clientId {string} Org client _id
     *
     * @param payload {Object} Invoice data
     * @param payload.number {number}
     * @param payload.amount {number}
     * @param [payload.status] {string} Defaults to "draft"
     * @param [payload.taxAPercent] {number}
     * @param [payload.taxBPercent] {number}
     * @param [payload.items] {object[]}
     * @param [payload.discountPercent] {number}
     * @param [options] {object}
     * @param [options.qbo] {'on'|'off'} If set to "on", it will sync to quickbooks. Defaults to "off"
     * @param [qbo] {object}
     * @param [qbo.CurrencyRef] {object} Required if multi-currency is enabled for the company.
     * @param qbo.CurrencyRef.value {string}
     * @param [qbo.CurrencyRef.name] {string}
     * @param [qbo.CustomerRef] {object}
     * @param qbo.CustomerRef.value {string}
     * @param [qbo.CustomerRef.name] {string}
     * @param [qbo.TaxCodeRef] {object}
     * @param qbo.TaxCodeRef.value {string}
     * @param [qbo.TaxCodeRef.name] {string}
     * @param [qbo.Description] {string}
     *
     * @return {Promise<*>}
     */
    createOrgClientInvoice(orgId, clientId, payload, options = {}, qbo = {}) {
        const qs = queryString.stringify(options || {})

        return api
            .post(`/clientInvoice/org/${orgId}/client/${clientId}?${qs}`, {
                payload,
                qbo,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * GET an org client invoice list
     *
     * @param orgId {string} Org _id
     * @param [query] {object} Optional query options
     * @param [query.clientId] {string}
     * @param [query.status] {string}
     * @return {Promise<Object[]>}
     */
    getOrgClientInvoiceList(orgId, query = {}) {
        const qs = queryString.stringify(query)
        return api
            .get(`/clientInvoice/org/${orgId}/list?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * PUT a client invoice
     *
     * @param clientInvoiceId {string} Client _id
     *
     * @param payload {Object} Invoice data
     * @param payload.number {number}
     * @param payload.amount {number}
     * @param [payload.status] {string} Defaults to "draft"
     * @param [payload.taxAPercent] {number}
     * @param [payload.taxBPercent] {number}
     * @param [payload.items] {object[]}
     * @param [payload.discountPercent] {number}
     * @param [options] {object}
     * @param [options.qbo] {'on'|'off'} If set to "on", it will sync to quickbooks. Defaults to "off"
     *
     * @return {Promise<Object>}
     */
    updateOrgClientInvoice(clientInvoiceId, payload, options = {}) {
        const qs = queryString.stringify(options || {})
        return api
            .put(`/clientInvoice/${clientInvoiceId}?${qs}`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * DELETE a client invoice
     *
     * @param clientInvoiceId {string} Client _id
     * @param [options] {object}
     * @param [options.qbo] {'on'|'off'} If set to "on", it will sync to quickbooks. Defaults to "off"
     * @return {Promise<Object>}
     */
    deleteOrgClientInvoice(clientInvoiceId, options = {}) {
        const qs = queryString.stringify(options || {})
        return api
            .delete(`/clientInvoice/${clientInvoiceId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Multer upload
     * @param clientInvoiceId
     * @param pdfFile {File} https://developer.mozilla.org/en-US/docs/Web/API/File
     * @returns {Promise<* | void>}
     */
    uploadOrgClientInvoicePdf(clientInvoiceId, pdfFile, options = {}) {
        return api
            .uploadFileSingle(`/clientInvoice/${clientInvoiceId}/pdf`, pdfFile, {
                ...options,
                name: "pdf",
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param clientInvoiceId {string}
     * @param [options] {object}
     * @param [options.paymentLink] {'stripe'|'paypal'}
     */
    sendOrgClientInvoiceEmail(clientInvoiceId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .post(`/clientInvoice/${clientInvoiceId}/send-email?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * https://stripe.com/docs/api/payment_links/payment_links/create
     *
     * @param clientInvoiceId {string}
     * @param [data] {object}
     *
     * @param [data.lineItems] {object[]}
     * @param [data.lineItems[].productName] {string} The product’s name, meant to be displayable to the customer.
     * @param [data.lineItems[].unitAmount] {number} A positive integer in cents (or 0 for a free price) representing how much to charge.
     * @param [data.lineItems[].quantity] {number} The quantity of the line item being purchased.
     * @param [data.lineItems[].unitLabel] {string} A label that represents units of this product in Stripe and on customers’ receipts and invoices.
     * @param [data.lineItems[].productStatementDescriptor] {string} An arbitrary string to be displayed on your customer’s credit card or bank statement. For validation: https://stripe.com/docs/api/prices/create#create_price-product_data-statement_descriptor
     * Tax needs to be enabled by the connected account and Stripe collects a minimum of 0.5% fee.
     * If not, no taxes are collected.
     * https://stripe.com/docs/tax/set-up
     * @param [data.lineItems[].taxCode] {string} A Stripe tax code to collect taxes: https://stripe.com/docs/tax/tax-categories
     * @param [data.lineItems[].taxBehavior] {'inclusive'|'exclusive'|'unspecified'} Specifies whether the price is considered inclusive of taxes or exclusive of taxes.
     * @param [data.taxCode] {string} A Stripe tax code to collect taxes: https://stripe.com/docs/tax/tax-categories
     * @param [data.taxBehavior] {'inclusive'|'exclusive'|'unspecified'} Specifies whether the price is considered inclusive of taxes or exclusive of taxes.
     * @param [data.automaticTaxEnabled] {boolean} f true, tax will be calculated automatically using the customer’s location.
     * //
     * @param [data.currency] {string} Three-letter ISO currency code. If not provided, will try to use the invoice's currency or the Stripe's account default currency.
     * @param [data.customSubmitText] {string} Custom text that should be displayed alongside the payment confirmation button. Text may be up to 500 characters in length.
     *
     * @param [options] {object}
     * @param [options.integration] {'stripe'} The integration to use to generate the payment link. Defaults to "stripe"
     * @returns {Promise<AxiosResponse<any>>}
     */
    createInvoicePaymentLink(clientInvoiceId, data, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .post(`/clientInvoice/${clientInvoiceId}/payment-link?${qs}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Mission
     * ============================================================
     */

    /**
     * Returns historical of org mission, plus all items with "missionId" set to org mission id.
     * ONLY org Admins can view this information.
     *
     * @param orgId
     * @param [options] {Object}
     * @param [options.limit] {String} The limit of results. Defaults to 100.
     * @param [options.page] {String} Paginate results to specified page, starting from page 1 as default.
     * @param [options.sort] {('asc'|'desc')} Sort either ascending or descending (by date). Defaults to desc.
     * @param [options.createdAtFrom] {Date|Number} Date range history was recorded
     * @param [options.createdAtTo] {Date|Number} Date range history was recorded
     * @returns {Promise<any>}
     */
    getMissionAuditTree(orgId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/org/${orgId}/audit/tree?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId
     * @param params {Object}
     * @param params.personId {string|string[]} One or more people IDs assigned to the plan items
     * @param [params.startDate] {Date|Number} Optional start date range
     * @param [params.endDate] {Date|Number} Optional end date range
     * @returns {Promise<any>}
     */
    getPeopleScheduleList(orgId, params = {}) {
        return api
            .post(`/org/${orgId}/schedule/people`, params)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     *
     * Returns org's mission tree with limited fields for performance
     *
     * @param orgId
     * @return {Promise<any>}
     */
    getOrgMissionTreeLight(orgId) {
        return api
            .get(`/org/${orgId}/mission/overview`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Departments
     * ============================================================
     */

    /**
     * @param orgId {string}
     * @param payload {object|object[]}
     * @param payload.title {string}
     * @param [payload.description] {string}
     * @return {Promise<any>}
     */
    addOrgDepartment(orgId, payload) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .post(`/org/${orgId}/department?${qs}`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param departmentId {string}
     * @param payload {object}
     * @param payload.title {string}
     * @param [payload.description] {string}
     * @return {Promise<any>}
     */
    updateOrgDepartment(orgId, departmentId, payload) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .put(`/org/${orgId}/department/${departmentId}?${qs}`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param departmentId {string}
     * @return {Promise<any>}
     */
    removeOrgDepartment(orgId, departmentId) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .delete(`/org/${orgId}/department/${departmentId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Offices
     * ============================================================
     */

    /**
     * @param orgId {string}
     * @param payload {object|object[]}
     * @param payload.title {string}
     * @param [payload.description] {string}
     * @param [payload.color] {string}
     * @param [payload.address] {string}
     * @param [payload.country] {string}
     * @return {Promise<any>}
     */
    addOrgOffice(orgId, payload) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .post(`/org/${orgId}/office?${qs}`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param officeId {string}
     * @param payload {object|object[]}
     * @param payload.title {string}
     * @param [payload.description] {string}
     * @param [payload.color] {string}
     * @param [payload.address] {string}
     * @param [payload.country] {string}
     * @return {Promise<any>}
     */
    updateOrgOffice(orgId, officeId, payload) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .put(`/org/${orgId}/office/${officeId}?${qs}`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param officeId {string}
     * @return {Promise<any>}
     */
    removeOrgOffice(orgId, officeId) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .delete(`/org/${orgId}/office/${officeId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Programs
     * ============================================================
     */

    /**
     * @param orgId {string}
     * @param payload {object|object[]}
     * @param payload.title {string}
     * @param [payload.owner] {string}
     * @param [payload.clients] {string[]}
     * @return {Promise<any>}
     */
    addOrgProgram(orgId, payload) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .post(`/org/${orgId}/program?${qs}`, { program: payload })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param programId {string}
     * @param payload {object}
     * @param [payload.owner] {string}
     * @param [payload.clients] {string[]}
     * @return {Promise<any>}
     */
    updateOrgProgram(orgId, programId, payload) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .put(`/org/${orgId}/program/${programId}?${qs}`, { payload })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param programId {string}
     * @return {Promise<any>}
     */
    removeOrgProgram(orgId, programId) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .delete(`/org/${orgId}/program/${programId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Roles
     * ============================================================
     */

    /**
     * @param orgId {string}
     * @param payload {object|object[]}
     * @return {Promise<any>}
     */
    addOrgRole(orgId, payload) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .post(`/org/${orgId}/role?${qs}`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param roleId {string}
     * @param payload {object}
     * @return {Promise<any>}
     */
    updateOrgRole(orgId, roleId, payload) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .put(`/org/${orgId}/role/${roleId}?${qs}`, payload)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param roleId {string}
     * @return {Promise<any>}
     */
    removeOrgRole(orgId, roleId) {
        const qs = queryString.stringify({
            withArchivedDepartments: '1',
            withArchivedOffices: '1',
            withArchivedPrograms: '1',
            withArchivedRoles: '1',
        })
        return api
            .delete(`/org/${orgId}/role/${roleId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org Role Plan Items
     * ============================================================
     */

    /**
     * @param orgId {string}
     * @param [options] {object}
     * @param [options.programId] {string|string[]}
     * @param [options.personId] {string|string[]}
     * @param [options.startDate] {number|date} Required if "endDate" is not provided
     * @param [options.endDate] {number|date} Required if "startDate" is not provided
     * @return {Promise<any>}
     */
    gtGetRolePlanItems(orgId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/org-role-plan-item/org/${orgId}?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param rolePlanItemIds {string|string[]}
     * @return {Promise<any>}
     */
    gtGetRolePlanItemById(orgId, rolePlanItemIds) {
        return api
            .post(`/org-role-plan-item/org/${orgId}/role-plan-item/list`, {
                rolePlanItemId: rolePlanItemIds
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @return {Promise<any>}
     */
    gtGetMyRolePlanItems(orgId) {
        return api
            .get(`/org-role-plan-item/org/${orgId}/my`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param payload {object|object[]}
     * @return {Promise<any>}
     */
    gtCreateRolePlanItem(orgId, payload) {
        return api
            .post(`/org-role-plan-item/org/${orgId}`, { payload })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param payload {object[]} Array of one or more items to update
     * @param payload[]._id {string} Each object with changes need to also include the _id of the item to be updated
     * @return {Promise<any>}
     */
    gtUpdateRolePlanItem(orgId, payload) {
        return api
            .put(`/org-role-plan-item/org/${orgId}/role-plan-item/bulk`, { payload })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param rolePlanItemId {string}
     * @return {Promise<any>}
     */
    gtDeleteRolePlanItem(orgId, rolePlanItemId) {
        return api
            .delete(`/org-role-plan-item/org/${orgId}/role-plan-item/${rolePlanItemId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param orgId {string}
     * @param payload {object[]}
     * @param payload[]._id {string} Each object with changes need to also include the _id of the item to be updated
     * @param payload[].status {'draft'|'pending'|'approved'|'rejected'}
     * @param [payload[].context] {string}
     * @param [payload[].comment] {string}
     * @return {Promise<any>}
     */
    changeRolePlanItemStatus(orgId, payload) {
        return api
            .put(`/org-role-plan-item/org/${orgId}/role-plan-item/status`, { payload })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * ============================================================
     * Org App Sumo
     * ============================================================
     */

    /**
     * @param oobCode {string}
     */
    getAppSumoByOobCode(oobCode) {
        return api
            .post(`/org/appsumo/oob-code/doc`, { oobCode })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Generates a new set of clientId and clientSecret for our OpenAPI.
     * NOTE: This will automatically revoke the previous set of keys, thus access to the OpenAPI. Use with caution.
     * NOTE: The "clientSecret" in the response, is the only time this key gets revealed.
     *
     * @param orgId {string}
     * @return {Promise<any>} E.g. {clientId: 'sdjkcbj...', clientSecret: 'hjbdfhbfd...'}
     */
    createOpenApiKeys(orgId) {
        return api
            .post(`/org/${orgId}/open-api/keys`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Revokes the keys and access token for OpenAPI.
     * NOTE: This will revoke all access to the OpenAPI. Use with caution.
     *
     * @param orgId {string}
     * @return {Promise<any>} E.g. {clientId: 'sdjkcbj...', clientSecret: 'hjbdfhbfd...'}
     */
    revokeOpenApiKeys(orgId) {
        return api
            .delete(`/org/${orgId}/open-api/keys`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     *
     * @param orgId {string}
     * @param [options] {object}
     * @param [options.startDate] {date|number|string}
     * @param [options.endDate] {date|number|string}
     * @param [options.missionIds] {string|string[]}
     * @param [options.peopleIds] {string|string[]}
     * @return {Promise<AxiosResponse<any>>}
     */
    getMissionTimesheetMetrics(orgId, options = {}) {
        return api
            .post(`/orgPersonMetrics/missionTimesheetDailyMetrics/list/org/${orgId}`, options)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * EXAMPLE:
     * apiOrg.onChanges('update', data => console.log(data))
     * @param operation {string} - update, create or delete
     * @param [query] {Object}
     * @param [cb] {Function}
     */
    onChanges(operation, query, cb) {
        if (typeof query === "function") {
            cb = query
            query = {}
        }

        ;(async () => {
            const socket = await api.socket.connect(`changes/orgs/${operation}`, { query })
            socket.on("changes", cb)
        })()
    }

    onPeopleChanges(operation, query, cb) {
        if (typeof query === "function") {
            cb = query
            query = {}
        }

        ;(async () => {
            const socket = await api.socket.connect(`changes/org.people/${operation}`, { query })
            socket.on("changes", cb)
        })()
    }

    /**
     * EXAMPLE:
     * const socket = await apiOrg.onGtRoleChanges('update', data => console.log(data))
     * socket.disconnect()
     * @param operation {string} - update, create or delete
     * @param [query] {Object}
     * @param [cb] {Function}
     */
    onGtRoleChanges(operation, query, cb) {
        if (typeof query === "function") {
            cb = query
            query = {}
        }

        let socket

        ;(async () => {
            socket = await api.socket.connect(`changes/org.rolePlanItems/${operation}`, { query })
            socket.on("changes", cb)
        })()

        return {
            disconnect: () => {
                try {
                    socket?.disconnect?.()
                } catch(err) {
                    // Nothing...
                }
            }
        };
    }
}

export default new OrgApi()
