import React from "react"
import cx from "classnames"
const getDefaultColor = () => {
    if (document && document.body.className.indexOf("dna-dark") !== -1) {
        return "#ffffff"
    } else {
        return "#161616"
    }
}

const Sonar = ({ children, color, width = 30, className, style, waveStyle }) => {
    return (
        <div className={cx("dna-sonar", className)} style={style}>
            <div
                className="dna-sonar-wave"
                style={{ height: width, width: width, backgroundColor: color || getDefaultColor(), ...waveStyle }}
            ></div>
            {children}
        </div>
    )
}

export default Sonar
