import React from 'react';
import apiFile from '../../services/file/file.api';
import './ViewUnsupported.scss';

export default class ViewUnsupported extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: null
        };
    }

    componentDidMount() {
        const {onLoad} = this.props;
        onLoad();
    }

    downloadFile(e) {
        e.preventDefault();
        e.stopPropagation();
        const {file} = this.props;

        apiFile
            .streamFileDownload(file)
            .catch(() => {})
    }

    onViewClick(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        const {file} = this.props;

        return (<>
            {!!file && <div className='dna-drive-view--unsupported'>
                <p className='dna-drive-view--unsupported-text' onClick={(e) => this.onViewClick(e)}>We're working on this file type!</p>
                <div className='dna-drive-view--unsupported-btn dna-pill-box blue-pill click-able' onClick={(e) => this.downloadFile(e)}>Download File</div>
            </div>}
        </>);
    }
}