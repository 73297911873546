import get from "lodash/get"
import api from "../api.service"

const queryString = require("query-string")

class ActionItemApi {
    /**
     * @param [options] {object}
     * @param [options.licensedOnly] {boolean} Defaults to true
     * @param [options.missionId] {string}
     * @param [options.orgId] {string}
     * @param [options.startDate] {number}
     * @param [options.endDate] {number}
     * @returns {Promise<string>}
     */
    getMyActionItems(options = {}) {
        options.licensedOnly = options.licensedOnly ?? true
        const qs = queryString.stringify(options)
        return api
            .get(`/action-item/my/items?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param actionItemId
     * @param [options] {Object}
     * @param [options.limit] {String} The limit of results. Defaults to 100.
     * @param [options.page] {String} Paginate results to specified page, starting from page 1 as default.
     * @param [options.sort] {('asc'|'desc')} Sort either ascending or descending (by date). Defaults to desc.
     * @param [options.createdAtFrom] {Date|Number} Date range history was recorded
     * @param [options.createdAtTo] {Date|Number} Date range history was recorded
     * @returns {Promise<any>}
     */
    getHistory(actionItemId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/action-item/${actionItemId}/history?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param missionId
     * @param [options] {Object}
     * @param [options.limit] {String} The limit of results. Defaults to unlimited.
     * @param [options.page] {String} Paginate results to specified page, starting from page 1 as default.
     * @param [options.sort] {('asc'|'desc')} Sort either ascending or descending (by date). Defaults to desc.
     * @param [options.createdAtFrom] {Date|Number} Date range history was recorded
     * @param [options.createdAtTo] {Date|Number} Date range history was recorded
     * @returns {Promise<any>}
     */
    getHistoryByMission(missionId, options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/action-item/mission/${missionId}/history?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    createActionItem(actionId, actionItemData) {
        return api
            .post(`/action-item/action/${actionId}`, actionItemData)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    updateActionItem(actionItemId, actionItemData) {
        return api
            .put(`/action-item/${actionItemId}`, actionItemData)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    updateActionItemBulk(missionId, actionItems) {
        return api
            .put(`/action-item/${missionId}/bulk`, actionItems)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param missionId {string}
     * @param actionItems {object[]} List of action items to update
     * @param actionItems[]._id {string} Required.
     * @param actionItems[].actionId {string} Required. New action ID to move to
     * @returns {Promise<* | void>}
     */
    moveActionItemBulk(missionId, actionItems) {
        return api
            .put(`/action-item/${missionId}/move`, actionItems)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    deleteActionItem(actionItemId) {
        return api
            .delete(`/action-item/${actionItemId}`)
            .then(() => {
                return {}
            })
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    addActionItemPeople(actionItemId, peopleId, peopleData) {
        return api
            .post(`/action-item/${actionItemId}/people/${peopleId}`, peopleData || {})
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param actionItemId
     * @param peopleIds {Array<String>|String} A single person's id or an array of multiple ids.
     * @returns {Promise<any>}
     */
    deleteActionItemPeople(actionItemId, peopleIds) {
        return api
            .post(`/action-item/${actionItemId}/people/remove`, { peopleIds })
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    deleteActionItemPeopleAll(actionItemId) {
        return api
            .post(`/action-item/${actionItemId}/people/remove`, { peopleIds: "ALL" })
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    getCommentList(actionItemId) {
        return api
            .get(`/action-item/${actionItemId}/comment`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    addComment(actionItemId, commentData) {
        return api
            .post(`/action-item/${actionItemId}/comment`, commentData)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    editComment(commentId, commentData) {
        return api
            .put(`/comment/${commentId}`, commentData)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    deleteComment(commentId) {
        return api
            .delete(`/comment/${commentId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    /**
     * @callback onUploadProgress
     * @param {Number} loaded Bytes that have been loaded
     * @param {Number} total The total upload bytes
     */
    /**
     * @param actionItemId {String}
     * @param files {File|Array<File>}
     * @param [options] {Object}
     * @param [options.onUploadProgress] {onUploadProgress} The progress of the upload
     * @return {Promise<*>}
     */
    uploadFiles(actionItemId, files, options = {}) {
        return api
            .uploadFileMulti(`/action-item/${actionItemId}/drive`, files, options)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    copyFiles(actionItemId, toActionItemId) {
        return api
            .post(`/action-item/${actionItemId}/drive/copy`, {copyToActionItemId: toActionItemId})
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    getFileList(actionItemId) {
        return api
            .get(`/action-item/${actionItemId}/drive`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    /**
     * @param actionItemId {String}
     * @param fileId {String}
     * @param data {Object}
     * @param [data.title] {String} File title
     * @param [data.description] {String} File description
     * @param [data.name] {String} Filename
     * @param [data.acl] {Object} Needs owner permissions
     * @return {Promise<any>}
     */
    updateFile(actionItemId, fileId, data) {
        return api
            .put(`/action-item/${actionItemId}/drive/${fileId}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    deleteFile(actionItemId, fileId) {
        return api
            .delete(`/action-item/${actionItemId}/drive/${fileId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    /**
     * @param actionItemId {String}
     * @param data {Object}
     * @param data.title {String}
     * @param data.done {Boolean}
     * @param data.completedBy {String}
     * @return {Promise<any>}
     */
    addChecklistItem(actionItemId, data) {
        return api
            .post(`/action-item/${actionItemId}/checklist/add`, data || {})
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param actionItemId {String}
     * @param checklistItemId {String} The _id property of the checklist item
     * @param moveIndex {Number} The array index of the position to move the item to
     * @return {Promise<any>}
     */
    moveChecklistItem(actionItemId, checklistItemId, moveIndex) {
        return api
            .put(`/action-item/${actionItemId}/checklist/move/${checklistItemId}/index/${moveIndex}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param actionItemId {String}
     * @param checklistItemId {String} The _id property of the checklist item
     * @param data {Object}
     * @param data.title {String}
     * @param data.done {Boolean}
     * @param data.completedBy {String}
     * @return {Promise<any>}
     */
    updateChecklistItem(actionItemId, checklistItemId, data) {
        return api
            .put(`/action-item/${actionItemId}/checklist/update/${checklistItemId}`, data || {})
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param actionItemId {String}
     * @param data {Array<Object>}
     * @param data[]._id {String} The _id property of the checklist item
     * @param data[].title {String}
     * @param data[].done {Boolean}
     * @param data[].completedBy {String}
     * @return {Promise<any>}
     */
    updateChecklistItemBulk(actionItemId, data) {
        return api
            .put(`/action-item/${actionItemId}/checklist/update/bulk`, data || [])
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param actionItemId {String}
     * @param checklistItemId {String} The _id property of the checklist item
     * @return {Promise<any>}
     */
    deleteChecklistItem(actionItemId, checklistItemId) {
        return api
            .delete(`/action-item/${actionItemId}/checklist/delete/${checklistItemId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param actionItemId {string}
     * @param linkId {string} Action item id to link to
     * @param linkType {'dependsOn'|'dependsOnMe'|'related'|'startToStart1'|'startToStart2'}
     * @return {Promise<any>}
     */
    addDependency(actionItemId, linkId, linkType = "dependsOn") {
        return api
            .post(`/action-item/${actionItemId}/dependency`, {
                linkId,
                linkType,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param actionItemId {string}
     * @param dependencyId {string} The link document id
     * @return {Promise<any>}
     */
    removeDependency(actionItemId, dependencyId) {
        return api
            .delete(`/action-item/${actionItemId}/dependency/${dependencyId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     * @param actionItemId {string}
     * @return {Promise<any>}
     */
    removeDependencyAll(actionItemId) {
        return api
            .delete(`/action-item/${actionItemId}/dependency/all`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data")
            })
    }

    /**
     *
     * EXAMPLE:
     *
     * apiActionItems.onChanges('update', data => console.log(data))
     *
     * @param operation {string} - update, create or delete
     * @param query {object} (optional) - socket.io query options
     * @param cb Callback method for the listener
     * @returns {Promise<void>}
     */
    async onChanges(operation, query, cb) {
        if (typeof query === "function") {
            cb = query
            query = {}
        }

        const socket = await api.socket.connect(`changes/action.items/${operation}`, {
            query,
        })

        socket.on("changes", cb)
    }

    async boardConnect() {
        return await api.socket.connect(`changes/action.items/board`)
    }
}

export default new ActionItemApi()
