import React from "react"
import ReactDOM from "react-dom"
import DocumentMeta from "react-document-meta"
import createHistory from "history/createBrowserHistory"

import App from "./App"

import { abortFileStreams } from "./services/file/file.service"
import ErrorBoundary from "./ErrorBoundary"
import { delAllExpiredCache } from "./utils/cache"
import * as serviceWorker from "./serviceWorker"
import DriveDRoutingComponent from "./drive/DriveDRouting"

const history = createHistory()
const data = {
    title: "missionX WORK SPACE",
    meta: {
        name: {
            /**
             * Prevent search engine indexing
             */
            robots: "noindex,nofollow",
        },
    },
}

let isDriveFileView = false
let urlParams

try {
    urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("drive") === "d") {
        isDriveFileView = true
    }
} catch (err) {}

class AppComponent extends React.Component {
    stripeEl
    history

    constructor(props) {
        super(props)
        this.state = { stripe: null }

        this.appRef = React.createRef()
    }

    componentDidMount() {
        delAllExpiredCache().catch(() => {
            // Nothing
        })

        window.addEventListener("beforeunload", abortFileStreams)
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", abortFileStreams)
    }

    render() {
        return (
            <DocumentMeta {...data}>
                <ErrorBoundary app={this.appRef.current}>
                    <App ref={this.appRef} />
                </ErrorBoundary>
            </DocumentMeta>
        )
    }
}

ReactDOM.render(
    isDriveFileView ? (
        <DriveDRoutingComponent fileId={urlParams.get("fileId")} fileName={urlParams.get("fileName")} />
    ) : (
        <DocumentMeta {...data}>
            <AppComponent />
        </DocumentMeta>
    ),
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
