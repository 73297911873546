import React from "react"

export default ({ width = 20, height=13 }) => (
    <svg width={width} height={height} viewBox="0 0 20 13" className="dna-svg-icon dna-icon-stroke dna-icon-check">

    <path d="M7.6822,12.7010542 L19.448469,1.05292036" transform="translate(13.825402, 6.950858) rotate(1.847610) translate(-13.825402, -6.950858) "></path>
    <path d="M1.41132983,6.32984116 L5.73236412,10.6722166" transform="translate(3.478333, 8.418185) rotate(-0.141140) translate(-3.478333, -8.418185) "></path>

    </svg>
)
