import React, { useEffect, useState, useRef, useCallback } from "react"
import cx from "classnames"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import format from "date-fns/format"
import { isArchived } from "../utils/utils"
import { getAiCode, actionItemPermissions } from "../utils/actionItem"
import apiComments from "../services/comment/comment.api"
import { toast } from "react-toastify"
import Messages from "./Messages"

import flatMap from "lodash/flatMap"
import Badge from "@material-ui/core/Badge"
import * as DOMPurify from "dompurify"
import { getMentionName } from "./MentionUtils"

import { motion, AnimatePresence } from "framer-motion"
import {
    PiAirplaneDuotone,
    PiFireDuotone,
    PiConfettiDuotone,
    PiChatCircleDuotone,
    PiCalendarDotsDuotone,
    PiEnvelopeOpenDuotone,
} from "react-icons/pi"
import Highlighter from "react-highlight-words"

import Panel from "./Panel"

import RoundAvatar from "./RoundAvatar"
import Tip from "./Tip"
import apiNotification from "../services/notification/notification.api"
import { getObjectId, getRefId, getPersonName } from "../utils/utils"

import uniq from "lodash/uniq"
import uniqBy from "lodash/uniqBy"

import history, { addParams, addParam } from "../comp/History"
import SimpleBarReact from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import "./PanelNotification.scss"

const PanelNotification = ({ app, notifications = [], open, onClose }) => {
    const ranOnce = useRef()

    const [allocations, setAllocations] = useState([])
    const [pinned, setPinned] = useState([])
    const [chats, setChats] = useState([])
    const [leaves, setLeaves] = useState([])
    const [invites, setInvites] = useState([])
    const [mappedMissions, setMappedMissions] = useState({})
    const [actionItemMap, setActionItemMap] = useState({})

    const [view, setView] = useState(localStorage.getItem("x-42H2" + app.state.person._id) || "pinned")

    const [showThread, setShowThread] = useState()

    const closeFn = (force) => {
        if (showThread && !force) {
            setShowThread(null)
        } else {
            onClose()
        }
    }

    useEffect(() => {
        if (!notifications.length) return
        const chat = []

        const missionMap = Object.fromEntries(app.state.missions.map((item) => [item._id, item]))
        setMappedMissions(missionMap)

        const myMissions = uniqBy(flatMap(notifications, "mission"), "_id")
            .map((m) => {
                return missionMap[m?._id]
            })
            .filter((m) => !!m)

        const a = myMissions
            .flatMap((m) => m.planItems || [])
            .flatMap((p) => p.actions || [])
            .flatMap((a) => a.actionItems)

        const am = Object.fromEntries(a.map((item) => [item._id, item]))

        setActionItemMap(am)

        let allos = []
        let pins = []
        let invs = []
        let lvs = []

        const orphanClients = checkForClientPrograms(app)
        if (orphanClients?.orphans) {
            pins.push({
                isPinned: true,
                system: true,
                orgData: orphanClients.orgData,
                title: orphanClients.orgData.title,
                subject: "Action required",
                body: `There are ${orphanClients.orphans} client accounts not linked to a group. Please link these from the resource allocation screens.`,
            })
        }

        if (app.state.missingTimesheets > 0) {
            pins.push({
                isPinned: true,
                system: true,
                title: "A message from mAX",
                orgData: {
                    title: "missionX",
                    logo: "/img/mAX.png",
                },
                subject: "Missing timesheets 🔥",
                body: `It looks like
                ${app.state.missingTimesheets === 1 ? "there is " : "there are"}
                ${app.state.missingTimesheets} missing project timesheet(s)
                over a three week period.`,
                onClick: () => {
                    closeFn()
                    history.push("/home")
                    app.setState({
                        timeTracking: true,
                    })
                },
            })
        }

        notifications.forEach((n, i) => {
            if (n.type === "mentionComment" && n.data?.roomName === "ActionItem") {
                const myM = missionMap[getObjectId(n.mission)]
                if (myM) chat.push({ ...n, mission: myM })
            } else if (n.type === "payment") {
                pins.push({
                    ...n,
                    isPinned: true,
                    system: true,
                    title: n.org.title,
                    orgData: n.org,
                    subject: "Payment issue",
                    body:
                        "Hello, your organization's payment information needs to be updated to continue your mission uninterrupted.",
                    onClick: () => {
                        apiNotification.markNotificationAsRead(n._id).catch(() => {
                            app.silentFail("3oiu92-2992")
                        })

                        closeFn()
                        addParams([
                            {
                                param: "org",
                                value: n.org._id,
                            },
                            {
                                param: "org-cfg",
                                value: "true",
                            },
                        ])
                    },
                })
            } else if (n.type === "orgRolePlanItemStatusChange") {
                let vals = []

                const org = app.state.orgs.find((o) => o._id === getObjectId(n.org))

                Object.keys(n.data.statusMap).forEach((key, i) => {
                    const obj = n.data.statusMap[key]

                    const oldHours =
                        (obj.oldWeeklyLumpHours || []).reduce((cumm, obj) => cumm + obj.hours, 0) +
                        (obj.oldDailyHours || []).reduce((cumm, obj) => cumm + obj.hours, 0)

                    vals.push({
                        program: org.programs?.find((p) => p._id === obj.programId),
                        status: obj.status,
                        roleName: org.roles?.find((r) => getObjectId(r) === obj.roleId)?.name,
                        oldHours,
                        hours:
                            (obj.weeklyLumpHours || []).reduce((cumm, obj) => cumm + obj.hours, 0) +
                            (obj.dailyHours || []).reduce((cumm, obj) => cumm + obj.hours, 0),
                    })
                })

                vals.forEach((v, i) => {
                    allos.push({
                        ...n,
                        isAllocation: true,
                        title: n.org.title,
                        orgData: n.org,
                        subject: v.program?.title,
                        body: `${v.roleName || "Role not found"} ${v?.hours}/hrs (Delta: ${
                            v.hours - v.oldHours
                        }/hrs) Status changed to: ${v.status}`,
                    })
                })
            } else if (n.type === "invite" || n.type === "orgInvite") {
                invs.push({
                    ...n,
                    isInvite: true,
                    title: "Welcome to the " + (n.type === "orgInvite" ? "organization" : "mission"),
                    subject: n.type,
                    body: n.body,
                    onClick: () => {
                        apiNotification.markNotificationAsRead(n._id).catch(() => {
                            app.silentFail("3oiu92-asd")
                        })
                        history.push("/home?invite-notice=" + n._id)
                    },
                })
            } else if (
                n.type === "leave_request_approval_final" ||
                n.type === "leave_request_approval" ||
                n.type === "leave_request_rejected"
            ) {
                if (!n.people[0] && n.type !== "leave_request_rejected") {
                    return
                }
                lvs.push({
                    ...n,
                    orgData: n.org,
                    title: n.title,
                    isLeave: true,
                    onClick: () => {
                        apiNotification.markNotificationAsRead(n._id).catch(() => {
                            app.silentFail("xcm-268W")
                        })

                        if (n.type === "leave_request_rejected") {
                            app.setState(
                                {
                                    showLeaveTracker: true,
                                },
                                () => {
                                    onClose()
                                }
                            )
                            return
                        }
                        const or = app.state.orgs.find((o) => o._id === getObjectId(n.org))

                        if (!or) {
                            return
                        }
                        const meInOrg = or.people.find((o) => getRefId(o) === app.state.person._id)

                        if (meInOrg.canApproveLeaves) {
                            onClose()
                            addParams([
                                {
                                    param: "org",
                                    value: or?._id,
                                },
                                {
                                    param: "org-tab",
                                    value: "org-leaves",
                                },
                            ])
                            return
                        }

                        app.setState(
                            {
                                avKey: new Date().getTime(),
                            },
                            () => {
                                onClose()
                            }
                        )
                        if (n.title === "Leave requests cancelled") {
                            history.push("/board?board-tab=leaves")
                        } else {
                            history.push("/home?leave-notice=" + n._id)
                        }
                    },
                })
            }
        })

        setAllocations(allos)
        setPinned(pins)
        setLeaves(lvs)
        setChats(chat)
        setInvites(invs)

        if (!ranOnce.current && !pins.length && chat.length) {
            ranOnce.current = true
            setView("chats")
        }
    }, [notifications])

    useEffect(() => {
        if (open) {
            app.hideKnobs()
        } else {
            app.showKnobs()
        }
    }, [open])

    useEffect(() => {
        localStorage.setItem("x-42H2" + app.state.person._id, view)
    }, [view])

    const unreadCount = chats?.filter((c) => !c.isRead).length || 0

    return (
        <Panel
            open={open}
            maxWidth={showThread ? 1000 : 620}
            onClose={() => closeFn()}
            className={"panel-notification"}
            outsideClickIgnoreClass="x-aix-img-msg-view"
            onBlur={() => {
                onClose()
            }}
        >
            <CommsBar
                setView={setView}
                view={view}
                chats={chats}
                leaves={leaves}
                invites={invites}
                pinned={pinned}
                allocations={allocations}
                setShowThread={setShowThread}
                unreadCount={unreadCount}
            />
            <MessagesBar
                setView={setView}
                view={view}
                chats={chats.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())}
                leaves={leaves.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())}
                invites={invites.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())}
                pinned={pinned.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())}
                allocations={allocations.sort(
                    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                )}
                setShowThread={setShowThread}
                showThread={showThread}
                actionItemMap={actionItemMap}
                app={app}
                unreadCount={unreadCount}
                mappedMissions={mappedMissions}
            />
            <Comms
                key={showThread?._id}
                app={app}
                message={showThread}
                actionItemMap={actionItemMap}
                closeFn={() => {
                    setShowThread(null)
                }}
            />
        </Panel>
    )
}

const Comms = ({ message, app, actionItemMap, closeFn }) => {
    const socketRef = useRef()
    const msgAudio = useRef()
    const threadsRef = useRef()
    const [thread, setThread] = useState([])
    const [ai, setAi] = useState()
    const [mission, setMission] = useState()
    const [orgData, setOrgData] = useState()
    const [topic, setTopic] = useState()
    const [permissions, setPermissions] = useState()
    const [subTask, setSubTask] = useState()

    const onSocketChange = useCallback((changes) => {
        let newComments = threadsRef.current.slice()

        changes.forEach((change, i) => {
            if (change.changeType === "create") {
                newComments.push(change.doc)
                msgAudio?.current.play()
            }
            if (change.changeType === "delete") {
                let myIndex = newComments.findIndex((c) => c._id === change._id)

                newComments.splice(myIndex, 1)
            }
        })
        threadsRef.current = newComments
        setThread(newComments)
    }, [])

    useEffect(() => {
        if (!message) return
        const theAI = actionItemMap[message?.data?.room]

        const theOrg = app.state.orgs.find((o) => o._id === getObjectId(message.mission?.org))
        const theTopic = message.data.topic

        if (!theAI) {
            toast.warning("🌵 Ooops. This thread is no longer available")
            closeFn()
            return
        }
        const thePermissions = actionItemPermissions({
            actionItem: theAI,
            mission: message.mission,
            app,
        })
        const cM = theAI.checklist?.find((c) => c._id === theTopic)

        setTopic(theTopic)
        setSubTask(cM)
        setAi(theAI)
        setMission(message.mission)
        setOrgData(theOrg)
        setPermissions(thePermissions)

        apiComments
            .actionItem(theAI?._id)
            .getCommentList()
            .then((res) => {
                //thread.filter((c) => (message.topic ? c.topic === topic : !c.topic)) : []
                setThread(res)
                threadsRef.current = res
            })
            .catch((err) => {
                toast.error(err.message)
            })

        socketRef.current = apiComments.actionItem(theAI?._id).onChanges(onSocketChange)

        return () => {
            socketRef?.current?.disconnect()
        }
    }, [message])

    if (!message || !ai) {
        return <></>
    }

    return (
        <div className="x-comms-chat">
            <div className="x-comms-chat-context">
                <div
                    className="dna-round-avatar"
                    style={{ marginRight: 8, backgroundImage: mission.theme.backgroundImage }}
                />
                <div className="dna-smaller-text">
                    <div
                        style={{ marginBottom: 3 }}
                        className="x-link"
                        onClick={() => {
                            addParam("mission", mission._id)
                        }}
                    >
                        {mission.projectCode} {mission.title}
                    </div>
                    <br />
                    <div className="dna-flex-apart">
                        <div
                            className="x-link"
                            onClick={() => {
                                app.showActionItemDetails(message.data.room, {
                                    showComments: true,
                                    topic: message.data?.topic,
                                })
                                closeFn(true)
                            }}
                        >
                            {ai.code} {ai.title || "No title for this task"}
                        </div>
                        {message.subTask && (
                            <Tip
                                className="tip-left x-link"
                                html={
                                    <div className="tip-left">
                                        <p>This is a thread from the following subtask: </p>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(message.subTask.title),
                                            }}
                                        ></div>
                                    </div>
                                }
                            >
                                info
                            </Tip>
                        )}
                    </div>
                </div>
            </div>
            <Messages
                key={message?.data?.room}
                ai={ai}
                app={app}
                permissions={permissions}
                showComments={true}
                comments={thread}
                orgData={orgData}
                mission={mission}
                topic={topic}
            />
            <audio ref={msgAudio} style={{ display: "none" }}>
                <source src="/video/message.mp3" type="audio/mpeg" />
            </audio>
        </div>
    )
}

const CommsBar = ({ view, setView, pinned, chats, leaves, invites, allocations, setShowThread, unreadCount }) => {
    return (
        <div className="x-comms-bar">
            <ul>
                <Badge badgeContent={pinned?.filter((c) => c.system).length || 0} color="secondary">
                    <li
                        onClick={() => {
                            setView("pinned")
                            setShowThread(null)
                        }}
                        className={cx({
                            active: view === "pinned",
                        })}
                    >
                        <Tip position="right" distance={20} title="Pinned and important messages">
                            <PiFireDuotone />
                        </Tip>
                    </li>
                </Badge>
                <Badge badgeContent={unreadCount} color="primary">
                    <li
                        onClick={() => {
                            setShowThread(null)
                            setView("chats")
                        }}
                        className={cx({
                            active: view === "chats",
                        })}
                    >
                        <Tip distance={20} position="right" title="Task msgs">
                            <PiChatCircleDuotone />
                        </Tip>
                    </li>
                </Badge>
                <Badge badgeContent={invites?.filter((c) => !c.isRead).length || 0} color="primary">
                    <li
                        onClick={() => {
                            setShowThread(null)
                            setView("invites")
                        }}
                        className={cx({
                            active: view === "invites",
                        })}
                    >
                        <Tip distance={20} position="right" title="Invites to projects and organizations">
                            <PiConfettiDuotone />
                        </Tip>
                    </li>
                </Badge>
                <Badge badgeContent={leaves?.filter((c) => !c.isRead).length || 0} color="primary">
                    <li
                        onClick={() => {
                            setShowThread(null)
                            setView("leaves")
                        }}
                        className={cx({
                            active: view === "leaves",
                        })}
                    >
                        <Tip distance={20} position="right" title="Leave requests">
                            <PiAirplaneDuotone />
                        </Tip>
                    </li>
                </Badge>
                <Badge badgeContent={allocations?.filter((c) => !c.isRead).length || 0} color="primary">
                    <li
                        onClick={() => {
                            setView("allocations")
                        }}
                        className={cx({
                            active: view === "allocations",
                        })}
                    >
                        <Tip distance={20} position="right" title="Allocation changes">
                            <PiCalendarDotsDuotone />
                        </Tip>
                    </li>
                </Badge>
            </ul>
        </div>
    )
}

const MessagesBar = ({
    view,
    setView,
    critical,
    chats = [],
    leaves = [],
    invites = [],
    pinned = [],
    allocations = [],
    setShowThread,
    showThread,
    actionItemMap,
    app,
    unreadCount,
    mappedMissions,
}) => {
    const [search, setSearch] = useState()
    const [showUnread, setShowUnread] = useState()

    return (
        <div className={cx("x-comms-messages", view)}>
            <h3>
                {view === "pinned"
                    ? "Pinned messages"
                    : view === "chats"
                    ? "Chats"
                    : view === "leaves"
                    ? "Leave notifications"
                    : view === "allocations"
                    ? "Allocations"
                    : "Invites"}
            </h3>

            {(view === "chats" || view === "allocations") && (
                <div className="x-comms-filter-bar">
                    {unreadCount > 0 && (
                        <Tip
                            position="left"
                            title="Show unread messages"
                            className={cx("dna-under-con", { active: showUnread })}
                            onClick={() => {
                                setShowUnread(!showUnread)
                            }}
                        >
                            <PiEnvelopeOpenDuotone />
                        </Tip>
                    )}
                    <input
                        type="text"
                        placeHolder="Find..."
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                    />
                </div>
            )}

            <SimpleBarReact className="x-comms-scroll">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={view}
                        initial={{ y: 30, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -30, opacity: 0, height: 0, overflow: "hidden" }}
                        transition={{ duration: 0.4, delay: 0.3 }}
                    >
                        <Chats
                            app={app}
                            messages={
                                view === "chats"
                                    ? chats
                                    : view === "pinned"
                                    ? pinned
                                    : view === "leaves"
                                    ? leaves
                                    : view === "allocations"
                                    ? allocations
                                    : invites
                            }
                            search={search}
                            view={view}
                            showUnread={showUnread}
                            setShowThread={setShowThread}
                            showThread={showThread}
                            actionItemMap={actionItemMap}
                            mappedMissions={mappedMissions}
                        />
                    </motion.div>
                </AnimatePresence>
            </SimpleBarReact>
        </div>
    )
}

const Chats = ({
    messages,
    setShowThread,
    showThread,
    actionItemMap,
    view,
    search,
    app,
    mappedMissions,
    showUnread,
}) => {
    let myMessages = messages
        .map((message) => {
            return normalizedData({ message, actionItemMap, app })
        })
        .filter((m) => !!m)

    function normalizeString(str) {
        return str.replace(/\s+/g, " ").trim().toLowerCase()
    }

    const refinedSearchString = search ? normalizeString(search) : search

    const filterFn = (message) => {
        const { obj } = message

        let titleOk = normalizeString(obj.title || "").includes(refinedSearchString)
        let subjectOk = normalizeString(obj.subject || "").includes(refinedSearchString)
        let bodyOk = normalizeString(obj.body || "").includes(refinedSearchString)

        return titleOk || subjectOk || bodyOk
    }

    const refinedMessages = search?.length ? myMessages.filter(filterFn) : myMessages

    return (
        <div className="x-comms-chats">
            {messages?.length === 0 && (
                <p style={{ marginTop: 30 }}>
                    Nothing to show... 👻 &nbsp; &nbsp;
                    {view === "pinned" && "No news is good news I guess."}
                    {view === "chats" && "Collaboration messages from task chats will appear here."}
                </p>
            )}
            {refinedMessages.map((message) => {
                if (showUnread && message.isRead) {
                    return null
                }

                return (
                    <Message
                        key={message?._id}
                        {...{
                            message,
                            messages,
                            setShowThread,
                            showThread,
                            app,
                            actionItemMap,
                            mappedMissions,
                            search,
                            obj: message.obj,
                        }}
                    />
                )
            })}
        </div>
    )
}

const normalizedData = ({ message, actionItemMap, app }) => {
    const isAi = message?.data?.roomName === "ActionItem"
    const isPinned = message.isPinned

    let title = ""
    let person
    let ai
    let subject = ""
    let body = ""
    let system
    let subSubject = ""
    let subTask

    if (isAi) {
        person = message.people[0]
        title = getPersonName(person)

        ai = actionItemMap[message?.data.room]

        subject = !ai ? "Task no longer exists" : getAiCode(ai) + " " + (ai?.title || "No task title...")
        body = message?.body || "No message..."

        const mn = "@" + getMentionName(app.state.person.firstName, app.state.person.lastName)
        const sn = "@" + getMentionName(person.firstName, person.lastName)

        const subTaskIndex = message.data.topic ? ai.checklist?.findIndex((c) => c._id === message.data.topic) : -1

        if (subTaskIndex !== -1) {
            subTask = ai.checklist[subTaskIndex]
            subSubject = "Subtask " + (subTaskIndex + 1)
        }

        body = body.replaceAll(mn + " ", "")
        body = body.replaceAll(sn + " ", "")
    } else if (message.isLeave) {
        person = message.people[0]

        if (message.type === "leave_request_rejected") {
            person = {
                avatar: "/img/mAX.png",
                email: "help@missionx.ai",
                ref: { firstName: "mAX", lastName: " " },
            }
            title = "Oh-oh, a leave request was rejected"

            body = "Please have a look at your leaves to see why."
        } else {
            title =
                getPersonName(person) +
                " " +
                (message.title?.includes("cancelled") ? "cancelled a leave request" : "submitted a leave request")

            body =
                message.title === "Leave requests cancelled"
                    ? "A leave request that may affect your projects was cancelled"
                    : "Your input is required regarding this request. Please cast your vote as this may affect some or all of your projects."
        }

        //
    } else if (message.system) {
        title = message.title
        subject = message.subject
        body = message.body
        system = true
        person = {
            avatar: message.orgData.logo || "/img/org-no-logo.jpg",
        }
    } else if (message.type === "payment") {
        title = message.title
        system = true
        subject = message.subject
        body = message.body
        person = {
            avatar: message.orgData.logo || "/img/org-no-logo.jpg",
        }
    } else if (message.isAllocation) {
        title = message.title

        subject = message.subject
        body = message.body
        person = {
            avatar: message.orgData.logo || "/img/org-no-logo.jpg",
        }
    } else if (message.isInvite) {
        person = message.people[0]

        if (!person || person.firstName === "" || person.lastName === "") {
            return null
        }
        title = getPersonName(person)

        subject = message.mission?.title || message.org?.title
        body =
            person?.firstName +
            " has invited you to join the " +
            (message.type === "orgInvite" ? "Organization 🎉" : "Project 🎉🎉🎉") +
            " Welcome aboard!"
    }

    return {
        ...message,
        system: system,
        subTask,
        obj: {
            isAi,
            isPinned,
            title,
            subSubject,
            ai,
            subject,
            person,
            body,
        },
    }
}

const Message = ({
    message,
    messages,
    setShowThread,
    showThread,
    app,
    actionItemMap = {},
    search,
    obj,
    mappedMissions = {},
}) => {
    const personInMission = mappedMissions[getObjectId(message.mission)]?.people?.find(
        (p) => getRefId(p) === getRefId(obj.person)
    )
    let comp = null
    if (personInMission) {
        comp = (
            <div style={{ marginTop: 10 }}>
                <div>
                    {personInMission.isProjectManager
                        ? "Project manager"
                        : personInMission.permission === 2
                        ? "Project owner"
                        : personInMission.permission === 1
                        ? "Team lead"
                        : "Team member"}
                </div>
                <div>{mappedMissions[getObjectId(message.mission)].title}</div>
            </div>
        )
    }
    return (
        <div
            className={cx("x-comms-message", {
                read: message.isRead,
            })}
            onClick={
                obj?.isAi
                    ? () => {
                          setShowThread(message)
                          apiNotification.markNotificationAsRead(message._id).catch(() => {
                              app.silentFail("xcm-268W")
                          })
                      }
                    : message.onClick
            }
        >
            <RoundAvatar
                person={personInMission || obj.person || message.people[0]}
                width={44}
                noTip={message.system}
                style={{ marginTop: 2, borderRadius: "50%", marginRight: 12, marginLeft: 4 }}
                comp={comp}
                noFlip={false}
            />
            <div className="x-comms-content">
                <div className="x-comms-message-header">
                    <Highlighter
                        highlightClassName="dna-highlight-text"
                        searchWords={[search]}
                        autoEscape={true}
                        textToHighlight={obj?.title || ""}
                    />

                    {message.createdAt && (
                        <Tip noFlip title={format(new Date(message.createdAt), "eee. MMM. do, yyyy h:mm a")}>
                            <span className="x-comms-distance">
                                {formatDistanceToNow(new Date(message.createdAt), { addSuffix: true })}
                            </span>
                        </Tip>
                    )}
                </div>
                <div className="x-comms-message-subject x-clamp-1">
                    <Highlighter
                        highlightClassName="dna-highlight-text"
                        searchWords={[search]}
                        autoEscape={true}
                        textToHighlight={obj?.subject || ""}
                    />
                </div>
                {obj.subSubject && <div className="x-comms-message-sub-subject">{obj.subSubject}</div>}
                <div
                    className={cx("x-comms-message-body", {
                        "x-clamp-2": !message.isAllocation,
                    })}
                >
                    <Highlighter
                        highlightClassName="dna-highlight-text"
                        searchWords={[search]}
                        autoEscape={true}
                        textToHighlight={obj?.body || ""}
                    />
                </div>
            </div>
        </div>
    )
}

export const checkForClientPrograms = (app) => {
    const orgData = app.state.orgs.find((o) => o._id === "63735f0e6494000d61444b4e")

    let orphans = 0
    if (orgData) {
        const meInOrg = orgData.people.find((p) => getRefId(p) === getRefId(app.state.person))

        if (meInOrg.canApproveRoles || meInOrg.permission === 2) {
            const allClientsUsed = uniq(
                flatMap(
                    orgData.programs.filter((p) => !isArchived(p) && !p.portfolio),
                    "clients"
                ).filter((c) => !!c)
            )
            orphans = orgData.clients.length - allClientsUsed?.length
        }
    }

    return {
        orgData,
        orphans,
    }
}

PanelNotification.displayName = "PanelNotification"
export default PanelNotification
