import React from "react"
import classnames from "classnames"
import get from "lodash/get"
import addDays from "date-fns/addDays"
import {isAfter} from "date-fns/isAfter"
import format from "date-fns/format"
import formatRelative from "date-fns/formatRelative"
import ReactDOM from "react-dom"
import "./Drive.scss"
import authService from "../services/auth/auth.service"
import apiFile from "../services/file/file.api"
import { getFileTypeFromPath } from "../services/file/file.service"
import { FILE_TYPE } from "../services/file/file.const"
import ViewUnsupported from "./view/ViewUnsupported"
import ViewImage from "./view/ViewImage"
import ViewDocument from "./view/ViewDocument"
import ViewAudio from "./view/ViewAudio"
import ViewVideo from "./view/ViewVideo"

import IconX from "../comp/icons/IconX"
import Avatar from "../comp/Avatar"
import IconCloudDownload from "../comp/icons/IconCloudDownload"
import IconDots from "../comp/icons/IconDots"
import DotMenu from "../comp/DotMenu"

import IconTrash from "../comp/icons/IconTrash"
import IconChevron from "../comp/icons/IconChevron"
import IconLink from "../comp/icons/IconLink"
import { toast, ToastContainer } from "react-toastify"
import { IoClose } from "react-icons/io5"

class DriveComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isUserLoggedIn: null,
            isMoreActionOpen: false,
            file: null,
            fileList: null,
            createdBy: null,
        }
        this.driveEl = React.createRef()
        this.isFileListSet = false
    }

    componentDidMount() {
        this.setUser()
        this.setFile()
        this.driveEl.current.focus() // For keyboard navigation
    }

    componentDidUpdate(prevProps) {
        const { files } = this.props
        const filesPrev = prevProps.files
        const filesLength = files?.length ?? 0
        const filesPrevLength = filesPrev?.length ?? 0

        if ((!this.isFileListSet && files) || filesLength !== filesPrevLength) {
            this.isFileListSet = true
            this.setState({
                fileList: files,
            })
        }
    }

    close(e) {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        const { onClose, isRoot } = this.props
        const { isMoreActionOpen, file } = this.state

        if (!isMoreActionOpen) {
            onClose && onClose()
        }

        if (isRoot) {
            const base = "https://app.missionx.ai/home"

            switch (file?.attachedTo) {
                case "missions":
                    return (window.location.href = `${base}?mission=${file.attachedToId}`)
                case "action.items":
                    return (window.location.href = `${base}?mission=${file.attachedToMissionId}&action-item=${file.attachedToId}`)
                default:
                    window.location.href = base
            }
        }
    }

    setUser() {
        authService
            .getCurrentUser()
            .then((user) => {
                this.setState({
                    isUserLoggedIn: !!user,
                })
            })
            .catch(() => {
                this.setState({
                    isUserLoggedIn: false,
                })

                // TODO: Handle public/shared files.
                window.location.href = "/login"
            })
    }

    setFile(file) {
        const fileToSet = file ?? this.props.file

        if (typeof fileToSet === "string") {
            apiFile
                .getFile(fileToSet)
                .then((item) => {
                    if (this.props.isRoot) {
                        let urlFileName = window.location.pathname.split("/")[4]
                        if (decodeURIComponent(urlFileName) !== item.name) {
                            return (window.location.href = "/")
                        }
                    }

                    this.setState({ file: item })
                })
                .catch((err) => {
                    this.setState({
                        isLoading: false,
                    })
                    if (err?.response?.status === 403 || err === "Forbidden") {
                        toast.error("You do not have the right permissions to view this file.", { autoClose: false })
                    } else {
                        this.close()
                    }
                })
        } else {
            this.setState({ file: fileToSet })
        }
    }

    getFileView(file) {
        const type = getFileTypeFromPath(file.path)
        let ViewComponent

        switch (type) {
            case FILE_TYPE.IMAGE:
                ViewComponent = ViewImage
                break
            case FILE_TYPE.DOCUMENT:
            case FILE_TYPE.CODE:
                ViewComponent = ViewDocument
                break
            case FILE_TYPE.AUDIO:
                ViewComponent = ViewAudio
                break
            case FILE_TYPE.VIDEO:
                ViewComponent = ViewVideo
                break
            default:
                ViewComponent = ViewUnsupported
        }

        return <ViewComponent key={file._id} file={file} onLoad={() => this.onViewLoad()} />
    }

    onViewLoad() {
        this.setState({
            isLoading: false,
        })
    }

    getUploadedDateLabel(uploadedDate) {
        try {
            const isWithinLastSixDays = isAfter(addDays(new Date(), 6), new Date(uploadedDate))

            if (isWithinLastSixDays) {
                let relativeDate = formatRelative(new Date(uploadedDate), new Date())
                return `${relativeDate.charAt(0).toUpperCase()}${relativeDate.substring(1)}`
            }

            return format(new Date(uploadedDate), "MMM d, yyyy h:mm a")
        } catch (err) {
            return ""
        }
    }

    getMoreOptions() {
        const { canDelete, isRoot } = this.props
        const { file } = this.state
        const menuItems = [
            <div
                className="dna-drive-dots-menu-item dna-drive-dots-menu-item-share"
                onClick={() => this.onActionMoreShare()}
            >
                <div className="dna-drive-dots-menu-item-icon">
                    <IconLink width={16} />
                </div>
                <div className="dna-drive-dots-menu-item-label">Share Link</div>
            </div>,
        ]

        if (!isRoot && (canDelete === true || ["OWNER", "WRITER"].includes(file?.fileRole))) {
            menuItems.push(<div className="dna-dots-menu-separator" />)
            menuItems.push(
                <div
                    className="dna-drive-dots-menu-item dna-drive-dots-menu-item-delete"
                    onClick={() => this.onActionMoreDelete()}
                >
                    <div className="dna-drive-dots-menu-item-icon">
                        <IconTrash width={16} />
                    </div>
                    <div className="dna-drive-dots-menu-item-label">Delete</div>
                </div>
            )
        }

        return menuItems
    }

    onActionDownload() {
        const { file, isMoreActionOpen } = this.state

        if (!isMoreActionOpen) {
            apiFile.streamFileDownload(file).catch((err) => {
                console.log(err)
            })
        }
    }

    onActionMoreOpen() {
        this.setState({
            isMoreActionOpen: true,
        })
    }

    onActionMoreClose(timeout = 0) {
        setTimeout(() => {
            this.setState({
                isMoreActionOpen: false,
            })
        }, timeout)
    }

    onActionMoreRename() {
        // alert('RENAME');
    }

    onActionMoreShare() {
        const { file } = this.state
        this.onActionMoreClose()

        try {
            const link =
                file.source === "external" ? file.path : `${window.location.host}/drive/d/${file._id}/${file.name}`
            navigator.clipboard.writeText(link).then(() => toast.success("Link copied to clipboard!"))
        } catch (err) {
            toast.error("Sorry, failed to generate a sharing link.")
        }
    }

    onActionMoreDelete() {
        const { file } = this.state

        this.onActionMoreClose()

        this.close()
        this.props.onClose()
        this.props.app?.onFileDelete(file)
    }

    onFileNavigation(e, file) {
        e.preventDefault()
        e.stopPropagation()

        if (file) {
            this.setState({
                isLoading: true,
                file,
            })
        }
    }

    render() {
        const { isRoot } = this.props
        const { isLoading, isMoreActionOpen, file, fileList } = this.state
        const fileIndex = (fileList || []).findIndex((item) => item._id === get(file, "_id"))
        const navPrevious = fileIndex > -1 ? fileList[fileIndex - 1] : null
        const navNext = fileIndex > -1 ? fileList[fileIndex + 1] : null

        const code = (
            <div
                ref={this.driveEl}
                tabIndex="0"
                onKeyDown={(e) => {
                    if (e.keyCode === 39) this.onFileNavigation(e, navNext)
                    else if (e.keyCode === 37) this.onFileNavigation(e, navPrevious)
                }}
                className={classnames("dna-drive", "dont-close-action-item", { "dna-drive-is-loading": isLoading })}
            >
                {!!file && (
                    <div className="dna-drive-toolbar">
                        <div className="dna-drive-toolbar-info">
                            <div className="dna-drive-toolbar-info-avatar">
                                <Avatar avatar={get(file, "createdBy.avatar")} size="small" noTip={true} />
                            </div>

                            <div className="dna-drive-toolbar-info-details">
                                <div className="dna-drive-toolbar-info-details-file-name">{file.name}</div>
                                <div className="dna-drive-toolbar-info-details-uploaded">
                                    <b>
                                        {get(file, "createdBy.firstName")} {get(file, "createdBy.lastName")}
                                    </b>{" "}
                                    {this.getUploadedDateLabel(file.createdAt)}
                                </div>
                            </div>
                        </div>

                        <div className="dna-dark dna-drive-toolbar-action">
                            {!this.props.noMenu && (
                                <>
                                    <div
                                        className="dna-drive-toolbar-action-item dna-drive-toolbar-action-download"
                                        onClick={() => this.onActionDownload()}
                                    >
                                        <IconCloudDownload />
                                    </div>

                                    {!isRoot && (
                                        <div
                                            className="dna-drive-toolbar-action-item dna-drive-toolbar-action-more"
                                            onClick={() => this.onActionMoreOpen()}
                                        >
                                            <IconDots width={7} height={18} />

                                            {isMoreActionOpen && !this.props.noMenu && (
                                                <DotMenu
                                                    className="dna-drive-dots-menu"
                                                    theme="light"
                                                    links={this.getMoreOptions()}
                                                    onClose={() => this.onActionMoreClose(150)}
                                                />
                                            )}
                                        </div>
                                    )}
                                </>
                            )}

                            <div className="dna-drive-toolbar-action-separator" />

                            <div
                                className="dna-dark dna-drive-toolbar-action-item dna-drive-toolbar-action-close"
                                onClick={(e) => this.close(e)}
                            >
                                <IconX size={18} />
                            </div>
                        </div>
                    </div>
                )}

                <div className="dna-drive-main" onClick={(e) => this.close(e)}>
                    <div className="dna-drive-main-left">
                        {!!navPrevious && (
                            <div
                                onClick={(e) => this.onFileNavigation(e, navPrevious)}
                                className="dna-drive-nav-button"
                            >
                                <IconChevron width={24} />
                            </div>
                        )}
                    </div>
                    <div className="dna-drive-main-view">
                        {!!file && this.getFileView(file)}
                        {isLoading && <div className="dna-drive-main-view-loader" />}
                    </div>
                    <div className="dna-drive-main-right">
                        {!!navNext && (
                            <div onClick={(e) => this.onFileNavigation(e, navNext)} className="dna-drive-nav-button">
                                <IconChevron width={24} />
                            </div>
                        )}
                    </div>
                </div>

                {!!file && <div className="dna-drive-footer" />}

                {isRoot === true && (
                    <ToastContainer
                        closeButton={({ closeToast }) => (
                            <span className="cursor" onClick={closeToast}>
                                <IoClose color="gray" />
                            </span>
                        )}
                        autoClose={3000}
                        className="toast-the-dna no-blur"
                        position={toast.POSITION.TOP_CENTER}
                    />
                )}
            </div>
        )

        return ReactDOM.createPortal(code, document.getElementById("root"))
    }
}

DriveComponent.displayName = "DriveComponent"
export default DriveComponent
