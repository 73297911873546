import api from '../api.service';
import {Cookie} from '../../utils/utils';

export async function setCsrf() {
    return api
        .post(`/session/csrf/set`, {}, {
            withCredentials: true
        })
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export async function revokeCsrf(text) {
    return api
        .post(`/session/csrf/revoke`, {}, {
            withCredentials: true
        })
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export async function setSessionCookie() {
    return api
        .post(`/session/cookie/set`, {}, {
            withCredentials: true,
            headers: {
                'MX_CSRF_TOKEN': Cookie.get('mx_csrfToken')
            }
        })
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}

export async function revokeSessionCookie() {
    return api
        .post(`/session/cookie/revoke`, {}, {
            withCredentials: true,
            headers: {
                'MX_CSRF_TOKEN': Cookie.get('mx_csrfToken')
            }
        })
        .then((res) => res.data)
        .catch((err) => {
            throw api.getResponseError(err)
        })
}