import {io} from "socket.io-client"
import get from "lodash/get"
import authService from "./auth/auth.service"
import { getFileMetadata } from "./file/file.service"
import { FILE_UPLOAD_SIZE_LIMIT_MB, FILE_UPLOAD_LIMIT } from "./file/file.const"
import { convertSize } from "../comp/Utils"

const axios = require("axios")
const API_HOST = process.env.REACT_APP_API_SERVICE_HOST;

class Api {
    constructor() {
        this.public = {
            get: (url) => this.get(url, true),
            post: (url, payload) => this.post(url, payload, true),
            put: (url, payload) => this.post(url, payload, true),
            delete: (url) => this.get(url, true),
        }

        this.socket = {
            connect: (namespace, options) => this.connect(namespace, options),
            connectAi: (namespace) => this.connectAi(namespace),
        }
    }

    /**
     *
     * @param url
     * @param isPublic
     * @param options
     * @return {Promise<string>}
     */
    get(url, isPublic = false, options = {}) {
        if (typeof isPublic === "object") {
            options = isPublic
            isPublic = false
        }

        return this._getUserToken(isPublic).then((token) => {
            const reqOptions = {
                method: "get",
                url: `${API_HOST}${url}`,
                ...options,
            }

            if (token) {
                reqOptions.headers = {
                    Authorization: `Bearer ${token}`,
                    "user-request-id": authService.getUserRequestId(),
                    ...options.headers,
                }
            }

            return axios(reqOptions)
        })
    }

    /**
     * @param url
     * @param isPublic
     * @param options
     * @return {Promise<string>}
     */
    fetch(url, isPublic = false, options = {}) {
        if (typeof isPublic === "object") {
            options = isPublic
            isPublic = false
        }

        return this._getUserToken(isPublic).then((token) => {
            const reqOptions = {
                method: options.method || "get",
                ...options,
            }

            if (token) {
                reqOptions.headers = {
                    Authorization: `Bearer ${token}`,
                    "user-request-id": authService.getUserRequestId(),
                    ...options.headers,
                }
            }

            return fetch(`${API_HOST}${url}`, reqOptions)
        })
    }

    post(url, payload, isPublic = false, options = {}) {
        if (typeof isPublic === "object") {
            options = isPublic
            isPublic = false
        }

        return this._getUserToken(isPublic).then((token) => {
            const reqOptions = {
                method: "post",
                url: `${API_HOST}${url}`,
                data: payload,
                ...options,
            }

            if (token) {
                reqOptions.headers = {
                    Authorization: `Bearer ${token}`,
                    "user-request-id": authService.getUserRequestId(),
                    ...options.headers,
                }
            }

            return axios(reqOptions)
        })
    }

    put(url, payload, isPublic = false, options = {}) {
        if (typeof isPublic === "object") {
            options = isPublic
            isPublic = false
        }

        return this._getUserToken(isPublic).then((token) => {
            const reqOptions = {
                method: "put",
                url: `${API_HOST}${url}`,
                data: payload,
                ...options,
            }

            if (token) {
                reqOptions.headers = {
                    Authorization: `Bearer ${token}`,
                    "user-request-id": authService.getUserRequestId(),
                    ...options.headers,
                }
            }

            return axios(reqOptions)
        })
    }

    delete(url, isPublic = false, options = {}) {
        if (typeof isPublic === "object") {
            options = isPublic
            isPublic = false
        }

        return this._getUserToken(isPublic).then((token) => {
            const reqOptions = {
                method: "delete",
                url: `${API_HOST}${url}`,
                ...options,
            }

            if (token) {
                reqOptions.headers = {
                    Authorization: `Bearer ${token}`,
                    "user-request-id": authService.getUserRequestId(),
                    ...options.headers,
                }
            }

            return axios(reqOptions)
        })
    }

    async uploadFileSingle(url, file, options = {}) {
        const formData = new FormData()
        formData.append(options.name || "file", file)

        if (convertSize(file.size, "bytes", "MB") >= FILE_UPLOAD_SIZE_LIMIT_MB) {
            throw this.getResponseErrorFileUpload({
                code: "LIMIT_FILE_SIZE",
            })
        }

        return this.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                if (typeof options.onUploadProgress === "function") {
                    options.onUploadProgress(progressEvent.loaded, progressEvent.total)
                }
            },
        }).catch((err) => {
            throw this.getResponseErrorFileUpload(err)
        })
    }

    async uploadFileMulti(url, files, options = {}) {
        const formData = new FormData()

        if (!Array.isArray(files)) {
            files = [files]
        }

        if (files.length > FILE_UPLOAD_LIMIT) {
            throw this.getResponseErrorFileUpload({
                code: "LIMIT_FILE",
            })
        }

        if (files.some((item) => convertSize(item.size, "bytes", "MB") >= FILE_UPLOAD_SIZE_LIMIT_MB)) {
            throw this.getResponseErrorFileUpload({
                code: "LIMIT_FILE_SIZE",
            })
        }

        try {
            // Set metadata
            let metadata = {}
            for (let file of files) {
                metadata[file.name] = await getFileMetadata(file)
            }
            formData.append("metadata", JSON.stringify(metadata))
        } catch (err) {
            // Do nothing
        }

        ;(files || []).forEach((item) => formData.append("file", item))

        return !formData.entries().next().done
            ? this.post(url, formData, {
                  headers: {
                      "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: (progressEvent) => {
                      if (typeof options.onUploadProgress === "function") {
                          options.onUploadProgress(progressEvent.loaded, progressEvent.total)
                      }
                  },
              }).catch((err) => {
                  throw this.getResponseErrorFileUpload(err)
              })
            : {
                  data: [],
              }
    }

    connect(namespace, options = {}) {
        return this._getUserToken().then((token) => {
            options.query = {
                ...options.query,
                userRequestId: authService.getUserRequestId()
            };
            options.auth = {
                ...options.auth,
                token
            }

            const socket = io(
                `${API_HOST}/${namespace}`,
                {
                    ...options,
                    withCredentials: true,
                    transports: ["websocket"],
                },
            )

            return new Promise((resolve, reject) => {
                socket.on("connect", () => {
                    resolve(socket)
                })
                socket.on("connect_error", (err) => {
                    if (err === "auth/id-token-expired") {
                        this._getUserToken().then((token) => {
                            // Get fresh token and update the connection query
                            socket.auth.token = token
                            socket.connect()
                        })
                    }
                });
            })
        })
    }

    connectAi(namespace) {
        return this._getUserToken().then((token) => {
            const socket = io(`${API_HOST}/ai${namespace ? "/" + namespace : ""}`, {
                withCredentials: true,
                transports: ["websocket"],
                auth: {
                    token,
                },
                query: {
                    userRequestId: authService.getUserRequestId(),
                },
            })

            return new Promise((resolve, reject) => {
                socket.on("connect", () => {
                    resolve(socket)
                })
                socket.on("connect_error", (err) => {
                    socket.disconnect()
                    reject(err)
                })
            })
        })
    }

    getCancelToken() {
        return axios.CancelToken
    }

    isCancel(err) {
        return axios.isCancel(err)
    }

    getResponseError(err) {
        return get(err || {}, "response.data") || err
    }

    getResponseErrorFileUpload(err) {
        const error = get(err, "response.data") || err
        const errorStatus = err.status || error.status
        const errorCode = err.code || error.code
        let message

        switch (errorStatus) {
            case 413:
                message = `Total file size too large to upload. Make sure your internet connection allows it.`
                break
            case 502:
                message = `Failed to upload the files. If this issue persists please contact us. `
                break
            default:
        }

        if (errorCode === "LIMIT_FILE_SIZE") {
            message = `Individual file limit of ${FILE_UPLOAD_SIZE_LIMIT_MB}MB exceeded.`
        }

        if (errorCode === "LIMIT_FILE") {
            message = `Upload limit of ${FILE_UPLOAD_LIMIT} files at a time exceeded.`
        }

        return {
            ...error,
            message: message || error.message,
        }
    }

    _getUserToken(isPublic) {
        if (isPublic === true) {
            return Promise.resolve()
        }

        return authService
            .getCurrentUser()
            .then((user) => user.getIdToken())
            .catch(() => {})
    }
}

export default new Api()
