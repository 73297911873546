import api from "./api.service"

let listenerCallbackFnList = []
let statusSocket

const query = {
    // Interval time to receive socket status in ms
    // Minimum 2000
    ping: 5000,
}

export function hasAppReconnected(cb) {
    ;(async () => {
        try {
            let lastStatusDate = new Date()
            let socketOfflineMs = 0

            if (!statusSocket) {
                statusSocket = await api.connect("connection-status", {
                    query,
                })
            }

            const socketListenerCallbackFn = () => {
                const nowDate = new Date()

                if (lastStatusDate && nowDate - lastStatusDate > query.ping + 3000) {
                    socketOfflineMs = nowDate - lastStatusDate
                    cb(socketOfflineMs)
                } else if (socketOfflineMs) {
                    socketOfflineMs = 0
                }

                lastStatusDate = nowDate
            }

            listenerCallbackFnList.push(socketListenerCallbackFn)
            return statusSocket.on("status", socketListenerCallbackFn)
        } catch (err) {
            console.error(`Not listening for offline sockets. ${err}`)
        }
    })()
}

export function unmountAppReconnect() {
    try {
        listenerCallbackFnList.forEach((callbackFn) => {
            try {
                statusSocket.off("status", callbackFn)
            } catch (err) {
                // Nothing
            }
        })

        if (statusSocket && typeof statusSocket.close === "function") {
            statusSocket.close()
        }

        statusSocket = null
        listenerCallbackFnList = []
    } catch (err) {
        console.error(err)
    }
}
