import get from 'lodash/get';
import api from '../api.service';
const queryString = require('query-string');

class ActionApi {

    /**
     * @param planItemId
     * @param actionData
     * @param [options]
     * @return {Promise<*>}
     */
    createAction(planItemId, actionData, options = {}) {
        const qs = queryString.stringify(options || {});
        return api.post(`/mission-plan/${planItemId}/action?${qs}`, actionData)
            .then(res => res.data)
            .catch(err => {
                throw get(err || {}, 'response.data') || err
            })
    }

    updateAction(actionId, actionData) {
        return api.put(`/action/${actionId}`, actionData)
            .then(res => res.data)
            .catch(err => {
                throw get(err || {}, 'response.data') || err
            })
    }

    updateActionBulk(missionId, actions) {
        return api.put(`/action/${missionId}/bulk`, actions)
            .then(res => res.data)
            .catch(err => {
                throw get(err || {}, 'response.data') || err
            })
    }

    deleteAction(actionId) {
        return api.delete(`/action/${actionId}`)
            .then(() => ({}))
            .catch(err => {
                throw get(err || {}, 'response.data') || err
            })
    }

    addActionDependency(actionId, dependencyActionId) {
        return api.put(`/action/${actionId}/dependency/add`, {actionId: dependencyActionId})
            .then(res => res.data)
            .catch(err => {
                throw get(err || {}, 'response.data') || err
            })
    }

    removeActionDependency(actionId, dependencyActionId) {
        return api.put(`/action/${actionId}/dependency/remove`, {actionId: dependencyActionId})
            .then(res => res.data)
            .catch(err => {
                throw get(err || {}, 'response.data') || err
            })
    }

    /**
     * EXAMPLE:
     * apiAction.onChanges('update', data => console.log(data))
     * @param operation {string} - update, create or delete
     */
    async onChanges(operation, query, cb) {

        if (typeof query === 'function') {
            cb = query
            query = {}
        }

        const socket = await api.socket.connect(`changes/actions/${operation}`, {
            query
        })

        socket.on('changes', cb)
    }

}

export default new ActionApi();
