export const ROLES = {
    ADMIN: "Admin",
    MANAGER: "Manager",
    MEMBER: "Member",
}

export const RATE_TIME = {
    BIWEEKLY: "biweekly",
    MONTHLY: "monthly",
    YEARLY: "yearly",
}

export const INVITATION_STATUS = {
    PENDING: 0,
    ACCEPTED: 1,
    REJECTED: 2,
}

export const MISSION_PERMISSION = {
    MEMBER: 0,
    MANAGER: 1,
    ADMIN: 2,
    OBSERVER: 3,
    OBSERVER_ORG: 4, // Automatically added from org (admin, manager)
    OBSERVER_ORG_LIMITED: 5, // Automatically added from org (observer). Cannot open missions + no stripe usage
}

export const MISSION_PERMISSION_LABEL = {
    MEMBER: "Team member",
    MANAGER: "Manager",
    ADMIN: "Admin",
    OBSERVER: "Observer",
    OBSERVER_ORG: "Not needed on project",
}

export const MISSION_PERMISSION_LABEL_MAP = new Map([
    [MISSION_PERMISSION.MEMBER, MISSION_PERMISSION_LABEL.MEMBER],
    [MISSION_PERMISSION.MANAGER, MISSION_PERMISSION_LABEL.MANAGER],
    [MISSION_PERMISSION.ADMIN, MISSION_PERMISSION_LABEL.ADMIN],
    [MISSION_PERMISSION.OBSERVER, MISSION_PERMISSION_LABEL.OBSERVER],
    [MISSION_PERMISSION.OBSERVER_ORG, MISSION_PERMISSION_LABEL.OBSERVER_ORG],
])

export const MISSION_ADD_ON = {
    /**
     * First 1GB free, then €0.40/GB/month
     */
    STORAGE_PAY_AS_YOU_GO: "STORAGE_PAY_AS_YOU_GO",

    /**
     * First 5 people free, then €5.99/person/month
     */
    PEOPLE_PAY_AS_YOU_GO: "PEOPLE_PAY_AS_YOU_GO",
}
