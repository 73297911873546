import createHistory from "history/createBrowserHistory"
import pick from "lodash/pick"
const queryString = require("query-string")

const history = createHistory()

export function addParam(param, val, replace) {
    let q = queryString.parse(history.location.search)

    q[param] = val

    if (!replace) {
        history.push({
            search: queryString.stringify(q),
        })
    } else {
        history.replace(history.location.pathname + "?" + queryString.stringify(q))
    }
}
export function addParams(params, replace) {
    let q = queryString.parse(history.location.search)

    params.forEach((p) => {
        q[p.param] = p.value
    })

    if (replace) {
        history.replace({
            search: queryString.stringify(q),
        })
    } else {
        history.push({
            search: queryString.stringify(q),
        })
    }
}
export function removeParam(param, replace) {
    let q = queryString.parse(history.location.search)

    delete q[param]

    if (replace) {
        history.replace({
            search: queryString.stringify(q),
        })
    } else {
        history.push({
            search: queryString.stringify(q),
        })
    }
}
export function removeParams(stringParams, replace) {
    let params = stringParams.split(",")

    let q = queryString.parse(history.location.search)

    params.forEach((p) => {
        delete q[p]
    })

    if (replace) {
        history.replace({
            search: queryString.stringify(q),
        })
    } else {
        history.push({
            search: queryString.stringify(q),
        })
    }
}
export function getQ() {
    return queryString.parse(history.location.search)
}

/**
 * @param [key] {string|string[]} A single or multiple keys to return values for
 * @return {string|object|undefined}
 */
export function getHash(key) {
    let hash = history.location.hash
    const hashObj = hash
        ? hash
              .slice(1)
              .split("&")
              .reduce((result, item) => {
                  let parts = item.split("=")
                  result[parts[0]] = parts[1]
                  return result
              }, {})
        : null

    if (!hashObj) {
        return
    }

    if (typeof key === "string") {
        return hashObj[key]
    }

    if (Array.isArray(key)) {
        return pick(hashObj, key)
    }

    return hashObj
}

export default history
