import firebase from 'firebase/app';
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_apiKey,
    authDomain: process.env.REACT_APP_FIREBASE_authDomain,
    projectId: process.env.REACT_APP_FIREBASE_projectId,
    storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
    messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId
};

if (process.env.REACT_APP_FIREBASE_appId) {
    config.appId = process.env.REACT_APP_FIREBASE_appId;
}

if (process.env.REACT_APP_FIREBASE_databaseURL) {
    config.databaseURL = process.env.REACT_APP_FIREBASE_databaseURL;
}

firebase.initializeApp(config);

export default firebase;

/**
 * https://firebase.google.com/docs/auth/web/phone-auth#use-invisible-recaptcha
 * @param containerId {string} The ID of the UI element to contain the recaptcha. The corresponding element must be empty and in the DOM at the time of initialization.
 * @param [callback] {function} Optionally, listen to successful resolve
 * @returns {*} When resolves the reCAPTCHA was solved
 */
export const getFirebaseInvisibleRecaptcha = (containerId, callback = () => {}) => {
    return new firebase.auth.RecaptchaVerifier(containerId, {
        'size': 'invisible',
        'callback': callback
    });
}