import React from "react"

export default () => (
    <svg width="26px" height="20px" viewBox="0 0 26 20" className="sr-icon-speeder">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.596037946">
            <g transform="translate(-1208.000000, -183.000000)">
                <g transform="translate(1208.000000, 183.000000)">
                    <path
                        className="speed-dial"
                        d="M18,6 L12.7572995,11.2076968 C11.9232335,11.4866806 11.684929,12.4166265 12.5189949,12.8815994 C13.3530609,13.2535777 14.4254315,12.6956102 14.3062792,11.9516535 L18,6 Z"
                        id="Shape"
                    ></path>
                    <g>
                        <path
                            d="M0.102362205,11.9211823 C-1.38777878e-17,14.7783251 0.921259843,17.635468 2.86614173,20 L3.78740157,18.4236453 C2.35433071,16.5517241 1.74015748,14.1871921 1.84251969,11.9211823 L0.102362205,11.9211823 Z"
                            opacity="0.2"
                        ></path>
                        <path
                            d="M3.99212598,3.54679803 C1.84251969,5.51724138 0.511811024,8.07881773 0.204724409,10.8374384 L1.94488189,10.8374384 C2.35433071,8.4729064 3.48031496,6.40394089 5.22047244,4.72906404 L3.99212598,3.54679803 Z"
                            opacity="0.5"
                        ></path>
                        <path
                            d="M12.3858268,0 C9.72440945,0.0985221675 7.06299213,1.08374384 4.81102362,2.75862069 L6.03937008,3.9408867 C7.98425197,2.56157635 10.1338583,1.77339901 12.3858268,1.67487685 L12.3858268,0 Z"
                            opacity="0.8"
                        ></path>
                        <path
                            d="M21.1889764,2.75862069 C18.9370079,1.08374384 16.2755906,0.0985221675 13.6141732,0 L13.6141732,1.67487685 C15.8661417,1.77339901 18.015748,2.56157635 19.9606299,3.9408867 L21.1889764,2.75862069 Z"
                            opacity="1"
                        ></path>
                        <path
                            d="M25.7952756,10.8374384 C25.488189,8.1773399 24.1574803,5.51724138 22.007874,3.54679803 L20.7795276,4.72906404 C22.6220472,6.40394089 23.7480315,8.57142857 24.0551181,10.8374384 L25.7952756,10.8374384 Z"
                            opacity="1"
                        ></path>
                        <path d="M23.1338583,20 C25.0787402,17.635468 26,14.7783251 25.8976378,11.9211823 L24.1574803,11.9211823 C24.2598425,14.1871921 23.6456693,16.453202 22.2125984,18.4236453 L23.1338583,20 Z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
