import get from 'lodash/get';
import api from './api.service';
import queryString from 'query-string';
import {ERROR_SEVERITY} from './constants.service';
import isPlainObject from 'lodash/isPlainObject';

const StackTrace = require('stacktrace-js');

export async function getErrorStackMapped(error) {
    try {
        const stack = await StackTrace.fromError(error);
        const lines = [error.toString()];

        for (let s = 0; s < stack.length; s++) {
            lines.push([
                '    at ',
                stack[s].getFunctionName() || '<anonymous>', ' (',
                stack[s].getFileName(), ':',
                stack[s].getLineNumber(), ':',
                stack[s].getColumnNumber(), ')',
            ].join(''));
        }

        return lines.join('\n');
    } catch (err) {
        const errorStack = error?.stack;
        return errorStack ?? getErrorMessage(err);
    }
}

export function getErrorMessage(error) {

    if (!error || typeof error !== 'object') {
        return error;
    }

    try {
        return error.toString();
    } catch (err) {
        try {
            return error.message;
        } catch (err) {
            return 'Unknown Error';
        }
    }
}

export async function logError(error, data = {}, options = {}) {
    const stack = options.stack ?? await getErrorStackMapped(error);

    if (error && process.env.REACT_APP_DISABLE_LOGGING !== "true") {
        const qs = queryString.stringify({
            severity: options.severity
        });
        return api.post(`/log/mission-x?${qs}`, {
            userAgent: get(window, "navigator.userAgent"),
            url: get(window, "location.href"),
            message: getErrorMessage(error),
            error: stack,
            code: error.code,
            data
        })
    }
}

async function logItHandler(message, error, data, severity) {
    if (process.env.REACT_APP_DISABLE_LOGGING !== "true") {
        let errObj = {};

        if (error) {
            errObj = {
                message: getErrorMessage(error),
                stack: await getErrorStackMapped(error),
                code: error?.code
            };
        }

        const qs = queryString.stringify({
            severity
        });

        return api.post(`/log/mission-x?${qs}`, {
            userAgent: window?.navigator?.userAgent,
            url: window?.location?.href,
            error: errObj,
            message: message ?? errObj?.message ?? 'No message',
            ...(data ?? {})
        })
    }
}

export function isError(obj) {
    return Object.prototype.toString.call(obj) === "[object Error]";
}

export const logIt = {
    info: (message, data) => {
        if (typeof message === 'object') {
            data = message;
            data = null;
        }
        logItHandler(message, null, data, ERROR_SEVERITY.INFO).catch(() => {
        });
    },
    warning: (message, error, data) => {
        if (typeof message === 'object') {
            error = message;
            data = error;
            message = null;
        }
        if (!data && !isError(error)) {
            data = error;
            error = null;
        }
        logItHandler(message, error, data, ERROR_SEVERITY.WARNING).catch(() => {
        });
    },
    error: (error, data) => {
        if (!data && !isError(error)) {
            data = error;
            error = null;
        }
        logItHandler(null, error, data, ERROR_SEVERITY.ERROR).catch(() => {
        });
    },
    critical: (error, data) => {
        if (!data && !isError(error)) {
            data = error;
            error = null;
        }
        logItHandler(null, error, data, ERROR_SEVERITY.CRITICAL).catch(() => {
        });
    },
    debug: (message, error, data) => {
        if (typeof message === 'object') {
            error = message;
            data = error;
            message = null;
        }
        if (!data && !isError(error)) {
            data = error;
            error = null;
        }
        logItHandler(message, null, data, ERROR_SEVERITY.DEBUG).catch(() => {
        });
    },
    alert: (error, data) => {
        if (!data && !isError(error)) {
            data = error;
            error = null;
        }
        logItHandler(null, error, data, ERROR_SEVERITY.ALERT).catch(() => {
        });
    },
};