import _ from 'lodash';
import api from '../api.service';

const queryString = require('query-string');

class PlanItemsApi {

    /**
     * @param [options] {object}
     * @param [options.licensedOnly] {boolean} Defaults to true
     * @param [options.missionId] {string}
     * @param [options.startDate] {number}
     * @param [options.endDate] {number}
     * @returns {Promise<string>}
     */
    getMyRoles(options = {}) {
        options.licensedOnly = options.licensedOnly ?? true;
        const qs = queryString.stringify(options);
        return api.get(`/mission-plan/my/roles?${qs}`)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param planItemId
     * @param [options] {Object}
     * @param [options.limit] {String} The limit of results. Defaults to 100.
     * @param [options.page] {String} Paginate results to specified page, starting from page 1 as default.
     * @param [options.sort] {('asc'|'desc')} Sort either ascending or descending (by date). Defaults to desc.
     * @param [options.createdAtFrom] {Date|Number} Date range history was recorded
     * @param [options.createdAtTo] {Date|Number} Date range history was recorded
     * @returns {Promise<any>}
     */
    getHistory(planItemId, options = {}) {
        const qs = queryString.stringify(options);
        return api.get(`/mission-plan/${planItemId}/history?${qs}`)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param missionId
     * @param [options] {Object}
     * @param [options.limit] {String} The limit of results. Defaults to unlimited.
     * @param [options.page] {String} Paginate results to specified page, starting from page 1 as default.
     * @param [options.sort] {('asc'|'desc')} Sort either ascending or descending (by date). Defaults to desc.
     * @param [options.createdAtFrom] {Date|Number} Date range history was recorded
     * @param [options.createdAtTo] {Date|Number} Date range history was recorded
     * @returns {Promise<any>}
     */
    getHistoryByMission(missionId, options = {}) {
        const qs = queryString.stringify(options);
        return api.get(`/mission-plan/mission/${missionId}/history?${qs}`)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    createPlanItem(missionId, itemData) {
        return api.post(`/mission-plan/mission/${missionId}`, itemData)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param itemId {string}
     * @param itemData {object}
     * @param itemData.endDate {number}
     * @returns {Promise<* | void>}
     */
    changePlanItemPerson(itemId, itemData) {
        return api.put(`/mission-plan/${itemId}/change-person`, itemData)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    updatePlanItem(missionId, itemId, itemData) {
        return api.put(`/mission-plan/${itemId}`, itemData)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    updatePlanItemBulk(missionId, planItems) {
        return api.put(`/mission-plan/${missionId}/bulk`, planItems)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    deletePlanItem(itemId) {
        return api.delete(`/mission-plan/${itemId}`)
            .then(() => ({}))
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param planItemId {string}
     * @param linkId {string} Plan item id to link to
     * @param linkType {'dependsOn'|'dependsOnMe'|'related'|'startToStart1'|'startToStart2'}
     * @return {Promise<any>}
     */
    addDependency(planItemId, linkId, linkType = 'dependsOn') {
        return api
            .post(`/mission-plan/${planItemId}/dependency`, {
                linkId,
                linkType
            })
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param planItemId {string}
     * @param dependencyId {string} The link document id
     * @return {Promise<any>}
     */
    removeDependency(planItemId, dependencyId) {
        return api.delete(`/mission-plan/${planItemId}/dependency/${dependencyId}`)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param planItemId
     * @return {Promise<any>}
     */
    removeDependencyAll(planItemId) {
        return api.delete(`/mission-plan/${planItemId}/dependency/all`)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param missionId {String} Mission id
     * @param payload {Object[]}
     * @param payload[].missionPlanId {String} The plan item id
     * @param payload[].request {Object} The plan item request data
     * @param payload[].request.person {String} The request person id
     * @param payload[].request.hours {Number} The request hours
     * @return {Promise<*>}
     */
    submitRequest(missionId, payload) {
        return api.post(`/mission-plan/request/submit/${missionId}`, payload)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param orgId {String} Org id
     * @param payload {Object}
     * @param [payload.comment] {String} Optional comment that will apply to all approved requests (individual comments take presence over this)
     * @param payload.data {Object[]} Data for each individual request to approve.
     * @param payload.data[].missionPlanId {String} The plan item id
     * @param [payload.data[].requestId] {String} Optional request id to approve. If provided the request will be approved independently of status. Otherwise, only pending approval will be processed.
     * @param [payload.data[].comment] {String} Optional approval comment. This will take presence over the "payload.comment" field.
     * @return {Promise<*>}
     */
    approveRequests(orgId, payload) {
        return api.put(`/mission-plan/request/action/${orgId}/approved`, payload)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param orgId {String} Org id
     * @param payload {Object}
     * @param [payload.comment] {String} Optional comment that will apply to all rejected requests (individual comments take presence over this)
     * @param payload.data {Object[]} Data for each individual request to reject.
     * @param payload.data[].missionPlanId {String} The plan item id
     * @param [payload.data[].requestId] {String} Optional request id to reject. If provided the request will be rejected independently of status. Otherwise, only pending approval will be processed.
     * @param [payload.data[].comment] {String} Optional rejection comment. This will take presence over the "payload.comment" field.
     * @return {Promise<*>}
     */
    rejectRequests(orgId, payload) {
        return api.put(`/mission-plan/request/action/${orgId}/rejected`, payload)
            .then(res => res.data)
            .catch(err => {
                throw _.get(err || {}, 'response.data')
            })
    }

    /**
     * @param planItemId {string}
     * @param scheduleList {object|object[]} One or more schedules to add
     * @return {Promise<any>}
     */
    addSchedule(planItemId, scheduleList) {
        return api
            .post(`/mission-plan/${planItemId}/schedule`, scheduleList)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param planItemId {string}
     * @param scheduleList {object|object[]} One or more schedules to update
     * @param scheduleList[]._id {string} Each schedule item must include the _id along with the data to be updated
     * @return {Promise<any>}
     */
    updateSchedule(planItemId, scheduleList) {
        return api
            .put(`/mission-plan/${planItemId}/schedule`, scheduleList)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @param planItemId {string}
     * @param scheduleIdList {string|string[]} One or more schedule ids to delete
     * @return {Promise<any>}
     */
    deleteSchedule(planItemId, scheduleIdList) {
        return api
            .post(`/mission-plan/${planItemId}/schedule/delete`, scheduleIdList)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err)
            })
    }

    /**
     * EXAMPLE:
     * apiPlanItem.onChanges('update', data => console.log(data))
     * @param operation {string} - update, create or delete
     */
    async onChanges(operation, query, cb) {

        if (typeof query === 'function') {
            cb = query
            query = {}
        }

        const socket = await api.socket.connect(`changes/mission.plans/${operation}`, {
            query
        })

        socket.on('changes', cb)
    }

}

export default new PlanItemsApi();
