import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import api from "../api.service"

const queryString = require("query-string")

class ExpenseApi {
    /**
     * List my expenses
     *
     * @param [options] {object}
     * @param [options.orgId] {string}
     * @param [options.missionId] {string}
     * @param [options.includeArchived] {boolean} Defaults to false.
     * @return {Promise<*>}
     */
    getMyExpenses(options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/expense/my?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * List my expenses from all projects I am project manager or admin
     *
     * @param [options] {object}
     * @param [options.orgId] {string}
     * @param [options.missionId] {string}
     * @param [options.includeArchived] {boolean} Defaults to false.
     * @return {Promise<*>}
     */
    getProjectManagerExpenses(options = {}) {
        const qs = queryString.stringify(options)
        return api
            .get(`/expense/project-manager?${qs}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    // ORG EXPENSES

    /**
     * List all org expenses
     *
     * @param orgId {String}
     * @return {Promise<*>}
     */
    getExpensesOrg(orgId) {
        return api
            .get(`/expense/org/${orgId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Submit a new Org expense
     *
     * @param orgId {String}
     * @param data {Object}
     * @param data.expenseDate {number|Date}
     * @param data.amount {number}
     * @param [data.client] {string}
     * @param [data.code] {string}
     * @param [data.description] {string}
     * @param [data.currency] {string}
     * @param [data.tip] {string}
     * @param [data.tax1] {string}
     * @param [data.tax2] {string}
     * @param [data.taxRate] {number}
     * @param [data.expenseTemplateId] {string}
     * @param [data.noReceipt] {boolean}
     * @return {Promise<*>}
     */
    createExpenseOrg(orgId, data) {
        return api
            .post(`/expense/org/${orgId}`, {
                payload: data,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Update an Org expense
     *
     * @param orgId {String}
     * @param expenseId {String}
     * @param data {Object}
     * @param [data.expenseDate] {number|Date}
     * @param [data.amount] {number}
     * @param [data.client] {string}
     * @param [data.code] {string}
     * @param [data.description] {string}
     * @param [data.currency] {string}
     * @param [data.tip] {string}
     * @param [data.tax1] {string}
     * @param [data.tax2] {string}
     * @param [data.taxRate] {number}
     * @param [data.expenseTemplateId] {string}
     * @param [data.noReceipt] {boolean}
     * @return {Promise<*>}
     */
    updateExpenseOrg(orgId, expenseId, data) {
        return api
            .put(`/expense/org/${orgId}/expense/${expenseId}`, {
                payload: data,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Update an Org expense
     *
     * @param orgId {String}
     * @param expenseId {String}
     * @param data {Object}
     * @param data.status {'draft'|'submitted'|'approved'|'rejected'|'paid'}
     * @param [data.comment] {string}
     * @return {Promise<*>}
     */
    updateExpenseStatusOrg(orgId, expenseId, data) {
        return api
            .put(`/expense/org/${orgId}/expense/${expenseId}/status`, {
                payload: data,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @callback onUploadProgress
     * @param {Number} loaded Bytes that have been loaded
     * @param {Number} total The total upload bytes
     */
    /**
     * @param orgId {String}
     * @param expenseId {String}
     * @param files {File|Array<File>}
     * @param [options] {Object}
     * @param [options.onUploadProgress] {onUploadProgress} The progress of the upload
     * @return {Promise<*>}
     */
    addExpenseAttachmentOrg(orgId, expenseId, files, options = {}) {
        return api
            .uploadFileMulti(`/expense/org/${orgId}/expense/${expenseId}/attachment`, files, options)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    removeExpenseAttachmentOrg(orgId, expenseId, attachmentId) {
        return api
            .delete(`/expense/org/${orgId}/expense/${expenseId}/attachment/${attachmentId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    /**
     * DELETE an Org expense
     *
     * @param orgId {String}
     * @param expenseId {String}
     * @return {Promise<*>}
     */
    deleteExpenseOrg(orgId, expenseId) {
        return api
            .delete(`/expense/org/${orgId}/expense/${expenseId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    // MISSION EXPENSES

    /**
     * List all org's mission expenses
     *
     * @param orgId {String}
     * @return {Promise<*>}
     */
    getExpensesOrgMission(orgId) {
        return api
            .get(`/expense/org/${orgId}/mission`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * List all mission expenses
     *
     * @param missionId {String}
     * @return {Promise<*>}
     */
    getExpensesMission(missionId) {
        return api
            .get(`/expense/mission/${missionId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Submit a new Org expense
     *
     * @param missionId {String}
     * @param data {Object}
     * @param data.expenseDate {number|Date}
     * @param data.amount {number}
     * @param [data.code] {string}
     * @param [data.description] {string}
     * @param [data.currency] {string}
     * @param [data.tip] {string}
     * @param [data.tax1] {string}
     * @param [data.tax2] {string}
     * @param [data.taxRate] {number}
     * @param [data.expenseTemplateId] {string}
     * @param [data.noReceipt] {boolean}
     * @return {Promise<*>}
     */
    createExpenseMission(missionId, data) {
        return api
            .post(`/expense/mission/${missionId}`, {
                payload: data,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Update an Org expense
     *
     * @param expenseId {String}
     * @param data {Object}
     * @param [data.expenseDate] {number|Date}
     * @param [data.amount] {number}
     * @param [data.code] {string}
     * @param [data.description] {string}
     * @param [data.currency] {string}
     * @param [data.tip] {string}
     * @param [data.tax1] {string}
     * @param [data.tax2] {string}
     * @param [data.taxRate] {number}
     * @param [data.expenseTemplateId] {string}
     * @param [data.noReceipt] {boolean}
     * @return {Promise<*>}
     */
    updateExpenseMission(expenseId, data) {
        return api
            .put(`/expense/mission/expense/${expenseId}`, {
                payload: data,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * Update an Org expense
     *
     * @param expenseId {String}
     * @param data {Object}
     * @param data.status {'draft'|'submitted'|'approved'|'rejected'|'paid'}
     * @param [data.comment] {string}
     * @return {Promise<*>}
     */
    updateExpenseStatusMission(expenseId, data) {
        return api
            .put(`/expense/mission/expense/${expenseId}/status`, {
                payload: data,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }

    /**
     * @callback onUploadProgress
     * @param {Number} loaded Bytes that have been loaded
     * @param {Number} total The total upload bytes
     */
    /**
     * @param expenseId {String}
     * @param files {File|Array<File>}
     * @param [options] {Object}
     * @param [options.onUploadProgress] {onUploadProgress} The progress of the upload
     * @return {Promise<*>}
     */
    addExpenseAttachmentMission(expenseId, files, options = {}) {
        return api
            .uploadFileMulti(`/expense/mission/expense/${expenseId}/attachment`, files, options)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    removeExpenseAttachmentMission(expenseId, attachmentId) {
        return api
            .delete(`/expense/mission/expense/${expenseId}/attachment/${attachmentId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw get(err || {}, "response.data") || err
            })
    }

    /**
     * @param expenseId {String}
     * @return {Promise<*>}
     */
    deleteExpenseMission(expenseId) {
        return api
            .delete(`/expense/mission/expense/${expenseId}`)
            .then((res) => res.data)
            .catch((err) => {
                throw api.getResponseError(err)
            })
    }
}

export default new ExpenseApi()
