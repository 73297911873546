export const QUICKBOOKS_PRODUCT = {
    ONLINE: 'online',
    TIME: 'time',
};

export const QUICKBOOKS_ONLINE_SCOPE = {
    ACCOUNTING: 'com.intuit.quickbooks.accounting',
    PAYMENT: 'com.intuit.quickbooks.payment',
    // TIME_TRACKING: 'com.intuit.quickbooks.payroll.timetracking', // TODO: Do we need this? If client doesn't have this enabled, they will get a vague error when trying to authenticate
    // PAYROLL: 'com.intuit.quickbooks.payroll',
};

export const QUICKBOOKS_ONLINE_SCOPE_LIST = [
    {
        name: 'Accounting',
        value: QUICKBOOKS_ONLINE_SCOPE.ACCOUNTING,
        description: 'Grants access to accounting data.',
        apiUrl: 'https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/account'
    },
    {
        name: 'Payment',
        value: QUICKBOOKS_ONLINE_SCOPE.PAYMENT,
        description: 'Grants access to payments processing.',
        apiUrl: 'https://developer.intuit.com/app/developer/qbpayments/docs/api/resources/all-entities/bankaccounts'

    },
    {
        name: 'Time Tracking',
        value: QUICKBOOKS_ONLINE_SCOPE.TIME_TRACKING,
        description: 'Grants access to the time activity of a vendor or employee.',
        apiUrl: 'https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/timeactivity',
        requires: [
            QUICKBOOKS_ONLINE_SCOPE.PAYROLL
        ],
    }
    // Payroll not supported?
    // https://help.developer.intuit.com/s/question/0D54R00008YZsprSAD/is-the-payroll-api-available-how-does-it-take-timesheets-and-map-to-the-appropriate-payroll-item-for-instance-hourly-wage-overtime-vacation-sick-salary-commissions-and-bonuses
    // {
    //     name: 'Payroll',
    //     value: QUICKBOOKS_ONLINE_SCOPE.PAYROLL,
    //     description: 'Grants access to accounting data.',
    // },
];