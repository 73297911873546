import { getRefId, ensureNumber } from "./utils"
import { eachDayOfInterval } from "date-fns/eachDayOfInterval"
import isWeekend from "date-fns/isWeekend"
import { isSameDay } from "date-fns/isSameDay"
import { isAfter } from "date-fns/isAfter"
import format from "date-fns/format"
import { endOfDay } from "date-fns/endOfDay"
import numeral from "numeral"

import { isSameYear } from "date-fns/isSameYear"
import { getLeaveDate } from "../comp/PeopleLeaveUtils"
import { toZonedTime } from "date-fns-tz"
import { getAllStatDays, formulas } from "../utils/holidays"
import axios from "axios"

export const getAmountOfLeave = ({ leave, holidays, orgData, inDays, year }) => {
    let hours = 0

    if (leave.hours?.length) {
        hours = leave.hours
            .filter((h) => (year ? isSameYear(toZonedTime(h.date), year) : true))
            .reduce((cumm, h) => (cumm += h.hours), 0)
    } else {
        const st = getLeaveDate(leave.dates[0].from)
        const ed = getLeaveDate(leave.dates[0].to)

        eachDayOfInterval({
            start: st,
            end: ed,
        }).forEach((d, i) => {
            if (year && !isSameYear(d, year)) {
                return
            }
            let isHoliday

            if (holidays[`${orgData.holidayCountryCode}-${format(d, "d-MMM-yyyy")}`]) {
                isHoliday = true
            }

            if (!isHoliday && !isWeekend(d)) {
                hours += 8
            }
        })
    }

    if (inDays) {
        return hours / 8
    }
    return hours
}

async function getCountryHoliday(countryCodes, years) {
    if (!Array.isArray(countryCodes)) {
        countryCodes = [countryCodes]
    }
    if (!Array.isArray(years)) {
        years = [years]
    }

    const requests = []

    countryCodes.forEach((countryCode, i) => {
        years.forEach((y, i) => {
            requests.push({
                year: y,
                countryCode,
            })
        })
    })

    const holidayResponse = await Promise.allSettled(
        requests.map((r) => {
            return axios.get(`https://date.nager.at/api/v3/PublicHolidays/${r.year}/${r.countryCode}`)
        })
    )

    let gottenHolidays = []

    holidayResponse.forEach((r) => {
        if (r.value.data?.length) {
            gottenHolidays = [...gottenHolidays, ...r.value.data]
        }
    })

    return gottenHolidays.filter((d) => d.global)
}

export async function getCountryHolidays(countryCodes, years) {
    if (!Array.isArray(countryCodes)) {
        countryCodes = [countryCodes]
    }
    if (!Array.isArray(years)) {
        years = [years]
    }

    let map = []

    if (years.length === 2 && years[0] === years[1]) {
        years = [years[0]]
    }

    years.forEach((y, i) => {
        countryCodes.forEach((c, i) => {
            map.push({ year: y, countryCode: c })
        })
    })

    const hResults = await Promise.allSettled(
        map.map((m) => {
            return getCountryHoliday(m.countryCode, m.year)
        })
    )

    let holidays = []

    hResults.forEach((result, i) => {
        if (result.status === "fulfilled") {
            if (result.value.length) {
                holidays = [...holidays, ...result.value]
            }
        }
    })

    return holidays.reduce((groups, item) => {
        const key1 = item["countryCode"]
        const key2 = format(new Date(item["date"]), "d-MMM-yyyy")
        const groupKey = `${key1}-${key2}`

        if (!groups[groupKey]) {
            groups[groupKey] = []
        }

        groups[groupKey].push(item)
        return groups
    }, {})
}

export function updateStateLeaves({ leaves = [], how, data }) {
    let newLeaves = leaves.slice()
    switch (how) {
        case "set":
            newLeaves = data
            break
        case "add":
            newLeaves.push(data)
            break
        case "push":
            newLeaves.push(data)
            break
        case "merge":
            newLeaves = [...leaves, ...data]
            break
        case "delete":
            const existinIndex = newLeaves.findIndex((ts) => ts._id === data._id)
            if (existinIndex !== -1) {
                newLeaves.splice(existinIndex, 1)
            }
            break
        case "update":
            const existinIndex2 = newLeaves.findIndex((ts) => ts._id === data._id)

            if (existinIndex2 !== -1) {
                newLeaves[existinIndex2] = { ...newLeaves[existinIndex2], ...data }
            }
            break

        default:
    }

    return newLeaves
}

//Person has to be an org person
export function personLeaveDetails({ person, leaves, year, orgData, holidays }) {
    if (!year) {
        throw new Error("Please provide year dc-31221-year")
    }

    if (!orgData.people.find((p) => getRefId(p) === getRefId(person))) {
        return {}
    }

    const myLeaveAmounts = person.leaveAmounts ? JSON.parse(person.leaveAmounts) : {}

    const myLeaves = leaves.filter((l) => {
        if (!l.dates[0] || !l.dates[0].from || !l.dates[0].to) return false

        const d = year === getLeaveDate(l.dates[0].from).getFullYear()
        const e = year === getLeaveDate(l.dates[0].to).getFullYear()

        return l.createdBy === getRefId(person) && (d || e)
    })

    let leaveDetails = {}

    //let leaveTypes = groupBy(myLeaves, "reason")

    const getDataForYear = (lc, y) => {
        const leavesOfType = myLeaves.filter((l) => l.reason === lc.id)

        const odd = isLeaveCodeOdd(lc)

        let approvedHours = 0
        let pendingHours = 0

        leavesOfType
            .filter((l) => l.status === "approved")
            .forEach((leave, i) => {
                approvedHours += getAmountOfLeave({
                    leave,
                    holidays: holidays,
                    orgData: orgData,
                })
            })

        leavesOfType
            .filter((l) => l.status === "pending_approval" || l.status === "pending_final_approval")
            .forEach((leave, i) => {
                pendingHours += getAmountOfLeave({
                    leave,
                    holidays: holidays,
                    orgData: orgData,
                })
            })

        let entitledHours
        //

        if (lc.id === "annual-leave" && formulas[orgData.holidayCountryCode]) {
            const theCalculation = formulas[orgData.holidayCountryCode]

            if (theCalculation) {
                const orgPerson = orgData.people.find((p) => getRefId(p) === getRefId(person))

                const entitledDays = theCalculation({ year: y, orgData, person: orgPerson, holidays })

                entitledHours = Math.round(entitledDays) * 8
            }
        } else {
            const dAmount = orgData.leaveCodes.find((l) => l.id === lc.id)?.days?.find((d) => d.year === y)?.amount
            let defaultLeaveAmount = dAmount === null || dAmount === undefined ? undefined : dAmount * (odd ? 1 : 8)
            let myAssignedDays = myLeaveAmounts[lc.id] ? myLeaveAmounts[lc.id][y] : 0

            entitledHours = myAssignedDays || defaultLeaveAmount

            if (odd) {
                if (lc.maxHours && lc.timesPerYear) {
                    defaultLeaveAmount = lc.maxHours * lc.timesPerYear
                    entitledHours = myAssignedDays || defaultLeaveAmount
                }
            }
        }

        let approvedBalanceLeft =
            entitledHours >= 0 && entitledHours !== null ? entitledHours - approvedHours : undefined
        let balanceLeft =
            entitledHours >= 0 && entitledHours !== null ? entitledHours - approvedHours - pendingHours : undefined

        let balanceHours = balanceLeft

        if (balanceLeft >= 0 && balanceLeft !== null && !odd) {
            balanceLeft = balanceLeft / 8
        }

        return {
            entitled: entitledHours >= 0 && entitledHours !== null ? roundToTwo(entitledHours) : undefined,
            entitledHours: entitledHours >= 0 && entitledHours !== null ? roundToTwo(entitledHours) : undefined,
            approvedHours: approvedHours || 0,
            approvedBalanceLeft: approvedBalanceLeft ? roundToTwo(approvedBalanceLeft) : approvedBalanceLeft,
            pendingHours: pendingHours || 0,
            balanceLeft: balanceLeft ? roundToTwo(balanceLeft) : balanceLeft,
            balanceHours: balanceHours ? roundToTwo(balanceHours) : balanceHours,
            balanceDays: balanceHours ? roundToTwo(balanceHours / 8) : undefined,
            inHours: odd,
        }
    }

    orgData.leaveCodes.forEach((lc, i) => {
        const lcData = getDataForYear(lc, year)
        let carryAmountData = null
        let lastYearsAmount = 0

        if (lc.id === "annual-leave") {
            if (orgData.carryOverAnnualLeaveCutOff && year === new Date().getFullYear()) {
                if (orgData.carryOverAnnualLeaveCutOff) {
                    const monthDay = orgData.carryOverAnnualLeaveCutOff.split("-").map((d) => +d)
                    const date = new Date(year, monthDay[0] - 1, monthDay[1])

                    if (isAfter(new Date(), date)) {
                        //do nothing
                    } else {
                        carryAmountData = getDataForYear(lc, year - 1)
                    }
                } else {
                    carryAmountData = getDataForYear(lc, year - 1)
                }
            }
        }

        if (carryAmountData && (orgData.carryOverAnnualLeaveAmount || orgData.carryOverAnnualLeaveFx)) {
            if (orgData.carryOverAnnualLeaveFx === "percent") {
                lastYearsAmount = carryAmountData.balanceLeft * (orgData.carryOverAnnualLeaveAmount / 100)
            } else {
                lastYearsAmount =
                    orgData.carryOverAnnualLeaveAmount > carryAmountData.balanceLeft
                        ? carryAmountData.balanceLeft
                        : orgData.carryOverAnnualLeaveAmount
            }
        }

        const numberOfRequestsMade = leaves.filter(
            (l) =>
                l.status !== "cancelled" &&
                l.status !== "rejected" &&
                l.reason === lc.id &&
                (getLeaveDate(l.dates[0].from).getFullYear() === year ||
                    getLeaveDate(l.dates[0].to).getFullYear() === year)
        ).length

        const overBalance =
            (lcData.balanceHours >= 0 || lcData.balanceHours <= 0) && lastYearsAmount >= 0
                ? ensureNumber(lcData.balanceHours) + ensureNumber(lastYearsAmount) <= 0
                : false

        let balanceInText = ""

        if (lcData.entitledHours)
            balanceInText = formatLeaveAmount(lc, ensureNumber(lcData.balanceHours) + ensureNumber(lastYearsAmount))

        leaveDetails[lc.id] = {
            timesPerYear: lc.timesPerYear,
            entitled: formatLeaveAmount(lc, lcData.entitled),
            approved: lcData.approvedHours === 0 ? "" : formatLeaveAmount(lc, lcData.approvedHours),
            approvedHours: lcData.approvedHours,
            pending: lcData.pendingHours === 0 ? "" : formatLeaveAmount(lc, lcData.pendingHours),
            carryOver: formatLeaveAmount(lc, lastYearsAmount),
            balanceAmount: lcData.balanceLeft,
            entitledHours: lcData.entitledHours,
            balanceHours: lcData.balanceHours,
            balanceDays: lcData.balanceDays,
            inHours: lcData.inHours,
            approvedBalanceLeft: lcData.approvedBalanceLeft
                ? formatLeaveAmount(lc, lcData.approvedBalanceLeft)
                : undefined,
            requestsMade: numberOfRequestsMade,
            balance: balanceInText,
            overBalance: overBalance,
        }
    })

    return leaveDetails
}

export const formatLeaveTotal = ({ leave, orgData }) => {
    const lc = orgData.leaveCodes.find((l) => l.id === leave.reason)

    const from = getLeaveDate(leave.dates[0].from)
    const to = endOfDay(getLeaveDate(leave.dates[0].to))

    let amount = 0
    if (leave.hours?.length) {
        amount = leave.hours.reduce((cumm, h) => (cumm += ensureNumber(h.hours)), 0)
    } else {
        eachDayOfInterval({
            start: from,
            end: to,
        }).forEach((day, i) => {
            amount += 8
        })
    }

    if (isLeaveCodeOdd(lc)) {
        return amount + "/hr" + (amount === 1 ? "" : "s")
    } else if (lc) {
        const h = amount / 8
        return numeral(h).format(h % 1 ? "0.0" : "0") + "/day" + (h === 1 ? "" : "s")
    } else {
        const c = amount / 8
        return amount / 8 + "/day" + (c === 1 ? "" : "s")
    }
}

export const isLeaveCodeOdd = (lc) => {
    if (!lc) {
        return false
    }
    return Boolean((lc.minHours && lc.minHours % 4) || (lc.maxHours && lc.maxHours % 4))
}
export const formatLeaveAmount = (lc, amount) => {
    if (amount === null || amount === undefined || isNaN(amount)) {
        return "N/A"
    }

    const isHours = isLeaveCodeOdd(lc)

    return amount && isHours
        ? amount + "/hrs"
        : amount === undefined
        ? "N/A"
        : isHours && amount < 8
        ? amount + "/hrs"
        : amount / 8 + "/days"
}

export function getCleanLeaveDays({ leaves, orgData, year }) {
    let statDays = []
    if (orgData.holidayCountryCode) {
        statDays = getAllStatDays(orgData.holidayCountryCode)
    }

    let total = 0

    leaves.forEach((leave, i) => {
        eachDayOfInterval({
            start: getLeaveDate(leave.dates[0].from),
            end: getLeaveDate(leave.dates[0].to),
        }).forEach((d, i) => {
            if (!isSameYear(d, new Date(year + ""))) {
                return
            }
            if (!isWeekend(d) && !statDays?.find((sd) => isSameDay(sd, d))) {
                total++
            }
        })
    })

    return total
}

function roundToTwo(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100
}
