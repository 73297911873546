import React, { useState, useEffect, useCallback, useRef } from "react"

import {
    PiClockCountdownDuotone,
    PiWarningDuotone,
    PiTriangleDuotone,
    PiCheckCircleDuotone,
    PiPushPinDuotone,
    PiEyeDuotone,
    PiEyeClosedDuotone,
} from "react-icons/pi"
import SearchDropDown from "./SearchDropDown"
import { toZonedTime } from "date-fns-tz"
import { getPersonName, getRefId, sortAlpha, getObjectId } from "../utils/utils"
import { findAiEndDate, getTimeUtc, getStartHour } from "../utils/dates"
import { isOverdue, actionItemPermissions, getAiCode } from "../utils/actionItem"
import { getAllActions } from "../comp/MissionUtils"
import format from "date-fns/format"
import Tip from "./Tip"
import { CheckBox } from "../comp/CheckBoxes"
import Button from "./Button"
import Hive from "./Hive"
import flatMap from "lodash/flatMap"
import uniqBy from "lodash/uniqBy"
import { isFriday } from "date-fns/isFriday"
import { isSameDay } from "date-fns/isSameDay"
import { eachDayOfInterval } from "date-fns/eachDayOfInterval"
import { endOfISOWeek } from "date-fns/endOfISOWeek"
import { nextFriday } from "date-fns/nextFriday"
import { isWeekend } from "date-fns/isWeekend"
import { addWeeks } from "date-fns/addWeeks"
import { startOfISOWeek } from "date-fns/startOfISOWeek"
import addDays from "date-fns/addDays"
import startOfDay from "date-fns/startOfDay"
import isSameISOWeek from "date-fns/isSameISOWeek"
import cx from "classnames"
import { addParam } from "./History"
import "ag-grid-enterprise"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { LicenseManager } from "ag-grid-enterprise"
import "../scss/DnaAgGrid.scss"
import "./TaskMaster.scss"

LicenseManager.setLicenseKey(
    "CompanyName=Mission-X LTD,LicensedApplication=Mission-X,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-033160,SupportServicesEnd=22_September_2023_[v2]_MTY5NTMzNzIwMDAwMA==e2181c44967b4c010344ad9c5f7af912"
)

const TaskMaster = ({ app, stateChange }) => {
    let presets = localStorage.getItem(`x-tm-filters-${app.state.person._id}`)

    if (presets) {
        try {
            presets = JSON.parse(presets)
        } catch (e) {
            presets = {}
        }
    }

    const gridRef = useRef()
    const peopleRef = useRef([])

    const missionRef = useRef(
        Object.fromEntries(app.state.missions.filter((m) => !m.isTemplate).map((item) => [item._id, item]))
    )
    const orgRef = useRef(Object.fromEntries(app.state.orgs.map((item) => [item._id, item])))
    const pinnedRef = useRef()
    const [columnDefs, setColumnDefs] = useState([])
    const [rowData, setRowData] = useState([])
    const [orgFilter, setOrgFilter] = useState(presets?.orgFilter)
    const [hideDone, setHideDone] = useState(presets?.hideDone)
    const [missionFilter, setMissionFilter] = useState(presets?.missionFilter)
    const [showPinned, setShowPinned] = useState(presets?.showPinned)
    const [personFilter, setPersonFilter] = useState(app.state.person._id)
    const [people, setPeople] = useState([])
    const [statusFilter, setStatusFilter] = useState(presets?.statusFilter)

    //Grid
    const getRowId = useCallback((params) => {
        return params?.data._id
    }, [])

    const getRowHeight = useCallback((params) => {
        return 140
    }, [])

    const isExternalFilterPresent = useCallback(() => {
        return true
    }, [])

    const doesExternalFilterPass = useCallback(
        (node) => {
            let isStatusOk = !statusFilter || node.data.status === statusFilter.id

            if (statusFilter && node.data?.ai) {
                const ai = node.data?.ai

                if (statusFilter && statusFilter.id === "overdue" && node.data?.isOverdue) {
                    isStatusOk = true
                } else if (statusFilter && statusFilter.id === "blocked" && node.data?.ai?.status === "blocked") {
                    isStatusOk = true
                } else if (statusFilter && statusFilter.id === "cr-open") {
                    isStatusOk =
                        node.data?.ai?.approvals?.find((a) => !a.cancelledOn && !a.approvedOn) && ai.status !== "done"
                } else if (statusFilter && statusFilter.id === "cr-approved") {
                    isStatusOk = node.data?.ai?.approvals?.find((a) => a.approvedOn)
                } else if (statusFilter.id === "due-today" && ai.status !== "done") {
                    isStatusOk = node.data.dueDate && isSameDay(node.data.dueDate, new Date())
                } else if (statusFilter.id === "due-tomorrow" && ai.status !== "done") {
                    isStatusOk = node.data.dueDate && isSameDay(node.data.dueDate, addDays(new Date(), 1))
                } else if (statusFilter.id === "due-this-week" && ai.status !== "done") {
                    isStatusOk = node.data.dueDate && isSameISOWeek(node.data.dueDate, new Date())
                }
            }

            let personOk = !personFilter || node.data?.ai?.people?.includes(personFilter)

            if (personFilter === "unassigned") {
                personOk = !node.data?.ai?.people?.length && node.data && node.data.ai && node.data.ai.status !== "done"
            }

            let isMissionOk = !missionFilter || missionFilter?._id === node.data.ai.missionId

            const mission = missionRef.current[node.data?.ai?.missionId]
            const org = mission ? orgRef.current[getObjectId(mission.org)] : null

            const isOrgOk = !orgFilter || getObjectId(org) === orgFilter._id

            const isPinnedOk = !showPinned || node.data.pinned

            let isDoneOk = true
            if (hideDone) {
                isDoneOk = node.data?.ai.status !== "done"
            }

            return isStatusOk && isMissionOk && personOk && isOrgOk && isDoneOk && isPinnedOk
        },
        [personFilter, missionFilter, statusFilter, orgFilter, hideDone, showPinned]
    )

    useEffect(() => {
        gridRef.current?.api?.onFilterChanged()

        localStorage.setItem(
            `x-tm-filters-${app.state.person._id}`,
            JSON.stringify({
                personFilter,
                missionFilter,
                statusFilter,
                orgFilter,
                hideDone,
                showPinned,
            })
        )
    }, [personFilter, missionFilter, statusFilter, orgFilter, hideDone, showPinned])

    useEffect(() => {
        setColumnDefs([
            {
                field: "pinned",
                headerName: "",
                maxWidth: 25,
                suppressMovable: true,
                cellStyle: { paddingRight: 0, cursor: "pointer" },
                cellRenderer: (params) => {
                    return params.value ? (
                        <PiPushPinDuotone color="orange" />
                    ) : (
                        <PiPushPinDuotone style={{ opacity: 0.2 }} />
                    )
                },
            },
            {
                field: "taskId",
                maxWidth: 60,
                hide: true,
                suppressMovable: true,
            },
            {
                field: "personName",
                hide: true,
                suppressMovable: true,
            },
            {
                field: "taskTitle",
                headerName: "Action items",
                flex: 1,
                minWidth: 390,
                suppressMovable: true,
                cellStyle: {
                    fontWeight: "normal",
                    fontSize: 14,
                    letterSpacing: 0.3,
                },
                cellRenderer: TaskCell,
                cellRendererParams: {
                    orgRef,
                    missionRef,
                    app,
                },
            },

            {
                field: "mission",
                suppressMovable: true,
                flex: 1,
                hide: true,
                minWidth: 240,
            },

            {
                field: "seen",
                suppressMovable: true,
                headerName: "Last viewed",

                maxWidth: 126,
                minWidth: 126,
                cellRenderer: (params) => {
                    return params.data?.seen ? (
                        formatDistanceToNowStrict(toZonedTime(params.data.seen)) + " ago"
                    ) : (
                        <span>Not seen</span>
                    )
                },
                sortable: true,
            },
            {
                field: "dueDate",
                headerName: "Due date",
                suppressMovable: true,
                maxWidth: 130,
                cellRenderer: (params) => {
                    return params.data?.dueDate ? format(params.data.dueDate, "EEEEEE. MMM dd, yyyy") : <></>
                },
                sortable: true,
            },
            {
                field: "process",
                suppressMovable: true,
                headerName: "Progress status",
            },
            {
                field: "status",
                headerName: "Status",
                suppressMovable: true,
                maxWidth: 120,
                cellRenderer: (params) => {
                    return (
                        <>
                            {params.data?.ai?.status === "blocked" && (
                                <div className="dna-pill-box red-pill ">
                                    <PiTriangleDuotone color="pink" /> Blocked
                                </div>
                            )}
                            {params.data?.isOverdue === 1 && (
                                <div className="dna-pill-box orange-pill ">
                                    <PiClockCountdownDuotone color="yellow" /> Overdue
                                </div>
                            )}
                            {params.data.ai.status === "done" && (
                                <div className="dna-pill-box green-pill">
                                    <PiCheckCircleDuotone color="green" /> Done
                                </div>
                            )}
                        </>
                    )
                },
            },
            {
                field: "overdue",
                suppressMovable: true,
                hide: true,
            },
            {
                field: "blocked",
                suppressMovable: true,
                hide: true,
            },
        ])
    }, [stateChange?.timestamp])

    useEffect(() => {
        let peeps = uniqBy(
            flatMap(
                app.state.missions.filter((m) => !m.isTemplate),
                "people"
            ).filter((p) => p.permission < 3 && p.ref),
            "ref._id"
        )
        peopleRef.current = Object.fromEntries(peeps.map((item) => [getRefId(item), item.ref]))

        setPeople(peeps.map((p) => ({ ...p, personName: getPersonName(p) })))
    }, [app.state.missions?.length])

    const defaultColDef = {
        suppressMenu: true,
        flex: 1,
        cellClass: (params) => {
            return params.data?.ai.status === "done" ? "x-tm-done" : undefined
        },
    }

    useEffect(() => {
        const grd = getData(app, missionRef, personFilter)

        setRowData(grd.data)
    }, [stateChange.timestamp, personFilter])

    const onCellClicked = useCallback((params) => {
        if (params.colDef.field === "pinned") {
            let newPins = params.data.ai.pinned?.slice() || []
            const pinnedIndex = (params.data.ai.pinned || [])?.findIndex((p) => p.by === app.state.person._id)
            if (pinnedIndex === -1) {
                newPins.push({
                    by: app.state.person._id,
                    when: getTimeUtc(new Date()),
                })
            } else {
                newPins.splice(pinnedIndex, 1)
            }

            app.actionItemUpdateX(params.data.ai, {
                pinned: newPins,
            })
        } else if (params.colDef.field === "mission") {
            addParam("mission", params.data.ai.missionId)
        } else {
            app.showActionItemDetails(params.data.ai)
        }
    }, [])

    const canAddTask = () => {
        if (missionFilter && !missionFilter.isTemplate && !missionFilter.isModel && !missionFilter.isProcess) {
            if (missionFilter.projectType === "kanban" || missionFilter.projectType === "simple") {
                const meInMission = missionFilter.people.find((p) => getRefId(p) === app.state.person._id)
                if (meInMission.permission > 0 && meInMission.permission < 3) {
                    return true
                }
            }
        }
        return false
    }

    if (app.state.missions.filter((m) => !m.isTemplate).length === 0) {
        return (
            <div className="x-task-master">
                <div className="x-tm-intro">
                    <h3 style={{ marginLeft: 0 }}>
                        Welcome <span className="light-span">young master</span>
                    </h3>

                    <p style={{ maxWidth: 420 }}>
                        When you create or join projects and begin working on tasks they will appear here for you to
                        command and control.
                    </p>

                    <p className="w-400">
                        For now, please start your mission and create a project. (top left of the site)
                    </p>

                    <img src="/img/astro-zen-x.png" style={{ width: 180, transform: "translate(380px,20px)" }} />
                </div>
            </div>
        )
    }

    return (
        <div className="x-task-master">
            <div className="x-dna-filters">
                <div className="x-dna-filters dna-flex">
                    <SearchDropDown
                        data={[
                            { personName: "My action items", _id: "mine" },
                            { personName: "Pinned items", _id: "mine-pinned" },
                            { personName: "Unassigned", _id: "unassigned" },
                            ...sortAlpha(people || [], "personName"),
                        ]}
                        value={
                            !personFilter
                                ? ""
                                : personFilter === app.state.person._id && showPinned
                                ? "My Pinned tasks"
                                : personFilter === app.state.person._id
                                ? "My action items"
                                : personFilter === "unassigned"
                                ? "Unassigned open tasks"
                                : getPersonName(peopleRef.current[personFilter])
                        }
                        wrapperClassName={cx("w-180", { "x-f-red": personFilter })}
                        labelKey={"personName"}
                        menuWidth={270}
                        placeHolder={"Everyone's tasks..."}
                        showClearIcon={true}
                        noAlpha={true}
                        onSelect={(label, obj) => {
                            if (!obj) return

                            if (obj?._id === "mine" || obj?._id === "mine-pinned") {
                                setPersonFilter(app.state.person._id)

                                if (obj?._id === "mine-pinned") {
                                    setShowPinned(true)
                                } else {
                                    setShowPinned(false)
                                }
                            } else if (obj?._id === "unassigned") {
                                setShowPinned(false)
                                setPersonFilter("unassigned")
                            } else {
                                setPersonFilter(getRefId(obj))
                                setShowPinned(false)
                            }
                        }}
                        onClear={() => {
                            setPersonFilter(null)
                            setShowPinned(false)
                        }}
                    />{" "}
                    &nbsp;{" "}
                    <input
                        type="text"
                        className="w-120"
                        placeHolder="Quick find..."
                        onChange={(e) => {
                            gridRef.current.api.setQuickFilter(e.target.value)
                        }}
                    />
                    &nbsp;
                    {app.state.orgs.length > 1 && (
                        <>
                            <SearchDropDown
                                data={app.state.orgs}
                                wrapperClassName={cx("w-150", { "x-f-orange": orgFilter })}
                                menuWidth={500}
                                placeHolder="Organization..."
                                labelKey="title"
                                selectedObj={orgFilter}
                                onSelect={(label, obj) => {
                                    setOrgFilter(obj)
                                    setMissionFilter(null)
                                }}
                                selectOnBlur={false}
                                showClearIcon={true}
                                onClear={() => {
                                    setOrgFilter(null)
                                }}
                            />
                            &nbsp;
                        </>
                    )}
                    {app.state.missions.filter((m) => !m.isTemplate).length > 0 && (
                        <SearchDropDown
                            data={app.state.missions
                                .filter(
                                    (m) =>
                                        !m.isTemplate &&
                                        !m.isModel &&
                                        (!orgFilter || getObjectId(m.org) === getObjectId(orgFilter))
                                )
                                .map((m) => ({ ...m, title: `${m.projectCode ? m.projectCode : ""} ${m.title}` }))}
                            wrapperClassName={cx("w-180", { "x-f-orange": missionFilter })}
                            menuWidth={500}
                            placeHolder="Mission..."
                            labelKey="title"
                            selectedObj={missionFilter}
                            onSelect={(label, obj) => {
                                setMissionFilter(obj)
                            }}
                            selectOnBlur={false}
                            showClearIcon={true}
                            onClear={() => {
                                setMissionFilter(null)
                            }}
                        />
                    )}
                    &nbsp;
                    {app.state.missions.filter((m) => !m.isTemplate).length > 0 && (
                        <SearchDropDown
                            data={[
                                { label: "Overdue", id: "overdue" },
                                { label: "Blocked", id: "blocked" },
                                { label: "Due today", id: "due-today" },
                                { label: "Due tomorrow", id: "due-tomorrow" },
                                { label: "Due this week", id: "due-this-week" },
                                { label: "In progress", id: "in-progress" },
                                { label: "Not being worked on", id: "not-planned" },
                                { label: "Complete", id: "done" },
                                { label: "CR awaiting approval", id: "cr-open" },
                                { label: "Approved CRs", id: "cr-approved" },
                            ]}
                            noAlpha
                            selectedObj={statusFilter}
                            wrapperClassName={cx("w-150", { "x-f-orange": statusFilter })}
                            placeHolder="View by status..."
                            menuWidth={300}
                            labelKey="label"
                            onSelect={(label, obj) => {
                                setStatusFilter(obj)
                            }}
                            selectOnBlur={false}
                            showClearIcon={true}
                            onClear={() => {
                                setStatusFilter(null)
                            }}
                        />
                    )}
                    &nbsp;
                    {missionFilter && canAddTask() && (
                        <>
                            <Button
                                mint
                                onClick={() => {
                                    const myAction = getAllActions(missionFilter)?.find((a) => {
                                        return (
                                            a.source === "mx-ai" ||
                                            a.aiSource === "mx-ai" ||
                                            a.title === "mx-ai" ||
                                            a.title === "General tasks..."
                                        )
                                    })
                                    const solo =
                                        missionFilter.autoAssign &&
                                        missionFilter.people?.filter((p) => p.permission < 3).length === 1

                                    const std = startOfDay(new Date())
                                    app.actionItemCreateX(
                                        myAction._id,
                                        {
                                            week: getTimeUtc(startOfISOWeek(new Date())),
                                            startDate: getTimeUtc(std),
                                            startHour: getStartHour(std),
                                            estimatedDuration:
                                                eachDayOfInterval({
                                                    start: std,
                                                    end:
                                                        isWeekend(new Date()) || isFriday(new Date())
                                                            ? endOfISOWeek(addWeeks(new Date(), 1))
                                                            : nextFriday(new Date()),
                                                }).length * 8,
                                            people: solo ? [app.state.person._id] : [],
                                            seen: [
                                                {
                                                    by: app.state.person._id,
                                                    when: getTimeUtc(new Date()),
                                                },
                                            ],
                                        },
                                        (res) => {
                                            app.showActionItemDetails(res, { focus: "title" })
                                        }
                                    )
                                }}
                            >
                                New task
                            </Button>
                            &nbsp;
                        </>
                    )}
                    <Button
                        icon
                        solid
                        noFlipping
                        className="filter-button"
                        onClick={() => {
                            setHideDone(!hideDone)
                        }}
                    >
                        {!hideDone ? (
                            <>
                                <PiEyeDuotone color="green" />
                                Done{" "}
                            </>
                        ) : (
                            <>
                                <PiEyeClosedDuotone color={"#fd2a7d"} />
                                Done{" "}
                            </>
                        )}
                    </Button>
                </div>
            </div>

            <div className={"ag-theme-alpine dna-ag-grid x-t-grid"}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    getRowId={getRowId}
                    rowData={rowData}
                    suppressContextMenu={true}
                    getRowHeight={getRowHeight}
                    headerHeight={30}
                    animateRows={true}
                    defaultColDef={defaultColDef}
                    onGridReady={(params) => params.api.onFilterChanged()}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    onCellClicked={onCellClicked}
                    noRowsOverlayComponent={() => {
                        return (
                            <div className="dna-col">
                                <h2 style={{ marginBottom: 10 }}>🌘</h2>
                                <p>Nothing to see here... create more action items.</p>
                            </div>
                        )
                    }}
                />
            </div>
        </div>
    )
}

const getData = (app, missionRef, personFilter) => {
    let data = []

    let allActionItems = app.state.missions
        .filter((m) => !m.isTemplate && !m.isModel)
        .flatMap((m) => m.planItems)
        .flatMap((p) => p.actions)
        .flatMap((a) => a.actionItems)
        .filter((ai) => !!ai)

    const missionMap = new Map(app.state.missions.map((m) => [m._id, m]))

    const flatPlanItems = flatMap(missionRef.current, "planItems")
    const flatPlanRoles = flatPlanItems.filter((p) => p.type === "person" && p.raciOnTasks?.length)

    flatPlanRoles.forEach((pi) => {
        if (missionMap.get(pi.missionId)?.projectType === "mx-gantt") {
            pi.raciOnTasks.forEach((pr) => {
                const foundAi = allActionItems.find((a) => a._id === pr.taskId)

                if (foundAi) {
                    if (foundAi.people?.find((p) => getRefId(p) === getRefId(pi.person))) {
                        foundAi.people = [...foundAi.people, ...[getRefId(pi.person)]]
                    } else {
                        foundAi.people = [...[getRefId(pi.person)]]
                    }
                }
            })
        }
    })

    allActionItems.forEach((ai, i) => {
        if (!ai) return
        const mission = missionRef.current[ai?.missionId]
        if (!mission) return
        const overdue = isOverdue(ai)

        let status = overdue ? "overdue" : ai?.status === "blocked" ? "blocked" : ai.status === "done" ? "done" : "open"

        if (status !== "done") {
            if (ai.col === "todo") {
                status = "not-planned"
            } else {
                status = "in-progress"
            }
        }

        const col = mission?.cols?.find((c) => c.id === ai.col)

        const who = personFilter === "mine" ? app.state.person._id : personFilter?.length > 7 ? personFilter : null

        const firstSeen = (ai.seen || []).sort((a, b) => b?.when - a?.when)

        const seen = (ai.seen || []).find((s) => {
            if (who) {
                return s.by === getRefId(who)
            }

            if (ai.people.find((p) => getRefId(p) === app.state.person._id)) {
                return s.by === app.state.person._id
            }

            if (firstSeen) {
                return firstSeen._id === s._id
            }

            return false
        })

        const isPinned = ai.pinned?.find((p) => p.by === app.state.person._id)
        const tId = getAiCode(ai)
        const obj = {
            _id: ai._id,
            taskId: tId,
            ai,
            mission: (mission.projectCode ? mission.projectCode + "-" : "") + " " + mission.title,
            taskTitle: tId + " " + ai.title,
            dueDate: findAiEndDate(ai)?.getTime() || 0,
            seen: seen?.when,
            isOverdue: overdue ? 1 : 0,
            permissions: actionItemPermissions(ai),
            status: status,
            process:
                mission.projectType !== "mx-gantt" && !ai.week
                    ? "Not scheduled"
                    : col?.id === "done"
                    ? ""
                    : col?.id === "todo"
                    ? "Not started"
                    : col.name,
            pinned: Boolean(isPinned) ? 1 : 0,
        }

        data.push(obj)
    })

    return {
        data: data,
    }
}

const TaskCell = (params) => {
    const { ai } = params.data

    const progress = ai.checklist?.filter((c) => c.done)?.length || 0

    const mission = params.missionRef.current[params.data?.ai.missionId]
    const people = mission?.people.filter((p) => p.permission < 3)
    const aiPeople = (params.data?.ai?.people || [])
        .map((ap) => people.find((p) => getRefId(p) === ap))
        .filter((p) => !!p)

    const org = params.orgRef.current[mission.org?._id]

    return (
        <div>
            <div className="x-tm-ai-title">
                <b className="x-clamp-2">
                    {getAiCode(ai)} {ai.title || "No title specified..."}
                </b>
            </div>
            <div className="dna-smaller-text x-clamp-1">{mission.title}</div>
            <div className="dna-progress-bar-x  w-100">
                <div style={{ width: (progress / ai.checklist?.length || 0) * 100 + "%" }}></div>
            </div>
            <Hive
                style={{ marginTop: 10 }}
                round
                ai={ai}
                mission={mission}
                people={aiPeople}
                width={26}
                orgData={org}
                maxLength={8}
                app={params.app}
            />
        </div>
    )
}

TaskMaster.displayName = "TaskMaster"

export default TaskMaster
