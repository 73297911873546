import React from "react"
import RoundAvatar from "./RoundAvatar"
import Highlighter from "react-highlight-words"
import { getRefId, getPersonName, isArchived, getPersonLocation } from "../utils/utils.js"
import Flag from "react-flagkit"

const PersonAvatar = ({ person, orgData, mission, searchString, avatarSize, hideRole }) => {
    if (!person) return null

    if (!orgData) {
        return (
            <div className="dna-flex  dna-person-snip">
                <RoundAvatar
                    mission={mission}
                    orgData={orgData}
                    person={person}
                    noTip
                    width={avatarSize}
                    style={{ marginRight: 10, marginTop: 3 }}
                />{" "}
                <strong className="x-snip-name">
                    <Highlighter
                        highlightClassName="dna-highlight-text"
                        searchWords={[searchString]}
                        autoEscape={true}
                        textToHighlight={getPersonName(person)}
                    />
                </strong>
            </div>
        )
    } else {
        const orgPerson = orgData?.people.find((p) => getRefId(p) === getRefId(person))

        if (!orgPerson) {
            return "Not found in organization"
        }

        const role = orgData.roles?.find((r) => r._id === person.role)
        const department = person.departments?.length
            ? orgData.departments?.find((d) => !isArchived(d) && d._id === person.departments[0])
            : null

        const office = person.office ? orgData.offices?.find((d) => d._id === person.office) : null

        return (
            <div className="dna-flex dna-person-snip">
                <RoundAvatar
                    width={avatarSize}
                    mission={mission}
                    noTip
                    orgData={orgData}
                    person={person}
                    style={{ marginRight: 10, marginTop: 3 }}
                />{" "}
                <div>
                    <strong className="x-snip-name">
                        <Highlighter
                            highlightClassName="dna-highlight-text"
                            searchWords={[searchString]}
                            autoEscape={true}
                            textToHighlight={getPersonName(person)}
                        />
                    </strong>
                    <br />
                    <div>
                        {role?.name ? (
                            <Highlighter
                                highlightClassName="dna-highlight-text"
                                searchWords={[searchString]}
                                autoEscape={true}
                                textToHighlight={role.name}
                            />
                        ) : (
                            "None"
                        )}
                    </div>
                    <div className="dna-smaller-text dna-flex wrap" style={{ marginTop: 5 }}>
                        {department ? department.title : "Not assigned a department"}
                        {office ? (
                            <>
                                ,&nbsp; {office.title} {office.country}
                            </>
                        ) : (
                            <>,&nbsp; {getPersonLocation(person)}</>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default PersonAvatar
