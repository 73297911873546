import React from "react"

export default ({ size = 24 }) => (
    <svg className="bi bi-download" width={size} height={size} viewBox={`0 0 20 20`} fill="currentColor">
        <path d="M6.887 7.2l-.964-.165A2.5 2.5 0 105.5 12H8v1H5.5a3.5 3.5 0 11.59-6.95 5.002 5.002 0 119.804 1.98A2.501 2.501 0 0115.5 13H12v-1h3.5a1.5 1.5 0 00.237-2.981L14.7 8.854l.216-1.028a4 4 0 10-7.843-1.587l-.185.96z" />
        <path
            fillRule="evenodd"
            d="M7 14.5a.5.5 0 01.707 0L10 16.793l2.293-2.293a.5.5 0 11.707.707l-2.646 2.647a.5.5 0 01-.708 0L7 15.207a.5.5 0 010-.707z"
            clip-rule="evenodd"
        />
        <path fillRule="evenodd" d="M10 8a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 0110 8z" clipRule="evenodd" />
    </svg>
)
