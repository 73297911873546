import {v5 as uuidv5} from 'uuid';
import {setMany, get, update, delMany} from 'idb-keyval';
import {toMillis} from './utils';

const CACHE_BUSTING_VERSION = '1'; // Change this to force cache reset. E.g. when breaking changes are expected
const CACHE_EXPIRY_TIME_MILLIS_DEFAULT = toMillis(48, 'hours');
const CACHE_NAMESPACE = 'c822b5c6-bab7-4193-90ab-36cf6b25530c';
const CACHE_KEY_PREFIX = 'cached';
const CACHE_EXPIRY_MAP_KEY_NAME = 'cachedEx';

export async function setCache(data, id) {

    if (!data || !id) {
        throw new Error('Both data and a unique id are required to store the data');
    }

    const keyName = getIdbKeyName(id);
    const timestamp = Date.now();
    let expiryMap = await get(CACHE_EXPIRY_MAP_KEY_NAME);


    if (!expiryMap) {
        expiryMap = new Map();
    }

    expiryMap.set(keyName, {
        timestamp,
        version: CACHE_BUSTING_VERSION
    });

    await setMany([
        [keyName, {
            timestamp,
            data
        }],
        [CACHE_EXPIRY_MAP_KEY_NAME, expiryMap]
    ]);
}

export async function getCache(id) {
    const cacheName = getIdbKeyName(id);
    return get(cacheName);
}

export async function delAllExpiredCache(milliseconds = CACHE_EXPIRY_TIME_MILLIS_DEFAULT) {
    const exMap = await get(CACHE_EXPIRY_MAP_KEY_NAME);

    if (!exMap || exMap.size === 0) {
        return;
    }

    const keyToDeleteList = [];

    for (const [key, data] of exMap) {
        if (
            (data?.timestamp && (Date.now() - data?.timestamp) > milliseconds) ||
            (data?.version !== CACHE_BUSTING_VERSION)
        ) {
            keyToDeleteList.push(key);
        }
    }

    if (keyToDeleteList.length) {
        await delMany(keyToDeleteList);
        await update(CACHE_EXPIRY_MAP_KEY_NAME, (idbExMap) => {
            keyToDeleteList.forEach(key => idbExMap.delete(key));
            return idbExMap;
        });
    }
}

export async function delAllCache() {
    const exMap = await get(CACHE_EXPIRY_MAP_KEY_NAME);

    if (!exMap || exMap.size === 0) {
        return;
    }

    const keyToDeleteList = Array.from(exMap.keys())

    if (keyToDeleteList.length) {
        await delMany([...keyToDeleteList, CACHE_EXPIRY_MAP_KEY_NAME]);
    }
}


function getIdbKeyName(id = '') {
    let idStr;

    if (typeof id === 'string') {
        idStr = id;
    } else {
        try {
            idStr = JSON.stringify(id);
        } catch (err) {
            // Nothing
        }
    }

    return idStr.length > 0 ? `${CACHE_KEY_PREFIX}-${uuidv5(idStr, CACHE_NAMESPACE)}` : CACHE_KEY_PREFIX;
}