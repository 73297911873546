import {get} from 'lodash';
import api from '../api.service';

class UnsubscribeApi {

    email(token) {
        return api.public
            .get(`/unsubscribe/email?token=${token}`)
            .catch(err => {
                throw get(err || {}, 'response.data')
            });
    }

}

export default new UnsubscribeApi()
