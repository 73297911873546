import React from "react"

export default ({ width = 30}) => (
    <svg width={width} height={width} viewBox="0 0 30 38" className="dna-svg-icon dna-icon-path dna-icon-leave">

    <circle cx="6.786" cy="7.555" r="2.758"/>
    <path d="M6.985,13.62l0.631-1.684c0.11-0.293,0.398-0.37,0.641-0.171l1.388,1.142c0.242,0.199,0.399,0.109,0.347-0.199l-0.296-1.773  c-0.052-0.309,0.16-0.521,0.469-0.469l1.773,0.295c0.309,0.053,0.399-0.104,0.2-0.346l-1.142-1.388  c-0.2-0.241-0.123-0.53,0.171-0.641l1.683-0.631c0.294-0.11,0.294-0.29,0-0.401l-1.682-0.629c-0.294-0.11-0.372-0.398-0.172-0.641  l1.142-1.389c0.199-0.242,0.109-0.397-0.2-0.346l-1.773,0.295c-0.309,0.052-0.521-0.16-0.469-0.469l0.296-1.773  c0.052-0.309-0.105-0.399-0.347-0.199L8.258,3.346C8.015,3.544,7.727,3.468,7.617,3.174L6.986,1.49c-0.109-0.293-0.29-0.293-0.4,0  l-0.63,1.683c-0.11,0.295-0.398,0.371-0.641,0.172L3.927,2.202C3.685,2.003,3.529,2.094,3.581,2.403l0.295,1.773  c0.052,0.309-0.16,0.52-0.468,0.469L1.634,4.35c-0.31-0.052-0.4,0.104-0.2,0.347l1.142,1.388c0.199,0.241,0.123,0.53-0.172,0.64  L0.721,7.354c-0.293,0.11-0.293,0.291,0,0.4l1.683,0.631c0.294,0.111,0.371,0.398,0.172,0.641l-1.142,1.388  c-0.2,0.242-0.109,0.398,0.2,0.347l1.774-0.295c0.309-0.053,0.52,0.16,0.468,0.469L3.58,12.708  c-0.051,0.309,0.105,0.398,0.347,0.199l1.388-1.143c0.243-0.199,0.531-0.121,0.64,0.172l0.631,1.684  C6.696,13.913,6.875,13.913,6.985,13.62z M3.305,7.555c0-1.923,1.559-3.481,3.481-3.481s3.481,1.559,3.481,3.481  s-1.559,3.481-3.481,3.481S3.305,9.478,3.305,7.555z"/>

    <path d="M28.234,10.575c-0.062-0.563-0.385-1.068-0.91-1.313s-1.121-0.167-1.592,0.147c-3.546-0.934-7.358,0.763-8.962,4.203  c-0.175,0.373-0.306,0.754-0.417,1.137c0.794-0.573,1.86-0.722,2.811-0.277c1.073,0.499,1.672,1.594,1.602,2.707  c0.67-0.638,1.626-0.906,2.544-0.697l-4.612,9.891c-0.799-0.105-1.637-0.167-2.506-0.167c-5.196,0-9.407,2.026-9.407,4.525H25.6  c0-1.866-2.349-3.468-5.702-4.159l4.475-9.596c0.75,0.569,1.159,1.475,1.101,2.398c0.808-0.771,2.032-1.015,3.105-0.514  c0.951,0.443,1.522,1.355,1.593,2.332c0.223-0.33,0.429-0.676,0.604-1.05C32.379,16.703,31.229,12.69,28.234,10.575z"/>



    </svg>
)
