import React from "react"

export default ({ width = 20, color }) => (
  <svg width={width} height={width} viewBox="0 0 100 100" className="dna-svg-icon dna-icon-path dna-icon-link">


  <path d="M82.31,86h0a21.93,21.93,0,0,1-31,0L38.84,73.56a1.46,1.46,0,0,1,1.42-2.43,28.31,28.31,0,0,0,9,1,3.35,3.35,0,0,1,2.54,1L58,79.28a12.4,12.4,0,0,0,17.53,0h0a12.39,12.39,0,0,0,0-17.53L62,48.19a12.39,12.39,0,0,0-17.53,0,1,1,0,0,1-1.35,0L37.74,42.8a1,1,0,0,1,0-1.35,21.93,21.93,0,0,1,31,0L82.31,55A21.93,21.93,0,0,1,82.31,86ZM25.4,21.69a12.4,12.4,0,0,1,17.53,0l6.21,6.21a3.35,3.35,0,0,0,2.54,1,28.31,28.31,0,0,1,9,1,1.46,1.46,0,0,0,1.42-2.43L49.67,15a21.93,21.93,0,0,0-31,0h0a21.93,21.93,0,0,0,0,31L32.22,59.53a21.93,21.93,0,0,0,31,0h0a1,1,0,0,0,0-1.35l-5.39-5.39a1,1,0,0,0-1.35,0h0a12.39,12.39,0,0,1-17.53,0L25.4,39.22a12.39,12.39,0,0,1,0-17.53Zm57,7.09,7.94-6.32a3.61,3.61,0,0,0-4.49-5.64l-7.94,6.32a3.61,3.61,0,0,0,4.49,5.64ZM93.68,33l-10,1.88A3.61,3.61,0,1,0,85,41.93l10-1.88A3.61,3.61,0,1,0,93.68,33ZM74.49,6.48h0a3.61,3.61,0,0,0-4.75,1.86l-4.06,9.3a3.61,3.61,0,0,0,6.61,2.89l4.06-9.3A3.61,3.61,0,0,0,74.49,6.48ZM6.32,67l10-1.88A3.61,3.61,0,1,0,15,58.07L5,59.95a3.61,3.61,0,0,0-2.87,4.21h0A3.61,3.61,0,0,0,6.32,67ZM25.51,93.52h0a3.61,3.61,0,0,0,4.75-1.86l4.06-9.3a3.61,3.61,0,0,0-6.61-2.89l-4.06,9.3A3.61,3.61,0,0,0,25.51,93.52ZM17.78,71,9.85,77.35A3.61,3.61,0,0,0,14.34,83l7.94-6.32A3.61,3.61,0,0,0,17.78,71Z"></path>
  

  </svg>
)
