import React from "react"
import cx from "classnames"

import Tip from "./Tip"
import { motion, AnimatePresence } from "framer-motion"
import history from "../comp/History"

import { PiX } from "react-icons/pi"
import ReactDOM from "react-dom"
const queryString = require("query-string")

const FullScreen = ({
    open,
    children,
    portal,
    id,
    style,
    closeClass,
    closeStyle,
    closeTip,
    className,
    param,
    onClose,
}) => {
    const cn = cx("dna-fullscreen", className)

    const closeHandler = () => {
        if (param) {
            const q = queryString.parse(history.location.search)
            param.split(",").forEach((param) => {
                delete q[param]
            })

            history.push({
                search: queryString.stringify(q),
            })
        }
        if (onClose) {
            onClose()
        }
    }

    const comp = (
        <AnimatePresence>
            {open && (
                <motion.div
                    transition={{ duration: 0.3 }}
                    initial={{ opacity: 0, transform: "scale3d(0.98, 0.98, 0.98)" }}
                    animate={{ opacity: 1, transform: "scale3d(1, 1, 1)" }}
                    exit={{ opacity: 0, transform: "scale3d(0.98, 0.98, 0.98)" }}
                    className={cn}
                    style={style}
                >
                    {children}
                    <CloseX
                        closeHandler={closeHandler}
                        closeClass={closeClass}
                        closeTip={closeTip}
                        closeStyle={closeStyle}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    )

    if (portal) {
        return ReactDOM.createPortal(comp, document.body)
    }

    return comp
}

const CloseX = ({ closeClass, closeHandler, closeTip, closeStyle }) => {
    return (
        <div className={cx("x-fs-close dna-under-con", closeClass)} onClick={closeHandler} style={closeStyle}>
            {closeTip ? (
                <Tip red position="bottom" title={closeTip}>
                    <PiX />
                </Tip>
            ) : (
                <PiX />
            )}
        </div>
    )
}

export default FullScreen
