import api from '../api.service';

const queryString = require('query-string');

class AIApi {

    /**
     * @param missionId {string}
     * @return {*}
     */
    whatIf(missionId) {
        return api
            .post(`/ai/what-if`, {missionId})
            .then(res => typeof res.data === 'object' ? res.data : {})
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * @param missionId {string}
     * @return {*}
     */
    weeklyPlot(missionId) {
        return api
            .post(`/ai/weekly-plot`, {missionId})
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * @typedef {Object[]} RolesNeeded
     * @property missionPlanId {string} The mission plan id
     */
    /**
     * @param orgId {string} The org id
     * @param rolesNeeded {RolesNeeded}
     * @param [options] {object}
     * @param [options.weights] {object}
     * @param [options.precision] {'year'|'month'|'day'|'hour'} Defaults to "day". All availability results are based on this unit.
     * @returns {Promise<* | void>}
     *
     * E.g.
     *
     * apiAi.recommendOrgResources('ORG_ID', [
     *      {missionPlanId: 'PLAN_ID'},
     *      {missionPlanId: 'PLAN_ID'},
     * ]);
     *
     * DEFAULT WEIGHTS
     * If the "weights" option is provided, will override the default values that are set on the API side.
     * This can make it easy for you to play around...
     *
     *{
     *  availabilityLeave: 1,
     *  availabilityOnboarded: 1,
     *  availabilityRole: 1,
     *  matchingRole: 0.6,
     *  matchingSkillTags: 0.3,
     *  matchingDepartment: 0.3,
     *}
     */
    recommendOrgResources(orgId, rolesNeeded, options = {}) {
        return api
            .post(`/ai/resource/org/${orgId}/recommend`, {
                rolesNeeded,
                ...options
            })
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * @param orgId {string} The org id
     * @param rolesNeeded {RolesNeeded}
     * @param [options] {object}
     * @param [options.weights] {object}
     * @param [options.precision] {'year'|'month'|'day'|'hour'} Defaults to "day". All availability results are based on this unit.
     * @returns {Promise<* | void>}
     *
     * E.g.
     *
     * apiAi.recommendOrgResources('ORG_ID', [
     *      {missionPlanId: 'PLAN_ID'},
     *      {missionPlanId: 'PLAN_ID'},
     * ]);
     *
     * DEFAULT WEIGHTS
     * If the "weights" option is provided, will override the default values that are set on the API side.
     * This can make it easy for you to play around...
     *
     *{
     *  availabilityLeave: 1,
     *  availabilityOnboarded: 1,
     *  availabilityRole: 1,
     *  matchingRole: 0.6,
     *  matchingSkillTags: 0.3,
     *  matchingDepartment: 0.3,
     *}
     */
    recommendOrgResourcesPreassigned(orgId, rolesNeeded, options = {}) {
        return api
            .post(`/ai/resource/org/${orgId}/recommend/preassigned`, {
                rolesNeeded,
                ...options
            })
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * @param orgId {string} The org id
     * @param rolesNeeded {RolesNeeded}
     * @param [options] {object}
     * @param [options.assignedRoles] {object[]} Mappings of person to role. It is used to assume assignments of a person to a role, so calculations build on top of this "draft" assignment. Helps to avoid recommending the same person if user has overridden the selection.
     * @param [options.assignedRoles[].roleId] {string} The plan item role _id to designate as assigned
     * @param [options.assignedRoles[].personId] {string} The person _id to designate as assigned to the role
     * @param [options.sessionId] {string} If provided, the already calculated scores will be used to speed things up. A new session ID is returned with each request, which expires after 4 hours. If parameters change, like dates or person vacation time, do not send a session ID to force recalculation of all scores
     * @param [options.weights] {object}
     * @param [options.precision] {'year'|'month'|'day'|'hour'} Defaults to "day". All availability results are based on this unit.
     * @param [options.limitSetBy] {string[]} An array of properties to restrict recommendation people by. Possible values: "role", "department", "office"
     * @returns {Promise<* | void>}
     *
     * E.g.
     *
     * apiAi.recommendOrgResources('ORG_ID', [
     *      {missionPlanId: 'PLAN_ID'},
     *      {missionPlanId: 'PLAN_ID'},
     * ]);
     *
     * DEFAULT WEIGHTS
     * If the "weights" option is provided, will override the default values that are set on the API side.
     * This can make it easy for you to play around...
     *
     *{
     *  availabilityLeave: 1,
     *  availabilityOnboarded: 1,
     *  availabilityRole: 1,
     *  matchingRole: 0.6,
     *  matchingSkillTags: 0.3,
     *  matchingDepartment: 0.3,
     *  matchingOffice: 0.3,
     *}
     */
    recommendOrgResourcesPreassignedSimple(orgId, rolesNeeded, options = {}) {
        return api
            .post(`/ai/resource/org/${orgId}/recommend/preassigned/simple`, {
                rolesNeeded,
                ...options
            })
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * @param orgId {string} The org id
     * @param subtaskIds {string[]} A list of checklist item ids to recommend resource
     * @param [options] {object}
     * @param [options.weights] {object}
     * @param [options.precision] {'year'|'month'|'day'|'hour'} Defaults to "day". All availability results are based on this unit.
     * @returns {Promise<* | void>}
     *
     * E.g.
     *
     * apiAi.recommendOrgResourceChecklist('ORG_ID', [
     *      'CHECKLIST_ITEM_ID',
     *      'CHECKLIST_ITEM_ID'
     * ]);
     *
     * DEFAULT WEIGHTS
     * If the "weights" option is provided, will override the default values that are set on the API side.
     * This can make it easy for you to play around...
     *
     *{
     *  availabilityLeave: 1,
     *  availabilityOnboarded: 1,
     *  availabilityRole: 1,
     *  matchingRole: 0.6,
     *  matchingSkillTags: 0.3,
     *  matchingDepartment: 0.3,
     *}
     */
    recommendOrgSubtask(orgId, subtaskIds, options = {}) {
        return api
            .post(`/ai/resource/org/${orgId}/recommend/checklist`, {
                checklistItemIds: subtaskIds,
                ...options
            })
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    getOrgSubtaskOverlap(orgId, subtaskIds, options = {}) {
        return api
            .post(`/ai/resource/org/${orgId}/overlap/checklist`, {
                subtaskIds,
                ...options
            })
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * Recalculates the resource recommendation, given a session ID returned by the "recommendOrgResources".
     *
     * E.g.
     *
     * const sessionId: '894fe06f4da23b47cc91dba1';
     * const assignedRoles = [ // Role assignments to test with (not in DB)
     *      {
     *          personId: '614fe06f4da23b47cc913f0f',
     *          roles: [
     *              {
     *                  roleId: '614fe06f4da23b47cc913f0f',
     *                  startDate: 1632182400000,
     *                  endDate: 1632182400000,
     *                  hoursNeeded: 64
     *              }
     *          ]
     *      },
     * ];
     *
     * const result = await recommendOrgResourcesReCalculate(orgId, sessionId, {assignedRoles})
     *
     * componentWillUnmount() {
     *     // It's good to clean up DB, at least until I put a cron to do so...
     *     deleteOrgResourcesSession(sessionId).catch(err => {});
     * }
     *
     * @param orgId {string}
     * @param sessionId {string} The "sessionId" returned by "recommendOrgResources"
     *
     * @param configuration {object}
     * @param configuration.assignedRoles {object[]}
     * @param configuration.assignedRoles[].personId {string} The person ID that has been assigned to one or more roles
     * @param configuration.assignedRoles[].roles {object[]}
     * @param configuration.assignedRoles[].roles[].roleId {string} The role ID
     * @param configuration.assignedRoles[].roles[].startDate {number} The role startDate
     * @param configuration.assignedRoles[].roles[].endDate {number} The role endDate
     * @param [configuration.assignedRoles[].roles[].hoursNeeded] {number} The role hoursNeeded
     *
     * @param options
     * @returns {*}
     */
    recommendOrgResourcesReCalculate(orgId, sessionId, configuration, options = {}) {
        const {assignedRoles} = configuration || {};
        return api
            .post(`/ai/resource/org/${orgId}/recommend/session/${sessionId}`, {
                assignedRoles
            })
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * Clean up the session created by "recommendOrgResources"
     * @param sessionId {string}
     * @returns {Promise<*>}
     */
    deleteOrgResourcesSession(sessionId) {
        return api
            .delete(`/ai/resource/recommend/session/${sessionId}`)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    // LATER
    // recommendMissionResources(missionId, rolesNeeded, options = {}) {
    //     return api
    //         .post(`/ai/resource/mission/${missionId}/recommend`, {
    //             rolesNeeded
    //         })
    //         .then(res => res.data)
    //         .catch(err => {
    //             throw api.getResponseError(err);
    //         });
    // }

    // AI GUYS
    // recommendResources(orgId, missionId) {
    //     return api
    //         .post(`/ai/resource/org/${orgId}/recommendation`, {missionId})
    //         .then(res => res.data)
    //         .catch(err => {
    //             throw api.getResponseError(err);
    //         });
    // }
}

export default new AIApi();