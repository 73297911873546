import React from "react"

export default ({ width, size }) => (
    <svg width={width ?? size ?? 18} height={width ?? size ?? 18} viewBox="0 0 48 60" className="dna-svg-icon dna-icon-path dna-icon-trash">

    <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g>
            <path d="M16,49 C17.1,49 18,48.1 18,47 L18,31 C18,29.9 17.1,29 16,29 C14.9,29 14,29.9 14,31 L14,47 C14,48.1 14.9,49 16,49 Z" id="Path"></path>
            <path d="M32,49 C33.1,49 34,48.1 34,47 L34,31 C34,29.9 33.1,29 32,29 C30.9,29 30,29.9 30,31 L30,47 C30,48.1 30.9,49 32,49 Z" id="Path"></path>
            <path d="M44,8 L33.8,8 C32.9,3.4 28.8,0 24,0 C19.2,0 15.1,3.4 14.2,8 L4,8 C1.8,8 0,9.8 0,12 L0,18 C0,20.2 1.8,22 4,22 L4,52 C4,56.4 7.6,60 12,60 L36,60 C40.4,60 44,56.4 44,52 L44,22 C46.2,22 48,20.2 48,18 L48,12 C48,9.8 46.2,8 44,8 Z M24,4 C26.6,4 28.8,5.7 29.6,8 L18.4,8 C19.2,5.7 21.4,4 24,4 Z M40,52 C40,54.2 38.2,56 36,56 L12,56 C9.8,56 8,54.2 8,52 L8,22 L40,22 L40,52 Z M42,18 L6,18 L4,18 L4,12 L44,12 L44,18 L42,18 Z" fillRule="nonzero"></path>
        </g>
    </g>
    </svg>
)
