import React from "react"
import cx from "classnames"

export default class extends React.PureComponent {
    render() {
        const cn = cx("dna-progress-bar", this.props.className, {
            issue: this.props.issue || this.props.blocked,
        })

        return (
            <div className={cn} style={this.props.style} onClick={this.props.onClick}>
                {this.props.label && <h4>{this.props.label}</h4>}

                <div
                    className="dna-progress-count"
                    style={{
                        backgroundColor: this.props.color,
                        width: (this.props.width > 100 ? 100 : this.props.width) + "%",
                    }}
                ></div>
                {!isNaN(this.props.advance) && this.props.advance > 0 && (
                    <div
                        className={"dna-progress-advance " + this.props.advanceClassName}
                        style={{ left: this.props.width + "%", width: this.props.advance + "%" }}
                    ></div>
                )}
            </div>
        )
    }
}
