import { getObjectId } from "./Utils"

export function showHiddenGem(user) {
    return [
        '5d663b175d39b511a08f99c3',
        '5d6634975d39b53d248f9980'
    ].includes(user?._id);
}

/**
 * Returns a person's full name
 *
 * @param firstName {String}
 * @param lastName {String}
 * @param [fallback] {String}
 * @return {String|undefined|*}
 */
export function getFullName(firstName, lastName, fallback) {
    return !firstName && !lastName ? fallback : `${firstName ? firstName : ""}${lastName ? " " + lastName : ""}`
}

/**
 * Check if the provided user email is verified
 * @param user {object}
 * @param user.emailList {object[]}
 * @param user.emailList[].email {string}
 * @param user.emailList[].verified {boolean}
 * @param email {string}
 * @returns {boolean}
 */
export function isUserEmailVerified(user, email) {
    const userEmailItem = (user?.emailList || []).find((emailItem) => emailItem.email === email)

    return userEmailItem?.verified === true
}

/**
 * Checks if the logged-in user's email is verified for a mission
 * @param user {object}
 * @param user._id {string}
 * @param user.emailList {object[]}
 * @param user.emailList[].email {string}
 * @param user.emailList[].verified {boolean}
 * @param mission {object}
 * @param mission.people {object[]}
 * @param mission.people[].ref {string|object}
 * @param mission.people[].email {string}
 * @returns {boolean}
 */
export function isUserEmailVerifiedForMission(user, mission) {
    const missionPerson = (mission?.people || []).find((missionPerson) => getObjectId(missionPerson.ref) === user._id)

    const userEmailItem = (user?.emailList || []).find((emailItem) => emailItem.email === missionPerson?.email)

    return userEmailItem?.verified === true
}

/**
 * Checks if the logged-in user's email is verified for a mission
 * @param user {object}
 * @param user._id {string}
 * @param user.emailList {object[]}
 * @param user.emailList[].email {string}
 * @param user.emailList[].verified {boolean}
 * @param org {object}
 * @param org.people {object[]}
 * @param org.people[].ref {string|object}
 * @param org.people[].email {string}
 * @returns {boolean}
 */
export function isUserEmailVerifiedForOrg(user, org) {
    const orgPerson = (org?.people || []).find((missionPerson) => getObjectId(missionPerson.ref) === user._id)

    const userEmailItem = (user?.emailList || []).find((emailItem) => emailItem.email === orgPerson?.email)

    return userEmailItem?.verified === true
}
