import api from '../api.service';

const queryString = require('query-string');

/**
 * Comment examples:
 *
 * apiComments.mission()
 * apiComments.planItem()
 * apiComments.action()
 * apiComments.actionItem()
 *
 * CREATE
 * apiComments
 *      .actionItem(actionItemId)
 *      .addComment({text: 'Comment text'})
 *      .then(comment => console.log(comment));
 *
 * GET all parent comments
 * apiComments
 *      .actionItem(actionItemId)
 *      .getCommentList()
 *      .then(commentList => console.log(commentList));
 *
 * GET all parent comments, fully populated example
 * apiComments
 *      .actionItem(actionItemId)
 *      .getCommentList({populate: ['mission', 'room', 'claps']})
 *      .then(commentList => console.log(commentList));
 *
 * GET all replies of a comment
 * apiComments
 *      .getCommentReplyList(commentId)
 *      .then(commentList => console.log(commentList));
 *
 * GET all mission parent comments
 * apiComments
 *      .getMissionCommentList(missionId)
 *      .then(commentList => console.log(commentList));
 *
 * GET all mission comments, including replies
 * apiComments
 *      .getMissionCommentList(missionId, {repliedTo: true})
 *      .then(commentList => console.log(commentList));
 *
 * UPDATE
 * apiComments
 *      .updateComment(commentId, {text: 'Updated text'})
 *      .then(comment => console.log(comment));
 *
 * DELETE
 * apiComments
 *      .deleteComment(commentId)
 *      .then(() => console.log('Deleted'));
 *
 */
class CommentApi {

    constructor() {

        const rooms = [
            'mission',
            'planItem',
            'action',
            'actionItem'
        ];

        const roomName = {
            mission: 'Mission',
            planItem: 'MissionPlan',
            action: 'Action',
            actionItem: 'ActionItem'
        };

        this.rooms = rooms;
        this.roomName = roomName;

        rooms.forEach(room => {

            /**
             * @param roomId {String} The document id of the entity
             * @return {Object}
             */
            this[room] = (roomId) => {

                return {

                    /**
                     * Add a new comment
                     *
                     * @param commentData {Object}
                     * @param commentData.text {String} Comment text
                     * @param [commentData.repliedTo] {String} The id of the comment if it is a reply
                     * @param [commentData.mentions] {String|String[]} One or more people ids mentioned in the comment
                     *
                     * @param [options] {Object}
                     * @param [options.populate] {String|String[]} Fields to populate (mission, room, repliedTo, claps)
                     * @return {Promise<any>}
                     */
                    addComment: (commentData, options = {}) => this._addComment(roomName[room], roomId, commentData, options),

                    /**
                     * Get comments
                     * @param [options] {Object}
                     * @param [options.populate] {String|String[]} Fields to populate (mission, room, claps)
                     * @return {Promise<any>}
                     */
                    getCommentList: (options = {}) => this._getCommentList(roomName[room], roomId, options),

                    /**
                     * Get comments
                     * @return {Promise<any>}
                     */
                    getCount: () => this._getCommentListCount(roomName[room], roomId),

                    /**
                     * Changes listener
                     */
                    onChanges: (cb) => {
                        let socket;

                        const connect = async () => {
                            socket = await api.socket.connect(`comment/${roomName[room]}/${roomId}`);
                        };

                        connect().then(() => {
                            socket.on('changes', cb);
                        });

                        return {
                            disconnect: () => {
                                if (socket) {
                                    socket.disconnect();
                                }
                            }
                        };
                    }

                }

            };

        });
    }

    /**
     *
     * @param commentId
     * @param [options] {Object}
     * @param [options.populate] {String|String[]} Fields to populate (mission, room, repliedTo, claps)
     * @return {Promise<string>}
     */
    getCommentReplyList(commentId, options = {}) {
        const qs = queryString.stringify(options);
        return api.get(`/comment/replies/${commentId}?${qs}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * Get all comments of a mission
     * @param missionId
     * @param [options] {Object}
     * @param [options.populate] {String|String[]} Fields to populate (mission, room, repliedTo, claps)
     * @param [options.repliedTo] {Boolean} Set to true to include replies to the list. Defaults to false.
     * @return {Promise<string>}
     */
    getMissionCommentList(missionId, options = {}) {
        const qs = queryString.stringify(options);
        return api.get(`/comment/mission/${missionId}?${qs}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * @param missionId
     * @param [options] {Object}
     * @param [options.rooms] {('mission'|'planItem'|'action'|'actionItem')[]} One or more rooms to filter by.
     * @returns {Promise<object[]>}
     */
    getMissionCommentListCount(missionId, options = {}) {
        const qs = queryString.stringify(options);
        return api.get(`/comment/count/mission/${missionId}?${qs}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * Only authors can update.
     * @param commentId {String} The comment id
     * @param commentData {Object} Updated comment data
     * @param commentData.text {String} Comment text
     * @return {Promise<any>}
     */
    updateComment(commentId, commentData) {
        return api.put(`/comment/${commentId}`, commentData)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * Only authors can delete.
     * @param commentId {String} The comment id
     * @return {Promise<any>}
     */
    deleteComment(commentId) {
        return api.delete(`/comment/${commentId}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    /**
     * Toggle clap.
     * @param commentId {String} The comment id
     * @return {Promise<any>}
     */
    clap(commentId) {
        return api.put(`/comment/${commentId}/clap`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    _addComment(room, roomId, commentData, options) {
        const qs = queryString.stringify(options);
        return api.post(`/comment/room/${room}/id/${roomId}?${qs}`, commentData)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    _getCommentList(room, roomId, options) {
        const qs = queryString.stringify(options);
        return api.get(`/comment/room/${room}/id/${roomId}?${qs}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }

    _getCommentListCount(room, roomId) {
        return api.get(`/comment/count/room/${room}/id/${roomId}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            });
    }
}

export default new CommentApi();
