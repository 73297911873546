import React from "react"
import cx from "classnames"
import { VscClose } from "react-icons/vsc"
import { motion, AnimatePresence } from "framer-motion"
import ReactDOM from "react-dom"
import BlurHandler from "./BlurHandler"

const Card = ({
    open,
    onClose,
    children,
    padded,
    style,
    className,
    modalIndex,
    modalClick,
    modalClass,
    whiteModal,
    modal,
    portal,
    center,
    autoClose,
    appendTo,
}) => {
    //if (!open) return null
    const cn = cx("x-card", className, {
        "dna-disabled": !open,
    })
    const cnModal = cx("dna-modal dna-card-modal", modalClass, {
        "white-modal": whiteModal,
    })

    const controlledProps =
        portal && center ? { left: "50%", top: "50%", transform: "translate(-50%,-50%)", zIndex: 9999 } : {}

    let styleObj = {
        position: "fixed",
        padding: padded ? 30 : 0,
        minWidth: 300,
        zIndex: portal ? 9999 : 11,
        ...controlledProps,
        pointerEvents: open ? "all" : "none",
    }

    let finalStyle = { ...styleObj, ...style }

    const cardCompInner = (
        <>
            {modal && open && (
                <div
                    style={{ zIndex: modalIndex || (portal && center ? 9998 : undefined) }}
                    onClick={modalClick ? modalClick : undefined}
                    className={cnModal}
                />
            )}

            <AnimatePresence>
                {open && (
                    <motion.div
                        className={cn}
                        style={finalStyle}
                        initial={{ marginTop: 30, opacity: 0, pointerEvents: "none" }}
                        animate={{ marginTop: 0, opacity: 1, pointerEvents: "all" }}
                        exit={{ marginTop: 30, opacity: 0, pointerEvents: "none" }}
                        transition={{
                            duration: 1,
                            type: "spring",
                            bounce: 0.4,
                            opacity: { duration: 0.3, type: "ease" },
                        }}
                    >
                        <div
                            className="x-close-card"
                            onClick={(e) => {
                                onClose()
                            }}
                        >
                            <VscClose />
                        </div>
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )

    const cardComp = autoClose ? (
        <BlurHandler
            baseClass="x-card"
            onBlur={
                open
                    ? () => {
                          onClose()
                      }
                    : undefined
            }
        >
            {cardCompInner}
        </BlurHandler>
    ) : (
        cardCompInner
    )

    if (portal) {
        return ReactDOM.createPortal(cardComp, appendTo || document.body)
    }

    return cardComp
}

export default Card
