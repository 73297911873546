import React from "react"

export default ({ width = 40.2 }) => (
    <svg width={width} height={width} viewBox="0 0 130.2 130.2" className="dna-svg-icon dna-icon-oh-check">


<circle className="oh-path oh-circle"  fill="none" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
<polyline className="oh-path oh-check"  fill="none" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>


    </svg>
)
