export const ORG_PERMISSION = {
    ADMIN: 2,
    MANAGER: 1,
    MANAGER_LIMITED: 4,
    OBSERVER: 3,
    MEMBER: 0
}

export const ORG_PERMISSION_LABEL = {
    ADMIN: "Admin",
    MANAGER: "Org management",
    MANAGER_LIMITED: "Org project manager",
    OBSERVER: "Org observer",
    MEMBER: "Team member"
}

export const ORG_PERMISSION_LABEL_MAP = new Map([
    [ORG_PERMISSION.ADMIN, ORG_PERMISSION_LABEL.ADMIN],
    [ORG_PERMISSION.MANAGER, ORG_PERMISSION_LABEL.MANAGER],
    [ORG_PERMISSION.MANAGER_LIMITED, ORG_PERMISSION_LABEL.MANAGER_LIMITED],
    [ORG_PERMISSION.OBSERVER, ORG_PERMISSION_LABEL.OBSERVER],
    [ORG_PERMISSION.MEMBER, ORG_PERMISSION_LABEL.MEMBER]
])

export const PLAN_REQUEST_STATUS = {
    PENDING_APPROVAL: "pending_approval",
    APPROVED: "approved",
    REJECTED: "rejected",
    CANCELLED: "cancelled"
}
