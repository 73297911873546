import { getRefId, makeHash } from "../utils/utils"
import uniq from "lodash/uniq"
import { findAiEndDate } from "../utils/dates"
import { isBefore } from "date-fns/isBefore"
import { toZonedTime } from "date-fns-tz"
import flatMap from "lodash/flatMap"

import { getPersonName } from "../utils/utils"

export function getAiCode(ai) {
    if (!ai) {
        return ""
    }
    if (ai.code) {
        return ai.code
    }
    if (ai.createdAt) {
        const st = new Date(ai.createdAt).getTime().toString()
        return st.substring(st.length - 4, st.length)
    }
    return ""
}

export const formatProcessDuration = (ai) => {
    const dv = Math.floor((ai.estimatedDuration || 0) / 8)
    const days = ai.estimatedDuration ? dv : null
    const hours = ai.estimatedDuration ? ai.estimatedDuration % 8 : null

    let d = days ? `${days} day${days === 1 ? "" : "s"}` : ""
    let h = hours ? `${hours} hour${hours === 1 ? "" : "s"}` : ""

    return d + " " + h
}

export const formatAiTags = (ai, mission) => {
    if (!ai.tags?.length) {
        return ""
    }
    const allActionItems = mission ? getAllActionItems(mission) : []

    const f = ai.tags
        .map((t) => {
            const fst = mission?.sectionTags?.find((tt) => tt._id === t)

            if (!fst) {
                const myAi = allActionItems.find((a) => a._id === t)
                if (myAi) {
                    return `${getAiCode(myAi)} ${myAi.title || "Task with no title..."}`
                }

                if (t.replaceAll(" ", "").length > 20) {
                    return null
                }
                return t
            } else {
                return fst?.title
            }
        })
        ?.filter((t) => t !== null)

    return f
}

export function getAllActionItems(mission) {
    if (!mission?.planItems) {
        return []
    }
    let allActions = mission.planItems.reduce((cum, p) => [...cum, ...(p.actions || [])], [])
    return (allActions || []).reduce((cum, p) => [...cum, ...(p?.actionItems || [])], [])
}

export function complexityClass(ai) {
    const cm = ai.complexityMultiplier

    if (!cm) {
        return "x-cm"
    }

    return cm < 8 ? "x-cm x-cm-1" : cm >= 8 && cm <= 14 ? "x-cm x-cm-2" : cm > 14 ? "x-cm x-cm-3" : "x-cm"
}

export function getSimpleAction(mission) {
    const actions = flatMap(mission.planItems, "actions")

    return actions.find(
        (a) =>
            a.source === "mx-ai" ||
            a.aiSource === "mx-ai" ||
            a.title === "General tasks..." ||
            a.title === "mx-ai-source" ||
            a.title === "mx-ai"
    )
}

export function actionItemPermissions({ actionItem, mission, app, ignoreDone }) {
    if (!actionItem) {
        return {
            canDelete: false,
            canAdmin: false,
            canUpdate: false,
            canInvite: false,
        }
    }

    if (!mission) {
        mission = app.state.missions.find((m) => m._id === actionItem.missionId)
    }

    const done = actionItem?.status === "done"
    const aboutMeOnMission = mission.people.find((p) => getRefId(p) === app.state.person._id)

    if (!aboutMeOnMission) {
        return {
            canDelete: false,
            canAdmin: false,
            canUpdate: false,
            canInvite: false,
        }
    }

    const isCreator = getRefId(aboutMeOnMission) === getRefId(actionItem.createdBy)

    const amIinIt = actionItem.people?.find((aper) => getRefId(aboutMeOnMission) === getRefId(aper))

    const canAdmin =
        isCreator ||
        aboutMeOnMission?.permission === 2 ||
        (aboutMeOnMission?.permission === 1 && aboutMeOnMission?.isProjectManager)

    if (ignoreDone) {
        return {
            canDelete: canAdmin,
            canAdmin: canAdmin,
            canUpdate: amIinIt || canAdmin,
            canInvite: canAdmin,
            canChangeStatus: canAdmin || amIinIt,
        }
    }

    return {
        canDelete: canAdmin,
        canAdmin: canAdmin && !done,
        canUpdate: (amIinIt || canAdmin) && !done,
        canInvite: canAdmin && !done,
        canChangeStatus: canAdmin || amIinIt,
    }
}

export function isOverdue(ai, mission) {
    if (!ai) {
        return
    }
    if (ai.status === "done") return false
    const ed = findAiEndDate(ai)

    if (ai.dueDate || ai.endDate) {
        return isBefore(toZonedTime(ai.dueDate || ai.endDate), new Date())
    } else if (ed) {
        return isBefore(ed, new Date())
    }
    return false
}

export function getSuggestions({ mission, ai, hasWeek }) {
    const allAi = getAllActionItems(mission).filter((a) => !!a.title && a._id !== ai?._id && (hasWeek ? a.week : true))
    const aiIds = Object.fromEntries(allAi.map((item) => [item._id, item]))
    const allSectionTags =
        mission.projectType === "simple" || mission.projectType === "kanban"
            ? mission.sectionTags?.filter((st) => st.title) || []
            : []
    const stIds = Object.fromEntries((allSectionTags || []).map((item) => [item._id, item]))

    let allTags = []

    allAi.forEach((a, i) => {
        allTags = [...allTags, ...(a.tags || [])] || []
    })

    allTags = allTags.filter((t) => {
        return !aiIds[t] && !stIds[t] && !ai?.tags?.includes(t)
    })

    return [...allSectionTags, ...uniq(allTags), ...allAi].map((obj, i) => {
        return {
            obj: obj,
            type: obj.actionId ? "ai" : obj?._id ? "st" : "tag",
            name: obj.actionId ? getAiCode(obj) + " - " + obj.title : obj?.title || obj,
            id: obj._id || i,
        }
    })
}

export function filterSimpleTasks({ filter, mission }) {
    const allActionItems = getAllActionItems(mission)
    const people = makeHash(
        mission.people.filter((p) => p.ref).map((p) => p.ref),
        "_id"
    )
    if (!filter.obj && filter.value) {
        return allActionItems.filter((ai, i) => {
            return (
                filter.value === "" ||
                !filter.value ||
                ai.title?.toLowerCase().includes(filter.value?.toLowerCase()) ||
                ai.tags?.map((t) => t?.toLowerCase()).includes(filter.value.toLowerCase())
            )
        })
    } else if (filter?.obj?.type === "ai") {
        const el = document.getElementById("x-tc-" + filter?.obj.id)
        if (el) {
            el.scrollIntoView({
                behaviour: "smooth",
                block: "center",
            })
        }
        return [filter.obj.obj]
    } else if (filter?.obj?.type === "st") {
        const fit = allActionItems.filter((ai, i) => {
            return ai.sectionTagId === filter.obj.obj._id
        })

        return fit
    } else if (filter?.obj?.type === "tag" && filter.value) {
        const fitTags = allActionItems.filter((ai, i) => {
            return ai.tags?.map((t) => t?.toLowerCase()).includes(filter.value.toLowerCase())
        })
        return fitTags
    } else if (filter?.obj?.type === "person" && filter.value) {
        const fitTags = allActionItems.filter((ai, i) => {
            const x = ai.people?.map((p) => getPersonName(people[getRefId(p)])?.toLowerCase())
            debugger
            return x.includes(filter.value.toLowerCase())
        })

        return fitTags
    }

    return []
}
