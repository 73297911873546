import React from 'react';
import classnames from 'classnames';

import './ViewAudio.scss';
import apiFile from '../../services/file/file.api';
import ViewUnsupported from './ViewUnsupported';
import {getFileUrl} from '../../services/file/file.utils';

export default class ViewAudio extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            url: null,
            isLoaded: false
        };

        this.cancelTokenSource = null;
    }

    componentDidMount() {
        const {file} = this.props;
        this.setState({url: getFileUrl(file)});
        this.setIsLoaded();
    }

    setIsLoaded() {
        const {onLoad} = this.props;
        onLoad && onLoad();
        this.setState({
            isLoaded: true
        });
    }

    componentWillUnmount() {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel();
        }
    }

    onUnsupportedLoad() {
        const {onLoad} = this.props;
        onLoad && onLoad();
    }

    render() {
        const {file} = this.props;
        const {url, isLoaded} = this.state;

        const cx = classnames(
            'dna-drive-view--audio',
            {'dna-drive-view--audio-is-hidden': !isLoaded}
        );

        return (<>
            {!!url && <div className={cx}>
                <audio
                    controls
                    controlsList='nodownload'
                    src={url}>
                    <ViewUnsupported onLoad={() => this.onUnsupportedLoad()} file={file}/>
                </audio>
            </div>}
        </>);
    }
}