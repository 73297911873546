import React from "react"
import classnames from "classnames"
import apiFile from "../../services/file/file.api"
import "./ViewImage.scss"
import {getFileUrl} from '../../services/file/file.utils';

export default class ViewImage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            url: null,
            isLoaded: false,
        }

        this.cancelTokenSource = null
    }

    componentDidMount() {
        const {file} = this.props
        this.setState({url: getFileUrl(file)})
        this.setIsLoaded()
    }

    setIsLoaded() {
        const {onLoad} = this.props

        setTimeout(() => {
            onLoad && onLoad()
            this.setState({
                isLoaded: true,
            })
        }, 200)
    }

    componentWillUnmount() {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel()
        }
    }

    onViewClick(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    render() {
        const {file} = this.props
        const {url, isLoaded} = this.state

        return (
            <>
                {!!url && (
                    <div
                        className={classnames("dna-drive-view--image", {
                            "dna-drive-view--image-is-hidden": !isLoaded,
                        })}
                    >
                        <img
                            onClick={(e) => this.onViewClick(e)}
                            className="dna-drive-view-box"
                            alt={file.name}
                            src={url}
                        />
                    </div>
                )}
            </>
        )
    }
}
