import React from "react"
//DO NOT USE OLD use utils/utils
export function getRole(roleId, org) {
    if (!roleId) return { name: "No role assigned", notFound: true }

    if (roleId && !org && typeof roleId === "string") {
        return { name: roleId }
    }

    const foundRole = org?.roles?.length ? org.roles.find((r) => roleId === r.id || roleId === r._id) : roleId

    if (foundRole) return foundRole

    if (!foundRole && roleId && typeof roleId === "string") return { name: roleId }

    return {}
}
