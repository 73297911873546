import { motion, AnimatePresence } from "framer-motion"
import Button from "../comp/Button"
import cx from "classnames"

const Bubble = ({
    app,
    msg,
    tag,
    x,
    y,
    arrow,
    right,
    delay = 1.5,
    zIndex,
    position = "fixed",
    top,
    left,
    width = 205,
}) => {
    const cn = cx("x-bubbly dna-dark", arrow, {
        "right-arrow": right,
    })
    return (
        <AnimatePresence>
            {!app.hasSeenMsg(tag) && (
                <motion.div
                    className={cn}
                    transition={{ duration: 1.8, type: "spring", bounce: 0.5 }}
                    initial={{
                        opacity: 0,
                        top: top,
                        left: left,
                        position: position,
                        right: right,
                        width: width,
                        zIndex: zIndex,
                        transform: `translate(${x}px,${y + 50}px)`,
                    }}
                    animate={{
                        opacity: 1,
                        transform: `translate(${x}px,${y}px)`,
                        transition: { delay: delay, duration: 2, type: "spring", bounce: 0.6 },
                    }}
                    exit={{ opacity: 0, transform: `translate(${x}px,${y + 50}px)` }}
                >
                    {msg}
                    <Button
                        mint
                        onClick={(e) => {
                            e.stopPropagation()
                            app.circleBurst(e.pageX, e.pageY)
                            app.setUiSeenFlags(tag)
                        }}
                    >
                        Got it
                    </Button>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default Bubble
