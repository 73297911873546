import api from '../api.service';
const queryString = require('query-string');

class NotificationApi {

    /**
     * @param [options]
     * @param [options.type] {String|String[]} One or more types to filter by (invite, orgInvite, mkh_meeting, mentionInComment)
     * @param [options.typeNot] {String|String[]} One or more types to exclude (cannot be used with in conjunction with type)
     * @param [options.limit] {Number} Defaults to 20
     * @return {Promise<string>}
     */
    getNotificationList(options = {}) {
        const qs = queryString.stringify(options);
        return api.get(`/notification?${qs}`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            })
    }

    getNotificationUnreadCount() {
        return api.get(`/notification/unread/count`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            })
    }

    /**
     * Sets the "notificationsLastRead" date for the user.
     * Call this when user has revealed the notifications list.
     * @return {Promise<*>}
     */
    setNotificationUserLastRead() {
        return api.put(`/notification/unread/user/read`)
            .then(res => res.data)
            .catch(err => {
                throw api.getResponseError(err);
            })
    }

    /**
     * Sets the "isRead" property on the notification item itself.
     * Call this when the user has clicked a specific unread notification item.
     *
     * @param notificationId {String|String[]} Notification _id or "all" to mark all of them as read.
     * @return {Promise<*>}
     */
    markNotificationAsRead(notificationId) {
        let url = `/notification/unread/mark/read`;
        let payload = {notificationId};

        if (notificationId === 'all') {
            url = `${url}/all`;
            payload = {};
        }

        return api.put(url, payload)
            .then(res => res.data)
            .catch(err => {

                throw api.getResponseError(err);
            })
    }

    disableNotification(notificationId) {
        return api.put(`/notification/disable/${notificationId}`)
            .then(() => ({}))
            .catch(err => {
                throw api.getResponseError(err);
            })
    }

    /**
     * EXAMPLE:
     * apiNotification.onChanges(data => console.log(data))
     * @param operation {string} - update, create or delete
     * @param cb {function} - callback function
     */
    onChanges(operation, cb) {
        (async () => {
            if (typeof operation === 'function') {
                cb = operation
                operation = 'create'
            }

            const socket = await api.socket.connect(`changes/notifications/${operation}`);

            socket.on('changes', (changes) => {
                cb(changes);
            })
        })();
    }
}

export default new NotificationApi();
