import queryString from 'query-string';
import {isLocalhost} from '../../utils/utils';
import authService from '../auth/auth.service';

export function getFileUrl(file, options = {}) {

    if (!file) {
        return;
    }

    if (isLocalhost() && authService.userToken) {
        options.t = authService.userToken;
    }

    const qs = queryString.stringify(options);
    
    return file.source === "external"
        ? file.path
        : `${isLocalhost() ? process.env.REACT_APP_API_SERVICE_HOST : ''}/drive/d/${file._id}/${encodeURIComponent(file.name)}${qs.length ? '?' + qs : ''}`;
}